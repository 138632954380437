import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "./contexts/AppContext";
// import {Router} from "react-router-dom";
import {BrowserRouter as Router} from 'react-router-dom';
//import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
///import {GoogleTranslate} from "./Utilities/LanguageTranslator";

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(<React.StrictMode>
       <AuthProvider>
           <Router>
               <App/>
               {/*<ToastContainer/>*/}
           </Router>
       </AuthProvider>
    </React.StrictMode>
);


reportWebVitals();
