import React from 'react';
import styled from 'styled-components';
import Empty from '../../../assets/top/empty3.png';
//import {HiOutlineInboxStack} from "react-icons/hi2";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40vh;

    .flex {
        text-align: center;

    }

    .head-text {
        font-weight: bold;
        font-size: 18px;
    }

    .head-sub-text {
        font-size: 13px;
        color: #9e9ea4;
    }

`;

const ImageContainer = styled.div`
    width: 300px;
    //height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    //margin-left: 1.5rem;
    //border: 1px solid black; /* Add border */
`;

const Image = styled.img`
    max-width: 30%;
    max-height: 30%;
    //margin-bottom: 0;
`;

// const Button = styled.button`
//     padding: 13px 20px;
//     background-color: #FFCF01;
//     color: #141D22;
//     border: none;
//     border-radius: 5px;
//     cursor: pointer;
//     //width: 20%;
//     font-size: 12px;
//     margin-top: 12px;
//     @media screen and (max-width: 576px) {
//         width: 50%;
//     }
// `;


const EmptyImage = ({title = 'No transaction Found', subTitle = 'There are currently no transactions to display', headColor = '#000'}) => {
    return (<Container>
        <div>
            <ImageContainer>
                <Image src={Empty} alt="Centered Image"/>
                {/*<HiOutlineInboxStack className={'Nicon'}/>*/}
            </ImageContainer>

            <div style={{marginTop:10}} className="flex">
                <div style={{color:headColor}} className="head-text">{title}</div>
                <p className="head-sub-text">{subTitle}</p>
            </div>
            {/*<Button>Reset filters</Button>*/}

        </div>

    </Container>);
};

export default EmptyImage;
