import React from "react";
import styled, { keyframes } from "styled-components";
import 'react-toastify/dist/ReactToastify.css';

const Modal = ({ isOpen, onClose, children, variant }) => {

    return (
        <Backdrop isOpen={isOpen} onClick={onClose}>
            <ModalContent variant={variant} onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                {children}
            </ModalContent>
        </Backdrop>
    )
}

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-50px);
  }
`

const Backdrop = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  animation: ${(props) => (props.isOpen ? fadeIn : fadeOut)} 0.5s ease;

  p {
    color: #141D22;
    padding-left: 5px;
  }
`

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: ${(props) => (props.variant === 'small' ? '30%' : '55%')};
  max-height: 80%;
  overflow: auto;
  color: #141D22;

  @media (max-width: 768px) {
    max-width: 92%;
    max-height: 80%;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 100px;
  right: 300px;
  border: none;
  background: none;
  font-size: 30px;
  display: none;
`

export default Modal;
