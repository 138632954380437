import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from "../../contexts/AppContext";
//import * as Yup from "yup";
import Loading from "../../Loader/Loading";
//import {toast} from "react-toastify";
///import {UpdatePasswordSchema} from "../../validation/validation";
//import {IoArrowBackCircleOutline} from "react-icons/io5";

const ChangePassword = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const {changePassword} = useAuth();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");

    const [formData, setFormData] = useState({
        newPassword: '', confirmPassword: '', email: email,
    });

    useEffect(() => {
       // console.log("Email passed to updatePassword:", email);
        formData.email = email
        //console.log(formData.email, "formData")
    }, [email, formData]);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const handleVerify = async () => {
        // e.preventDefault();
        setLoading(true);
        await changePassword(formData.newPassword, formData.email)
            .then((data) => {
                // console.log(data.status, "status")
                if (data.status) {
                    alert(data.message);
                    return navigate(`/`);
                } else {
                    //console.log(data.message, "error message")
                    alert(data.message);
                    // return navigate(`/updatePassword`);
                }
            })
            .catch((error) => {
                //console.log("Oops Something Went Wrong Please Try Again")
                alert("Oops Something Went Wrong Please Try Again");
            })
    };


    const handleChange = (e, countryName) => {
        const {name, value} = e?.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };


    return (<div>
        {/*<ToastContainer/>*/}
        {/*<IoArrowBackCircleOutline style={{fontSize: "25px", cursor: "pointer"}} onClick={Navigate}/>*/}
        <div className="auth-container__form">
            <p style={{color: "#21262C", fontSize: "12px", fontWeight: "600"}}> You can now change your new password</p>

            <div>
                <label>password</label>
                <div>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="New Password"
                        name="newPassword"
                        value={formData.newPassword}
                        onChange={handleChange}
                        required
                    />

                </div>
                {errors.newPassword && !formData.newPassword &&
                    <div className="error">{errors.newPassword}</div>}
            </div>


            <div>
                <label>Confirm password</label>
                <div>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                    />
                </div>

                {errors.confirmPassword && !formData.confirmPassword &&
                    <div className="error">{errors.confirmPassword}</div>}
            </div>

            <div className="checkboxToggle">
                <label>show password</label>
                <input type="checkbox" placeholder="password" id="password"
                       className="checkbox_input" style={{cursor: "pointer"}}
                       onClick={() => togglePasswordVisibility('Password')}
                       required/>
            </div>
            <button type="submit" onClick={handleVerify}> {loading ? <Loading/> :
                <span>Continue</span>}</button>
        </div>
    </div>)
}

export default ChangePassword
