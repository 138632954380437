import React, {useState} from "react";
import molecule from "../molecules/molecules.module.css";
import EmptyImage from "../ProfileDashboard/TABS/EmptyImage";
import {useAuth} from "../../contexts/AppContext";
import {MainContainer} from "../ProfileDashboard/TABS/Tabs";
import {Wrapper} from "../ProfileDashboard/SideBarPages/Account";
import {
    DashboardInvestmentHistory
} from "../ProfileDashboard/TABS/dashboard/dashboardInvestmentHistory";
import {
    DashboardTransactionHistory
} from "../ProfileDashboard/TABS/dashboard/dashboardTransactionHistory";


const TradeHistory = () => {
    const {transactionHistory, investmentHistory, user} = useAuth();

    const [activeTab, setActiveTab] = useState('transactions');

    const handleTabClicks = (tab) => {
        setActiveTab(tab.toLowerCase());
    };


    // return (<div className="orders ">
    return (<div>
        <ul className="orders__tabs ">
            {/*<ul className="orders__tabs deskShow">*/}
            {/*<li className={`orders__tabs--links ${molecule.active === "true" && "active"}`}>SELL</li>*/}
            <li className={activeTab === 'transactions' ? `${molecule.segmentedTabs__links} ${molecule.active}` : `${molecule.segmentedTabs__links} `}
                onClick={() => handleTabClicks('transactions')}>
                Recent Transactions
            </li>


            <li className={activeTab === 'history' ? `${molecule.segmentedTabs__links} ${molecule.active}` : `${molecule.segmentedTabs__links} `}
                onClick={() => handleTabClicks('history')}>
                Recent Investment
            </li>


        </ul>

        <MainContainer>
            {activeTab === 'transactions' && <div>
                <Wrapper>
                    <div className='title'>
                        <h3 style={{color: "white", padding: "1rem"}}>Recent Transactions</h3>
                    </div>

                    <MainContainer>
                        {transactionHistory && transactionHistory.length > 0 ? (
                            <DashboardTransactionHistory user={user}
                                transactionHistory={transactionHistory}/>) : (<EmptyImage headColor={'#fff'}/>)}


                    </MainContainer>
                </Wrapper>
                )
            </div>}

            {activeTab === 'history' && <div>
                <Wrapper>
                    <div className='title'>
                        <h3 style={{color: "white", padding: "1rem"}}>Recent Investment </h3>
                    </div>

                    <MainContainer>
                        {investmentHistory && investmentHistory.length > 0 ? (
                            <DashboardInvestmentHistory user={user} investmentHistory={investmentHistory}/>) : (
                            <EmptyImage title={'No Investment Yet'} subTitle={'There are currently no investment to display'} headColor={'#fff'}/>)}


                    </MainContainer>
                </Wrapper>
            </div>

            }

        </MainContainer>

    </div>)
}

export default TradeHistory
