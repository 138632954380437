import React, {useState} from 'react';
import styled from 'styled-components';
import {
    AmountContainer,
    AuthButton,
    CardContainers,
    CardContent,
    Container,
    FieldContainer,
    FieldLabel,
    FormContainer, FormContainer2,
    Text,
    TextWrapper
} from "./Deposit&WithdrawCard";
import {BiArrowBack} from "react-icons/bi"
//import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from "yup";
import Loading from "../../../Loader/Loading";
import {useAuth} from "../../../contexts/AppContext";
import {InvestmentConfirmation} from "./TransactionHistory/confirmation";
import {formatCurrency, formatCurrency2} from "./formatCurrency";
import {useNavigate} from "react-router-dom";
import Modal from "./Modal";
import getCompanyInfo from "../../../Utilities/companyInfo";


const CardContainer = styled.div`
    flex: 0 0 auto;
    width: 300px;
    //width: 100%;
    height: 480px;
    color: #141D22;
    background-color: #ffffff;
    cursor: pointer;
    //border: 1px solid #FFDE02;
        // background-color: ${({active}) => (active ? 'rgba(255, 222, 2, 0.1)' : '#000000')};
    border: 1px solid ${({active}) => (active ? '#FFDE02' : '')};
    border-radius: 3px;
    padding: 20px;
    margin-right: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 600px) {
    width: 100% !important;
    margin-bottom: 20px;
  }

    &:hover {
        background-color: rgba(255, 222, 2, 0.1);
        color: black
    }

    @media screen and (max-width: 768px) {
        max-width: 100%;
        height: auto;
        &:hover {
            background-color: rgba(255, 222, 2, 0.1);
            color: black;
        }
    }
`;
const Title = styled.h2`
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
`;

const SubTitle = styled.span`
    font-size: 12px;
    text-align: center;
    background-color: #FFDE02;
    color: #000000;
    border-radius: 10px;
    padding: 5px;
    margin-top: 9px;
    margin-left: 3rem;
    margin-right: 3rem;
    display: flex;
    justify-content: center;
`;

const Description = styled.div`
    font-size: 12px;
    margin-top: 10px;
    //white-space: pre-line;
    text-align: center;
`;

const DetailsList = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 3rem;
`;

const DetailItem = styled.li`
    font-size: 12px;
    border-bottom: 1px solid #E2E4E4;
    padding: 15px;
    display: flex;
    justify-content: space-between;
`;

const Label = styled.span`
    flex-grow: 1;
    text-transform: capitalize;
`;


export const InvestmentPlanCard = ({
                                       _id,
                                       planName,
                                       planType,
                                       desc,
                                       minimumInitialDeposit,
                                       maximumInitialDeposit,
                                       spread,
                                       commission,
                                       duration,
                                       planStatus
                                   }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [active, setActive] = useState(false);

    const handleClick = () => {
        setActive(!active);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };




    return (<div>
            {/*<ToastContainer/>*/}
            <CardContainer key={_id} _id={_id} active={active} onClick={handleClick}>
                <Title>{planName}</Title>
                <SubTitle>{planType}</SubTitle>
                <Description>{desc}</Description>
                <DetailsList>
                    <DetailItem>
                        <Label>Minimum Investment Deposit</Label>
                        <strong> ${formatCurrency(minimumInitialDeposit)}</strong>
                    </DetailItem>
                    <DetailItem>
                        <Label>Maximum Investment Deposit</Label>
                        <strong> ${formatCurrency(maximumInitialDeposit)}</strong>
                    </DetailItem>
                    <DetailItem>
                        <Label>Profit</Label>
                        <strong>{spread}%</strong>
                    </DetailItem>
                    <DetailItem>
                        <Label>Commission</Label>
                        <strong>{commission}</strong>
                    </DetailItem>
                    <DetailItem>
                        <Label>Duration</Label>
                        <strong style={{textTransform: "capitalize"}}>{duration} Days</strong>
                    </DetailItem>
                </DetailsList>
            </CardContainer>

            <Modal isOpen={isModalOpen} onClose={closeModal} variant={'small'}>
                <InvestmentDeposit
                    _id={_id}
                    planName={planName}
                    planType={planType}
                    desc={desc}
                    minimumInitialDeposit={minimumInitialDeposit}
                    maximumInitialDeposit={maximumInitialDeposit}
                    spread={spread}
                    commission={commission}
                    duration={duration}
                    planStatus={planStatus}
                    onClose={closeModal}
                />

            </Modal>

        </div>

    );
};


export const InvestmentDeposit = ({
                                      onClose,
                                      _id,
                                      planName,
                                      planType,
                                      desc,
                                      minimumInitialDeposit,
                                      maximumInitialDeposit,
                                      spread,
                                      duration,
                                  }) => {
    const {StartInvestment} = useAuth();
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        amount: '', spread: spread, duration: duration, investmentPlan: planName
    });


    const handleInvest = async () => {
        setLoading(true);
        try {
            await StartInvestment(formData.amount, formData.spread, formData.duration, formData.investmentPlan)
                .then((data) => {
                     if (data.message === "Investment started successfully") {
                        onClose()
                        return navigate(`/profile/success?amount=${data?.details?.amount}&message=${data?.details?.message}&transactionRef=${data?.details?.transactionRef}&transactionStatus=${data?.details?.transactionStatus}&date=${data?.details?.date}`);
                    }  else if (data?.message === 'Access Denied') {
                         alert("Login to Continue");
                         return navigate(`/`);
                     } else if(data.error) {
                         alert(data?.error)
                     }
                })
        } catch (err) {
            alert('Oops, something went wrong, please try again')
        } finally {
            setLoading(false);
        }
    };

    const handleChange = async (e, countryName) => {
        const {name, value} = e?.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };
    const handleSubmit = () => {
        try {
            // Check if formData.amount is empty
            if (!formData.amount) {
                alert('Please enter the amount you want to invest.');
                return; // Exit the function early
            }

            // Check if formData.amount is less than the minimum initial deposit
            if (parseInt(formData.amount) < parseInt(minimumInitialDeposit)) {
                alert(`Investing in ${planName}, the amount must be at least $${minimumInitialDeposit}.`);
                return; // Exit the function early
            }

            // Check if formData.amount is greater than the maximum initial deposit
            if (parseInt(formData.amount) > parseInt(maximumInitialDeposit)) {
                alert(`Investing in ${planName}, the amount must not exceed $${maximumInitialDeposit}.`);
                return; // Exit the function early
            }

            // If all conditions are met, setSubmitted to true
            setSubmitted(true);
        } catch (error) {
            console.error(error);
        }
    };



    if (submitted) {
        return (<Container>
            <InvestmentConfirmation setSubmitted={setSubmitted}
                                    handleInvest={handleInvest}
                                    planName={planName}
                                    planType={planType}
                                    desc={desc} loading={loading}
                                    amount={formData.amount}
                                    minimumInitialDeposit={minimumInitialDeposit}
                                    maximumInitialDeposit={{maximumInitialDeposit}}
                                    spread={spread}
                                    duration={duration}

            />
        </Container>)
    }


    return (<Container _id={_id}>
            {/*<ToastContainer/>*/}
            <FormContainer2>

                <h3 className='text' style={{marginBottom:20}} onClick={onClose}>
                    <div style={{background:'#000',height:30,width:30,borderRadius:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <BiArrowBack className='icon' style={{color:'#fff'}}/>
                    </div>
                </h3>

                <FieldContainer>
                    <FieldLabel>Investment Plan</FieldLabel>
                    <input
                        type="text"
                        defaultValue={planName}
                        disabled
                        style={{fontSize: "15px", fontWeight: "500"}}
                    />
                </FieldContainer>
                <FieldContainer>
                    <FieldLabel>Amount</FieldLabel>
                    <input
                        type="text"
                        value={formData.amount}
                        name="amount"
                        placeholder="0"
                        style={{fontSize: "15px", fontWeight: "500"}}
                        onChange={handleChange}
                    />
                    <span>{formatCurrency(minimumInitialDeposit)} - {formatCurrency(maximumInitialDeposit)} USD</span>
                </FieldContainer>
                <CardContainers>
                    <CardContent>
                        <Text>
                            Enter the amount you want to Invest. It should fall within the suggested
                            range and cannot
                            be more than available on your trading account or wallet.
                        </Text>
                    </CardContent>
                </CardContainers>
                <AmountContainer>
                    <p className="text">Investment Amount</p>
                    {formData.amount === "" ? "0 USD" : (parseFloat(formData.amount) || 0).toLocaleString('en-US', {
                        style: 'currency', currency: 'USD'
                    })}


                </AmountContainer>
                <AuthButton type="button" onClick={handleSubmit}>
                    Continue
                    {/*{loading ? <Loading/> : <span>Invest </span>}*/}
                </AuthButton>

                <TextWrapper className='HT'>
                    <p style={{marginTop:20}}>
                        <strong>Investment Details</strong>
                    </p>
                    <p style={{textTransform: "capitalize"}}>Investment Duration:
                        <span style={{color: "#298551", fontWeight: "bold"}}> {duration}</span></p>
                    <p style={{textTransform: "capitalize"}}>Profit:
                        <span style={{color: "#298551", fontWeight: "bold"}}> {spread}%</span></p>
                </TextWrapper>


            </FormContainer2>

        </Container>
    );
}


export const InvestmentWithdrawalForm = ({user, RedeemInvestment}) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(false)
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        amount: '', toAccount: user.walletNumber,
    });

    const company = getCompanyInfo();

    const RedeemAction = async () => {
        setLoading(true);
        try {
            if (formData.amount >= user?.withdrawalLimit) {
                await RedeemInvestment(formData.amount, formData.toAccount)
                    .then((data) => {
                        //console.log(data, "data...")
                         if (data?.status) {
                            alert(data.message);
                             return navigate(`/profile/success?amount=${data?.details?.amount}&message=${data?.details?.message}&transactionRef=${data?.details?.transactionRef}&transactionStatus=${data?.details?.transactionStatus}&date=${data?.details?.date}`);}
                         else if (data?.message === 'Access Denied') {
                             alert("Login to Continue");
                             return navigate(`/`);
                         } else if(data.error) {
                             alert(data?.error);
                         }
                    }).catch((error) => {
                        alert(error.message);
                    })
            } else {
                alert("minimum amount must exceed $100")
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                    alert(error.message)
                });
            }
        } finally {
            setLoading(false);
        }


    };

    const handleRedeemInvestment = (image) => {
        const result = window.confirm(
            'Are you sure you want to proceed?, Your redeemed amount will be credited to your main wallet balance'
        );

        if (result) {
            RedeemAction().then()
        }
    };

    const handleChange = async (e, countryName) => {
        const {name, value} = e.target;

        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };


    const amount = user?.investBalance;
    const formattedAmount = formatCurrency2(amount);

    return (<Container>
            <FormContainer>
                <FieldContainer>
                    <FieldLabel>To account</FieldLabel>
                    <div className="Account">
                        <div>
                            <span className="step-number">ST</span>
                            <span>{user?.walletNumber}</span>
                        </div>
                        <div>{formattedAmount} </div>
                    </div>
                </FieldContainer>

                <FieldContainer>
                    <FieldLabel>Amount</FieldLabel>
                    <input
                        type="text"
                        value={formData.amount}
                        name="amount"
                        placeholder="100"
                        style={{fontSize: "18px", fontWeight: "500"}}
                        onChange={handleChange}
                    />
                    <span>Minimum Withdrawal ${user?.withdrawalLimit}</span>
                </FieldContainer>


                <CardContainers>
                    <CardContent>
                        <Text>
                            Enter the amount you want to withdraw. It should fall within the suggested range and cannot be more than available on your investment profit balance
                        </Text>
                    </CardContent>
                </CardContainers>

                <AmountContainer>
                    <p className="text">To be Withdraw</p>
                    {formData.amount === "" ? "0 USD" : (parseFloat(formData.amount) || 0).toLocaleString('en-US', {
                        style: 'currency', currency: 'USD'
                    })}

                </AmountContainer>

                <AuthButton type="button" onClick={handleRedeemInvestment}>   {loading ?
                    <Loading/> :
                    <span>Continue </span>}</AuthButton>
            </FormContainer>
            <TextWrapper>
                <p>
                    <strong>Terms</strong>
                </p>
                <p>Average payment time: 1 hour</p>
                <p>Fee: 0%</p>
                <p>
                    <strong>Need Help?</strong>
                </p>
                <p>Contact Support Team {company.supportEmail}</p>
            </TextWrapper>
        </Container>
    );
}
