import React, {useState} from "react";
import {Link, useNavigate} from 'react-router-dom'
import {
    CountryItem,
    DropdownButton,
    DropdownContainer,
    DropdownContent
} from "../ProfileDashboard/TABS/dropDown/CustomDropDown";
import {Countries} from "../ProfileDashboard/TABS/CountryList";
import {useAuth} from "../../contexts/AppContext";
import {SignUPSchema} from "../../validation/validation";
import * as Yup from "yup";
import Loading from "../../Loader/Loading";
import 'react-toastify/dist/ReactToastify.css';
//import {toast} from "react-toastify";
import {BiChevronDown, BiChevronUp} from "react-icons/bi";
import getCompanyInfo from "../../Utilities/companyInfo";


const Register = () => {
    const [showInput, setShowInput] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const {register} = useAuth();

    const company = getCompanyInfo()

    const [formData, setFormData] = useState({
        email: '', password: '', country: '', partnerCode: '', termsAndConditions: false
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleInput = () => {
        setShowInput(!showInput);
    };


    const handleRegister = async (e) => {
        e?.preventDefault();
        setLoading(true);
        try {
            await SignUPSchema.validate(formData, {abortEarly: false});
            // const { termsAndConditions, ...registerData } = formData;
            await register(formData.country, formData.email, formData.password, formData.partnerCode)
                .then((data) => {
                    if (data.status) {
                            alert(data.message);
                            return navigate(`/congratulation?email=${formData.email}`);
                    } else {
                        alert(data.message);
                        return navigate('/')
                    }
                }).catch((error) => {
                    alert("Oops Something Went Wrong Please Try Again");
                })
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                alert(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e, countryName) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };


    const handleSelectBank = (countryName) => {
        setFormData({
            ...formData, country: countryName
        });
        setIsOpen(false);
    };


    const handleCheck = () => {
        setFormData({
            ...formData,
            termsAndConditions: !formData.termsAndConditions
        });
        setIsOpen(false);
    };


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    return (<div>
        {/*<ToastContainer/>*/}
        <div className="auth-container__form">
            <div>
                <label>Country / Region of Residence</label>
                <DropdownContainer>
                    <DropdownButton style={{alignItems: "start", justifyContent: "start"}}
                                    onClick={toggleDropdown}>
                        {formData.country ? <>{formData.country}</> : 'Select Country'}
                    </DropdownButton>
                    {isOpen && (<DropdownContent>
                        {Countries.map((countryName, index) => (
                            <CountryItem style={{alignItems: "start", justifyContent: "start"}}
                                         key={index}
                                         name="country"
                                         value={formData.country}
                                         onClick={() => handleSelectBank(countryName.value)}
                            >
                                {countryName.value}
                            </CountryItem>))}
                    </DropdownContent>)}
                </DropdownContainer>
                {errors.country && !formData.country &&
                    <div className="error">{errors.country}</div>}
            </div>

            <div>
                <label>Your Email Address</label>
                <input type="email"
                       placeholder="Email"
                       name="email"
                       value={formData.email}
                       onChange={handleChange}
                       required/>
                {errors.email && !formData.email && <div className="error">{errors.email}</div>}
            </div>
            <div>
                <label>Password</label>
                <div>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />


                </div>
                {errors.password && !formData.password &&
                    <div className="error">{errors.password}</div>}
            </div>


            <div className="checkboxToggle">
                <label>Show Password</label>
                <input type="checkbox" placeholder="password" id="password"
                       className="checkbox_input" style={{cursor: "pointer"}}
                       onClick={togglePasswordVisibility}
                       required/>
            </div>

            <div style={{display: 'flex', flexDirection: "row",}} onClick={toggleInput}>
                <label style={{fontSize: "22px", cursor: 'pointer'}}>{showInput ? <BiChevronUp/> :
                    <BiChevronDown/>}
                </label>
                <label style={{fontSize: "12px", paddingTop: "5px", cursor: 'pointer'}}>Partner's
                    code (optional)</label>
            </div>
            <div>
                {showInput && (<input type="text"
                                      placeholder="xxxx"
                                      name="partnerCode"
                                      value={formData.partnerCode}
                                      onChange={handleChange}
                                      required/>)}
            </div>


            <div className="checkbox">
                <input type="checkbox" id="termsAndConditions"
                       placeholder="checkbox"
                       name="termsAndConditions"
                       className="checkbox_input"
                       value={formData.termsAndConditions}
                       onChange={handleCheck}
                       required/>


                <label>
                    Please check this box to indicate your agreement with our <Link style={{color:'#fba737'}} target='_blank' to={company.terms}>terms and conditions</Link>
                </label>
                {errors.termsAndConditions && !formData.termsAndConditions &&
                    <div className="error">{errors.termsAndConditions}</div>}
            </div>

            <button type="submit" onClick={handleRegister} disabled={loading}>
                {loading ? <Loading/> : <span>Create Account</span>}</button>

            <p className="text">
                Based on the selected country of residence, you are registering with {company.name},
                regulated by the
                Seychelles
                FSA.
            </p>
            <p className="text">By clicking Continue, you confirm that you have read, understood,
                and agree with all the
                information in the Client Agreement and the service terms and conditions listed in
                the following
                documents: General Business Terms, Partnership Agreement, Privacy Policy, Risk
                Disclosure of your private information.</p>
            <p className="text hidden">You also confirm that you fully understand the nature and the risks
                of the services and
                products envisaged. Trading CFDs is not suitable for everyone; it should be done by
                traders with a high
                risk tolerance and who can afford potential losses.</p>
        </div>
    </div>)
}

export default Register
