import React from 'react'
import AuthHeader from "../components/Authentication/Header";
import Footer from "../components/Authentication/Footer";
import CongratulationsScreen from "../components/Authentication/CongratulationScreen";
import styled from "styled-components";

const Congratulation = () => {
    // const navigate = useNavigate();

    return (

        <div className="Tab_Auth">
            <AuthHeader/>
            <div className="auth-layout">
                <div>
                    <Container>
                        <h2 className="auth-layout-text">Registration Successful </h2>
                    </Container>
                    <div className="auth-container">
                        <CongratulationsScreen/>
                    </div>
                </div>

            </div>

            <Footer/>
        </div>

    )
}

export default Congratulation


const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%

`;