import React from 'react'
import {Link} from 'react-router-dom'
import AuthHeader from "../components/Authentication/Header";
import Footer from "../components/Authentication/Footer";
import ChangePassword from "../components/Authentication/ChangePassword";

const UpdatePassword = () => {
    // const navigate = useNavigate();

    return (

        <div className="Tab_Auth">
            <AuthHeader/>
            <div className="auth-layout">
                <div>
                    <h2 className="auth-layout-text">New password</h2>
                    <div className="auth-container">
                        <ChangePassword/>
                    </div>
                </div>

            </div>
            <Link className="Link" to='/'>
                <span>SignIn Now </span>
            </Link>

            <Footer/>
        </div>

    )
}

export default UpdatePassword
