import React from 'react';
import styled from 'styled-components';
import {IoMdCheckmarkCircleOutline} from "react-icons/io";

const TransferSuccessFul = ({updateDetails, onClose}) => {

    const Cancel = () => {
        // onClose();
        onClose(false)

    }

    return (
        <Container>
            <ImageContainer>

                <IoMdCheckmarkCircleOutline className="icon"/>
            </ImageContainer>
            <Heading>Success</Heading>
            <p>{updateDetails?.message}</p>
            <DetailsContainer>

                <DetailItem>
                    <strong>Transaction ID:</strong>
                    <span>{updateDetails?.transactionRef}</span>
                </DetailItem>
                <DetailItem>
                    <strong>Amount:</strong>
                    <span>${updateDetails?.amount}</span>
                </DetailItem>
                <DetailItem>
                    <strong>Status:</strong>
                    <span>{updateDetails?.transactionStatus}</span>
                </DetailItem>
                <DetailItem>
                    <strong>Date:</strong>
                    <span>{updateDetails?.date}</span>
                </DetailItem>

            </DetailsContainer>
            <div className="contact">
                <p>Need help? <span className="contactUs">Contact Us</span></p>
                <p className="cancel" onClick={Cancel}>Cancel?</p>
            </div>

        </Container>)
};

export default TransferSuccessFul;


// Styled components for styling
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    border-radius: 5px;
    //border: 1px solid #34aadc;
    //margin: 6px;
    z-index: 999;
    padding: 20px;
    //background: linear-gradient(45deg, #28a745, #20c997);

    .icon {
        font-size: 70px;
        color: #28a745
    }

    .contact {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
    }

    .contactUs {
        color: #FFCF01;
        font-weight: bold;
        cursor: pointer;
    }

    .cancel {
        color: #FFCF01;
        font-weight: bold;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        .contact {
            //flex-direction: row;
            //justify-content: space-between;
        }
    }
`;


const ImageContainer = styled.div`
    display: flex;
    justify-content: center;

`
const Heading = styled.h3`
    font-size: 25px;
    margin-top: 1rem;
`;


const DetailsContainer = styled.div`
    border-bottom: 1px solid #B1B5C36B;
    padding: 10px;
    //border-radius: 5px;
    margin-bottom: 20px;
`;

const DetailItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
    gap: 15rem;

    .status {
        background: #8FF5A0BD;
        padding: 4px 12px;
        border-radius: 10px;
        color: #00C076;
        border: 1px solid #4cd964;
    }

    span {
        font-size: 13px;
        color: #1C2127;
    }

    strong {
        font-size: 13px;
        color: #9e9ea4;
        font-weight: 600;
    }

    @media screen and (max-width: 768px) {
        gap: 5rem;
    }
`;


