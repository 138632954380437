import React from 'react';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

const CardContainer = styled.div`
    //background-color: #f0f0f0;
    background-color: #FFFFFF;
    border-radius: 5px;
    border: 2px solid #f0f0f0;
    padding: 20px;
    //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px; /* Set maximum width */
    //margin: 10px auto; /* Center the card */
    height: 25vh;
    margin-right: 2rem;
    margin-top: 2rem;

    @media screen and (max-width: 576px) {
        margin-right: 0;
        p {
            color: #1C2127;
        }
    }
`;

const Title = styled.h5`
    color: #333;
    margin-bottom: 20px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
`;

const Content = styled.p`
    color: #373b3f;
    line-height: 1.6;
    font-size: 10px;
`;

export const NegativeBalance = () => {
    return (<CardContainer>
        <Title>Negative Balance Protection</Title>
        <Content>
            You can never lose more money than you put into your account.
            If a stop out causes all your positions to
            close in a negative balance, we will restore it to 0.
        </Content>
    </CardContainer>);
};

export const SwapFree = () => {
    return (<CardContainer>
        <Title> <span>Swap-Free</span> <span>Extended</span></Title>
        <Content>
            No more overnight charges. Hold positions on popular instruments for as long as you want, without paying
            swaps. The list of swap-free instruments varies depending on your Swap-Free Level.
        </Content>
    </CardContainer>);
};
