import React, {useEffect, useState} from 'react';

const ProgressBar = ({startDate, endDate, color = '#000'}) => {
    const [progress, setProgress] = useState(0);


    useEffect(() => {
        const calculateProgress = () => {
            const currentTime = new Date();
            const startTime = new Date(startDate);
            const endTime = new Date(endDate);

            const totalTime = endTime - startTime;
            const elapsedTime = currentTime - startTime;

            // const percentage = (elapsedTime / totalTime) * 100;
            let percentage = (elapsedTime / totalTime) * 100;
            percentage = Math.min(percentage, 100);
            setProgress(percentage);
        };

        // Calculate progress initially
        calculateProgress();

        // Update progress every second
        const interval = setInterval(calculateProgress, 1000);

        // Cleanup interval
        return () => clearInterval(interval);
    }, [startDate, endDate]);


    return (<div>
        <div style={{width: '100%', backgroundColor: '#ddd', borderRadius: '5px'}}>
            <div
                style={{
                    width: `${progress}%`, // width: `100%`,
                    height: '8px', backgroundColor: '#00C076', borderRadius: '5px', transition: 'width 1s ease-in-out',
                }}
            />
        </div>
        <p style={{marginTop: '5px',color:color}}>Progress: {progress.toFixed()}%</p>
    </div>);
};


export default ProgressBar
