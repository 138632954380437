import styled from "styled-components";
import { MoonLoader } from "react-spinners";
import { useEffect, useState } from "react";
import getCompanyInfo from "../Utilities/companyInfo";

const LoadingSpan = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999; /* Ensure it's on top of other content */
`;

const LoadingText = styled.span`
  margin-left: 1rem;
  font-size: 13px;
  color: #FFCF01;
  font-weight: bold;
`;

const LoadingSpinner = () => {
    const [showSpinner, setShowSpinner] = useState(true);

    const company = getCompanyInfo()

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSpinner(false);
        }, 60000);

        return () => clearTimeout(timer); // Clear the timer on unmount
    }, []);

    return showSpinner ? (
        <LoadingSpan>
            <MoonLoader color="#FFCF01" size={60} />
            <LoadingText>{company?.loadingText}......</LoadingText>
        </LoadingSpan>
    ) : null;
};

export default LoadingSpinner;
