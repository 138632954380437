import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import top1 from "../../../assets/top/top1.png"
import top2 from "../../../assets/top/top2.png"
import top3 from "../../../assets/top/top3.png"
import top4 from "../../../assets/top/top4.png"

// Define your images
const images = [top1, top2, top3, top4];

// Styled components
const SliderContainer = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      //padding: 2rem;
      width: 100%;
      margin-top: 10px;
      padding-left: 1rem;
      padding-top: 0.5rem;

`;

const Slide = styled.img`
    //width: 100%;
    //max-width: 1010px;
    //max-width: 1000px;
    height: auto;
    //display: ${props => props.active ? 'block' : 'none'};
  display: ${props => props.active === "true" ? 'block' : 'none'};

  //@media screen and (max-width: 768px) {
    //    width: 100%;
    //}
  @media screen and (max-width: 1024px) {
    //min-width: 100%;
    //max-width: 100%;
  }
  //min-width: 50%;

  @media screen and (max-width: 576px) {
    min-width: 100%;
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    max-width: 1000px;
  }

  @media screen and (max-width: 992px) {
    max-width: 1000px;
  }
  @media only screen and (min-width: 600px) {
    max-width: 1000px;
  }

  @media only screen and (max-width: 600px) {
    min-width: 100%;
    max-width: 100%;
  }


  @media screen and (max-width: 420px) {
    //min-width: 100%;
    //max-width: 100%;
  }
`;

const DotContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px;
  padding: 10px;
`;

const Dot = styled.span`
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    //background-color: ${props => props.active ? '#9e9ea4' : 'rgba(229,229,229,0.57)'};
  background-color: ${props => props.active === "true" ? '#9e9ea4' : 'rgba(229,229,229,0.57)'};

  margin: 0 5px;
    cursor: pointer;
    color: #9e9ea4;
`;

const AutoSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide(prevSlide => (prevSlide + 1) % images.length);
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval);
    }, []);

    const handleDotClick = (index) => {
        setCurrentSlide(index);
    };

    return (<SliderContainer>
            {images.map((image, index) => (<Slide key={index} src={image} active={index === currentSlide ? "true" : "false"} />
            ))}
            <DotContainer>
                {images.map((_, index) => (
                    <Dot key={index} active={index === currentSlide ? "true" : "false"} onClick={() => handleDotClick(index)} />

                ))}
            </DotContainer>
        </SliderContainer>

    );
};

export default AutoSlider;
