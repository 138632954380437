import React from "react"
import Layout from "../../components/ProfileDashboard/SideBarLayout";
import PortfolioManagement from "../../components/ProfileDashboard/SideBarPages/PortFolioManagement";


const Portfolio = () => {

    return (<Layout>
        <PortfolioManagement/>
    </Layout>)

}

export default Portfolio