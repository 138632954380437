import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../contexts/AppContext";

function useInitializeAuthState() {
    const navigate = useNavigate();
    const { setIsLoggedIn, setToken, token, setUser, user, setInvestmentPlan, InvestmentPlan, setTransactionHistory, transactionHistory, setInvestmentHistory, investmentHistory  } = useAuth();


    useEffect(() => {
        const storedToken = localStorage.getItem('token');

        if (storedToken) {
            // USER IS LOGGED IN

            setToken(token);
            setUser(user);
            setInvestmentPlan(InvestmentPlan);
            setTransactionHistory(transactionHistory);
            setInvestmentHistory(investmentHistory)
            setIsLoggedIn(true);
            // Optionally fetch user data here and set it
        } else {
            navigate('/');
            setIsLoggedIn(false);
            setToken(null);
            setUser(null);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('InvestmentPlan');
            localStorage.removeItem('investmentHistory');

        }
    }, [ setIsLoggedIn, setToken, setUser]);
}

export default useInitializeAuthState;
