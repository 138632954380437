import React from "react";
import styled from "styled-components";
import {FaLongArrowAltLeft, FaLongArrowAltRight} from "react-icons/fa";

const Pagination = ({currentPage, totalPages, onPageChange}) => {
    const handlePrevClick = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    return (<PaginationContainer>
        <PaginationButton onClick={handlePrevClick} disabled={currentPage === 1}>
            <FaLongArrowAltLeft style={{paddingTop: "5px"}}/>
        </PaginationButton>
        <PaginationButton onClick={handleNextClick} disabled={currentPage === totalPages}>
            <FaLongArrowAltRight style={{paddingTop: "5px"}}/>
        </PaginationButton>
    </PaginationContainer>);
};

const PaginationContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    padding-left: 20px;
`;

const PaginationButton = styled.button`
    background-color: #f1c40f;
    color: #000000;
    font-size: 20px;
    border: none;
    padding: 3px 18px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

export default Pagination;
