import React from 'react'
import styled from "styled-components";
import Tab, {MainContainer} from "../TABS/Tabs";
import {Wrapper} from "./Account";
import {AccountCard, AccountCard1, Auth2Card, AuthorizationCard} from "../TABS/AccountCards";
import {NegativeBalance, SwapFree} from "../TABS/SettingsCard";
import Accordion from "../TABS/Accordion";
import withLoader from "../../../Loader/WithLoader";
import 'react-toastify/dist/ReactToastify.css';


const Settings = ({user}) => {


    const tabs = [{
        title: "Profile", content: <MainContainer>
            <h2 className='header-text'>Account</h2>
            <DivContainer>
                <AccountCard user={user}/>
                <AccountCard1 user={user}/>
            </DivContainer>

            <h2 className='header-text'>Profile</h2>
            <Accordion user={user}/>
        </MainContainer>
    }, {
        title: "Security Settings", content: <MainContainer>
            <h2 className='header-text'>Authorization</h2>
            <p className='header-text'>Information for logging in to Sisyphus.</p>
            <p className='header-text'>Change your password whenever you think it might have been compromised.</p>
            <AuthorizationCard user={user}/>


            <h2 className='header-text'>2-Step verification</h2>
            <p className='header-text'>2-step verification ensures that all sensitive transactions are authorized by
                you.</p>
            <p className='header-text'>We encourage you to enter verification codes to confirm these transactions.</p>
            <Auth2Card/>

        </MainContainer>
    }, {
        // title: "Trading Terminals",
        title: "Trading Conditions", content: <MainContainer>
            <h2 className='header-text'>Trading Conditions</h2>
            <p className='header-text'>Here's a list of the better-than-market trading conditions you can currently
                enjoy on your accounts..</p>
            <DivContainer>
                <NegativeBalance/>
                <SwapFree/>
            </DivContainer>
        </MainContainer>
    },

    ];

    return (

        <main className='main-container'>
            {/*<ToastContainer/>*/}
            <Wrapper>
                <div className='title'>
                    <h4>Settings</h4>
                </div>
                <Tab tabs={tabs}/>
            </Wrapper>
        </main>)
}


// export default Settings
export default withLoader(Settings);


export const DivContainer = styled.div`
    //padding: 20px;
    //margin: 5px auto;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        color: #141D22;
    }
`;