import React from "react"
import Settings from "../../components/ProfileDashboard/SideBarPages/Settings";
import Layout from "../../components/ProfileDashboard/SideBarLayout";
import {useAuth} from "../../contexts/AppContext";


const Setting = () => {
    const {user} = useAuth();
    return (<Layout>
            <Settings user={user}/>
        </Layout>)

}

export default Setting