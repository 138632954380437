import React from 'react'
import AuthHeader from "../components/Authentication/Header";
import Footer from "../components/Authentication/Footer";
import Security2FA from "../components/Authentication/Security2Fa";

const SecurityLayout = () => {

    return (

        <div className="Tab_Auth">
            <AuthHeader/>
            <div className="auth-layout">
                <div>
                    <h2 className="auth-layout-text">2 Factor Verification</h2>
                    <div className="auth-container">
                        <Security2FA/>
                    </div>
                </div>

            </div>

            <Footer/>
        </div>

    )
}

export default SecurityLayout