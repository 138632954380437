import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from "../../contexts/AppContext";
import * as Yup from "yup";
import Loading from "../../Loader/Loading";
import 'react-toastify/dist/ReactToastify.css';
import {toast } from 'react-toastify';
import {Reset2FaOTPSchema} from "../../validation/validation";
import {IoArrowBackCircleOutline} from "react-icons/io5";
import {SuccessDiv} from "./ResetPasswordOTP";

const Security2FA = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const {Security2Fa, isLoggedIn} = useAuth();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");


    const [formData, setFormData] = useState({
        email: email, twoFactorCode: '',
    });


    useEffect(() => {
        console.log("Email passed to 2FA security:", email);
        formData.email = email
        console.log(formData.email, "formData")
    }, [email, formData]);


    const handleVerify = async () => {
        // e.preventDefault();
        setLoading(true);
        try {
            await Reset2FaOTPSchema.validate(formData, {abortEarly: false});
            await Security2Fa(formData.email, formData.twoFactorCode,)
                .then((data) => {
                   if (data.status === true) {
                    if(isLoggedIn === true){
                        toast.success(data.message);
                        return navigate('/profile')
                       }
                    //    toast.success(data?.message);
                    //     return navigate('/profile');
                    } else {
                        toast.error(data.message);
                        return navigate('/');
                    }
                })
                .catch((error) => {
                    toast.error("Oops Something Went Wrong Please Try Again");
                })
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                    toast.error(error.message)
                });
            }
        } finally {
            setLoading(false);
        }
    };


    const handleChange = (e, countryName) => {
        const {name, value} = e?.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };

    const Navigate = () => {
        navigate("/");
    }


    return (<div>
        {/*<ToastContainer/>*/}
        <IoArrowBackCircleOutline style={{fontSize: "25px", cursor: "pointer"}} onClick={Navigate}/>
        <div className="auth-container__form">
            <SuccessDiv>
                Verification pin has been sent to your email successfully!
            </SuccessDiv>
            <div>
                <label>Enter your 4 Digit Pin</label>
                <input type="text"
                       placeholder="4 digit OTP"
                       name="twoFactorCode"
                       maxLength={4}
                       value={formData.twoFactorCode}
                       onChange={handleChange}
                       required/>
                {errors.twoFactorCode && !formData.twoFactorCode &&
                    <div className="error">{errors.twoFactorCode}</div>}
            </div>


            <button type="submit" onClick={handleVerify}> {loading ? <Loading/> :
                <span>Continue</span>}</button>
        </div>
    </div>)
}

export default Security2FA
