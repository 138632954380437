import React, {useState} from 'react'
import styles from './organisms.module.css'
// import logo from '../../assets/images/Fictional company logo.svg'
import logo from "../../assets/logo.svg";
import {Link} from 'react-router-dom'
import styled from "styled-components";
import {CgMenuGridO} from "react-icons/cg";
import {MdOutlineAddchart, MdOutlineCandlestickChart} from "react-icons/md";
import {HiUsers} from "react-icons/hi2";
import {FcCurrencyExchange} from "react-icons/fc";
import {IoIosNotificationsOutline} from "react-icons/io";


function Navbar() {
    // const {returnSearchQuery} = useContext(AppContext)
    // const [menuItems, setMenuItems] = useState(false)
    // const transform = {
    //     transform: 'translateX(0%)', display: 'flex'
    //
    // }

    const [isOpen, setIsOpen] = useState(false);

    // const handleChange = (e) => {
    //     // setData(e.target.value)
    //     returnSearchQuery(e.target.value)
    // }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    // const walletBalance = 30000


     return (<div>
        <div className={styles.navbar}>
            <div className={styles.navbar__content}>
                <div className={styles['navbar__content--logo']}>
                    <img src={logo}
                         alt="fictional logo"
                         style={{width: "90%"}}
                    />
                </div>


                <div className={styles.navbar__actions}>
                    <DropdownButton onClick={toggleDropdown}>
                        <CgMenuGridO className='icon'/>
                    </DropdownButton>

                    {/*<Profile IsCheck={setIsOpen} onCancel={() => setIsOpen(!isOpen)}/>*/}


                    <DropdownButton onClick={toggleDropdown}>
                        <IoIosNotificationsOutline className='icon'/>
                    </DropdownButton>

                    <DropdownContent isOpen={isOpen}>
                        <DropdownContentWrapper>
                            <div>
                                <DropdownItem>
                                    <Link to="/profile">
                                        <MdOutlineAddchart className='icons'/>
                                        <br/>
                                        <span className='span'>Personal Area</span>
                                    </Link>
                                </DropdownItem>
                                <DropdownItem>
                                    <MdOutlineCandlestickChart className='icon'/>
                                    <br/>
                                    <span className='span'>Public Website</span>
                                </DropdownItem>
                            </div>
                            <div>
                                <DropdownItem>
                                    <FcCurrencyExchange className='icons'/>
                                    <br/>
                                    <span className='span'>Exness Terminal</span>
                                </DropdownItem>
                                <DropdownItem>
                                    <HiUsers className='icon'/>
                                    <br/>
                                    <span className='span'>Partnership</span>
                                </DropdownItem>
                            </div>
                        </DropdownContentWrapper>
                    </DropdownContent>
                </div>


            </div>
        </div>


    </div>)
}


export const StyledButton = styled.button`
    background-color: transparent;
    border: 1px solid #FFCF01;
    border-radius: 5px;
    color: #FFCF01;
    padding: 10px 30px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    //margin-top: 8px;

    &:hover {
        color: #fff;
    }

    @media (max-width: 600px) {
        padding: 8px 15px;
        font-size: 14px;
        display: none;
    }

`;


export const DropdownButton = styled.button`
    background-color: transparent;
    color: white;
    //padding: 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;

    .icon {
        font-size: 30px;
        padding-top: 5px;
        color: white;
    }

    &:active {
        color: #FFCF01;
    }

    &:hover {
        background-color: #20252B;
        color: #FFCF01;
    }

    @media (max-width: 480px) {
        .icon {
            font-size: 33px;
        }

    }
`;

export const DropdownContent = styled.div`
    display: ${props => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    background-color: #1C2227;
    min-width: 270px;
    z-index: 99;
    margin-top: 14rem;
    cursor: pointer;
    //left: auto;
    //right: 0;

    @media (max-width: 768px) {
        min-width: 200px;
        margin-top: 7.5rem;
    }

    @media (max-width: 480px) {
        min-width: 250px;
        margin-top: 15.1rem;
        z-index: 99;
        right: 0;
    }
`;

export const DropdownContentWrapper = styled.div`
    display: flex;
    cursor: pointer;
`;

export const DropdownItem = styled.p`
    color: #FFFFFF;
    //background-color: #000000;
    padding: 20px 30px;
    text-decoration: none;
    text-align: center;
    display: block;
    cursor: pointer;

    .span {
        font-size: 12px;
    }

    .icon {
        font-size: 20px;
    }

    &:hover {
        //background-color: #20252B;
        color: #FFCF01;
    }

`;


export default Navbar
