import React, {useEffect, useState} from 'react'
import logo from "../../assets/unity.png";
import {CgMenuGridO} from "react-icons/cg";
import {MdOutlineAddchart, MdSupportAgent} from "react-icons/md";
import {FcCurrencyExchange} from "react-icons/fc";
//import {HiUsers} from "react-icons/hi2";
import { FaRegUserCircle} from "react-icons/fa";
import {IoIosNotificationsOutline} from "react-icons/io";
import styled, {keyframes} from "styled-components";
import {Link, useNavigate} from "react-router-dom";
import {HiOutlineMenu} from "react-icons/hi";
import {DropdownButton} from "../organisms/navbar";
//import {AiOutlineGlobal} from "react-icons/ai";
import {useAuth} from "../../contexts/AppContext";
import {BiLogOut} from "react-icons/bi";
import {PiCurrencyBtcFill} from "react-icons/pi";
import {CiGlobe, CiWallet} from "react-icons/ci";
import {formatCurrency, loadLan, showLanguageSelect} from "./TABS/formatCurrency";
import 'react-toastify/dist/ReactToastify.css';
import {IoLanguage} from "react-icons/io5";
import {LiaHandsHelpingSolid} from "react-icons/lia";
import getCompanyInfo from "../../Utilities/companyInfo";
import {TbSettings2} from "react-icons/tb";




const Header = ({OpenSidebar}) => {
    const navigate = useNavigate();
    const [isopen, setIsopen] = useState(false);
    const [iswallet, setIswallet] = useState(false);
    const [isprofile, setIsprofile] = useState(false);
    const [isnotification, setIsnotification] = useState(false);
    const [isGoogleTranslate, setIsGoogleTranslate] = useState(false);
    const [googleTranslateInitialized, setGoogleTranslateInitialized] = useState(false);
    //const [scriptLoaded, setScriptLoaded] = useState(false);

    const {Logout, user, setIsLoggedIn, setToken} = useAuth();


    const company = getCompanyInfo()


    const toggleDropdown = () => {
        setIsopen(!isopen);
        setIsprofile(false)
        setIswallet(false);
        setIsGoogleTranslate(false);
        setIsnotification(false);

    };

    const toggleWallet = () => {
        setIswallet(!iswallet);
        setIsopen(false);
        setIsprofile(false)
        setIsGoogleTranslate(false);
        setIsnotification(false);
    };


    const toggleProfile = () => {
        setIsprofile(!isprofile)
        setIswallet(false);
        setIsopen(false);
        setIsGoogleTranslate(false);
        setIsnotification(false);

    };

    const translate = ()=>{
        showLanguageSelect(googleTranslateInitialized)
        setIsGoogleTranslate(!isGoogleTranslate)
        setIsprofile(false)
        setIswallet(false);
        setIsopen(false);
        setIsnotification(false);

    }


    const toggleNotification = () => {
        setIsnotification(!isnotification)
        setIsprofile(false)
        setIswallet(false);
        setIsopen(false);
        setIsGoogleTranslate(false);
    }


    const handleLogout = async () => {
        setIsLoggedIn(false);
        setToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('isLoggedIn');
        try {
           await Logout()
            navigate('/');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };


    // const amount = user?.balance;
    let formattedAmount = formatCurrency(user?.balance);


    useEffect(()=>{
        loadLan(setGoogleTranslateInitialized, googleTranslateInitialized)
    },[googleTranslateInitialized])


    return (

        <header className='header'>
            <div className='menu-icon'>
                <HiOutlineMenu className='open-icon' onClick={OpenSidebar}/>
            </div>

            <div className='header-left'>
                <img src={logo}
                     alt="fictional logo"
                     className='icon'
                     style={{width: "100px", marginTop: "0.5rem"}}
                />
            </div>

            <div className='header-right'>


                <DropdownButton className="CiWallet-w" onClick={toggleWallet}>
                    <p style={{fontSize:25}}><CiWallet/> {formattedAmount} USD</p>
                </DropdownButton>


                <CiWallet onClick={toggleWallet} className='Nicon-m'/>


                <IoLanguage style={{fontSize:20}} onClick={translate} className='Nicon'/>


                <CgMenuGridO className='Nicon' onClick={toggleDropdown}/>

                <IoIosNotificationsOutline className='Nicon hidden' onClick={toggleNotification}/>

                <FaRegUserCircle className='Nicon' onClick={toggleProfile}/>

                <DropdownContent isnotification={isnotification.toString()}>
                    <DropdownContentWrapper>
                        <div className="item-div" style={{padding: "1.5rem"}}>
                            <p>No Notification </p>
                        </div>
                    </DropdownContentWrapper>
                </DropdownContent>


                {/*<DropdownContent isNotification={isGoogleTranslate}>*/}
                {/*    <DropdownContentWrapper style={{width:'30% !important'}}>*/}
                        <div className="item-div" style={{display:isGoogleTranslate ? "block":"none",position:"absolute",zIndex:999}}>
                            <div className='Nicon' id="google_translate_element" style={{display:'flex',justifyContent: 'flex-end', background:' #141D22' }}></div>
                        </div>
                {/*    </DropdownContentWrapper>*/}
                {/*</DropdownContent>*/}


                <DropdownContent iswallet={iswallet.toString()}>
                    <DropdownContentWrapper>
                        <div className="item-div">
                            {/*<p className="item-text-Amount">Wallet</p>*/}
                            <p style={{color:'gray'}}>Main Balance</p>
                            <p style={{fontSize:18,marginBottom:20, color:'green'}} className="">{formattedAmount} USD</p>

                            <p style={{}} className="item-text-ID">#{user?.walletNumber}</p>

                        </div>
                    </DropdownContentWrapper>
                </DropdownContent>

                <DropdownContent isprofile={isprofile.toString()}>
                    {/*<DropdownContentWrapper>*/}
                    <div>
                        <div className="item-user"
                           style={{display: "flex", flexDirection: "row", padding: "15px"}}>
                            <FaRegUserCircle style={{fontSize: "18px"}}/>
                            <div style={{marginLeft: "10px"}}>
                                <p> {user?.firstName} {user?.lastName}</p>
                                <p style={{fontSize: "10px", color: "gray"}}> {user?.email}</p>
                            </div>
                        </div>

                    </div>
                    <hr/>
                    <div className="item">

                        <Link to="/profile/deposit">
                            <p className="item-text">
                                <PiCurrencyBtcFill
                                    style={{
                                        paddingTop: "6px",
                                        fontSize: "20px",
                                        marginRight: "3px"
                                    }}/>
                                Deposit</p>
                        </Link>

                        <Link to="/profile/setting">
                            <p className="item-text">
                                <TbSettings2
                                    style={{
                                        paddingTop: "6px",
                                        fontSize: "20px",
                                        marginRight: "3px"
                                    }}/>
                                Settings</p>
                        </Link>

                        <Link target={'_blank'} to={company.supportUrl}>
                            <p className="item-text">
                                <MdSupportAgent
                                    style={{paddingTop: "6px", fontSize: "20px", marginRight: "3px"}}/>
                                Help Center
                            </p>
                        </Link>

                    </div>
                    <hr/>
                    <div className="item">

                        <p style={{color:'red'}} className="item-text" onClick={handleLogout}>
                            <BiLogOut
                                style={{paddingTop: "6px", fontSize: "20px", marginRight: "3px"}}/>
                            Sign Out
                        </p>
                    </div>
                    {/*</DropdownContentWrapper>*/}
                </DropdownContent>

                <DropdownContent isopen={isopen.toString()}>
                    <DropdownContentWrapper>
                        <div>
                            <DropdownItem>
                                <Link to="/profile">
                                    <MdOutlineAddchart className='icons'/>
                                    <br/>
                                    <span className='span'>My Account</span>
                                </Link>
                            </DropdownItem>
                            <DropdownItem>
                                <Link target={'_blank'} to={company.publicWebsite}>
                                    <CiGlobe className='icon'/>
                                    <br/>
                                    <span className='span'>Public Website</span>
                                </Link>
                            </DropdownItem>
                        </div>
                        <div>
                            <DropdownItem>
                                <Link to="/dashboard">
                                    <FcCurrencyExchange className='icons'/>
                                    <br/>
                                    <span className='span'>Trade Analytics</span>
                                </Link>
                            </DropdownItem>
                            <DropdownItem>
                                <Link target={'_blank'} to={company.supportUrl}>
                                    <LiaHandsHelpingSolid className='icon'/>
                                    <br/>
                                    <span className='span'>Help Center</span>
                                </Link>
                            </DropdownItem>
                        </div>
                    </DropdownContentWrapper>
                </DropdownContent>
            </div>


        </header>)
}

export default Header

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-50px);
    }
`;


const DropdownContent = styled.div`
  animation: ${(props) => (props.isopen || props.iswallet || props.isprofile || props.isnotification ? fadeIn : fadeOut)} 0.8s ease;
  visibility: ${(props) => (props.isopen === "true" || props.iswallet === "true" || props.isprofile === "true" || props.isnotification === "true"  ? "visible" : "hidden")};
  position: absolute;
  //background-color: #141D22;
  background-color: #ffffff;
  //box-shadow: 0 0 5px rgba(21, 21, 21, 0.1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-width: 300px;
  z-index: 99;
  //margin-top: 1px;
  cursor: pointer;
  //left: auto;
  right: 8px;
  top: 3.9rem;
  border-radius: 5px;

  @media only screen and (min-width: 600px) and (max-width: 900px) and (max-width: 992px) and (min-width: 992px) and (max-width: 768px) and (min-width: 768px) {
    top: 1rem !important;
  }
  
  // display: none; // initially hide the dropdown content
  // ${(props) => (props.isOpen || props.iswallet || props.isprofile || props.isnotification) && `
  //   display: block;
  // `}

  .item-div {
    padding: 13px;
    font-size: 12px;
    color: #000000;
    //color: #FFFFFF;
  }

  .item {
    padding: 0.2rem 0;
  }

  hr {
    color: #FFFFFF
  }

  .item-user {
    padding: 6px 1.1rem;
    font-size: 13px;
    color: #000000;
  }

  .item-text {
    padding: 6px 1rem;
    font-size: 13px;
    color: #000000;
  }

  .item-text:hover {
    //color: #f1c40f;
    background-color: #DFE4E7

  }

  .item-text-ID {
    padding: 5px;
    //color: #9e9ea4;
    color: #000000;
  }

  .item-text-Amount {
    font-weight: 700;
    padding: 5px;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    min-width: 200px;
    margin-top: 0.5rem;
  }

  @media (max-width: 480px) {
    min-width: 250px;
    margin-top: 1rem;
    z-index: 99;
    right: 0;
  }
`;

const DropdownContentWrapper = styled.div`
        // animation: ${(props) => (props.isopen ? fadeIn : fadeOut)} 0.5s ease;
        // visibility: ${props => (props.isopen || props.iswallet || props.isprofile ? 'visible' : 'hidden')};
        //animation: ${(props) => (props.isopen ? fadeIn : fadeOut)} 0.5s ease;
    display: flex;
    cursor: pointer;
    width: 100%;
    color: #000000;
    //flex-direction: column;

    a {
        color: #000000;
    }

    .item {
        padding: 1rem;
        color: #000000;
    }

    .item-text {
        padding: 8px;
        font-size: 13px;
        color: #000000;
    }

    .item-text:hover {
        color: #f1c40f;

    }
`;

const DropdownItem = styled.div`
    //color: #FFFFFF;
    color: #000000;
    //background-color: #000000;
    padding: 20px 30px;
    text-decoration: none;
    text-align: center;
    display: block;
    cursor: pointer;

    .span {
        font-size: 12px;
    }

    .span:hover {
        color: #FFCF01;
    }

    .icon {
        font-size: 20px;
    }

    &:hover {
        //background-color: #20252B;
        color: #FFCF01;
    }

`;

