import styled from "styled-components";
import Tab, {MainContainer} from "../TABS/Tabs";
import React from "react";
import {AccountCard, ArchivedCard, DemoCard} from "../TABS/Card";
import {useAuth} from "../../../contexts/AppContext";
import withLoader from "../../../Loader/WithLoader";
import {GeneralTransactionHistory} from "../TABS/TransactionHistory/GeneralTransactionHistory";
import EmptyImage from "../TABS/EmptyImage";
import {InvestmentHistory} from "../TABS/TransactionHistory/InvestmentHistory";
import 'react-toastify/dist/ReactToastify.css';
//import {ToastContainer} from 'react-toastify';


const Account = () => {
    const {user, transactionHistory, investmentHistory} = useAuth();


    const tabs = [{
        title: "Account", content: <MainContainer>
            <AccountCard  user={user}/>
            <p className='header-text'>See all Transaction history.</p>
            {transactionHistory && transactionHistory.length > 0 ? (
                <GeneralTransactionHistory user={user}
                                           transactionHistory={transactionHistory}/>) : (
                <EmptyImage/>)}

        </MainContainer>
    }, {
        title: "Investment Wallet", content: <MainContainer>
            <DemoCard user={user}/>
            <p className='header-text'>See all Investment history.</p>
            {investmentHistory && investmentHistory.length > 0 ? (
                <InvestmentHistory investmentHistory={investmentHistory}/>) : (<EmptyImage title={'No Investment Yet'} subTitle={'There are currently no investment to display'} headColor={'#000'}/>)}

        </MainContainer>
    }, {
        title: "Downline Partners", content: <MainContainer>
            <ArchivedCard user={user} />
        </MainContainer>
    }]

    return (<main className='main-container'>
        {/*<ToastContainer/>*/}
        <Wrapper>
            <div className='title'>
                <h4>My accounts</h4>
                {user?.accountStatus === true && (<p style={{
                    color: "#00C076",
                    fontWeight: "600",
                    fontSize: "15px"
                }}>Verified</p>)}
                {/*<GreyButton onClick={handleSetting}>Account Settings</GreyButton>*/}
            </div>
            <Tab tabs={tabs}/>

        </Wrapper>
    </main>);
}

export const Wrapper = styled.div`
    color: #141D22;

    .title {
        margin: 20px 0px;
        color: #000;
        display: flex;
        justify-content: space-between;
    }

    h6 {
        color: #141D22;
    }

    h4 {
        font-size: 28px;
        margin-bottom: 10px;
        color: #141D22;
    }

    p {
        font-size: 12px;
        color: #434749;
        margin-bottom: 20px;
    }

    .balance-div {
        margin-top: 2rem;
        color: #1C2127;
    }

    .balance {
        color: #434749;
        font-size: 20px;
        margin-top: 5px;
    }

    .balance-text {
        color: #74797C;
        font-size: 15px;

    }

    @media screen and (max-width: 992px) {
        .title{
            padding-left: 1rem;
            padding-top: 1rem;
        }
    }
    @media screen and (max-width: 576px) {
        color: #000;
        width: 100%;
        //padding: 1rem;
        .title {
            margin: 0px 0px;
            color: #000;
        }

        h4 {
            font-size: 20px;
            color: #000;
        }

        p {
            font-size: 12px;
            color: #000;
        }

        .balance {
            color: #ffffff;
        }
    }

`
// export default Account
export default withLoader(Account);
