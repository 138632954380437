import styled from 'styled-components';

// Country data with flags
export const countries = [{name: 'United States', flag: '🇺🇸'}, {name: 'United Kingdom', flag: '🇬🇧'}, {
    name: 'Canada', flag: '🇨🇦'
}, // Add more countries as needed
];

// Styled components
export const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
`;

export const DropdownButton = styled.div`
    background-color: #fff;
    //border: 1px solid #ccc;
    border: 1px solid #A7B1BC;
    padding: 10px;
    min-width: 150px;
    text-align: left;
    width: 100%;
    color: #1C2127;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .image {
        width: 10%;
    }

    .text {
        width: 90%;
    }
`;


export const DropdownContent = styled.div`
    position: absolute;
    background-color: #fff;
    border: 1px solid #A7B1BC;
    border-top: none;
    width: 100%;
    color: #1C2127;
    z-index: 1;
    max-height: 200px; /* Adjust the height as needed */
    overflow-y: auto;
    top: 2.5rem;
    border-radius: 3px; /* Add border radius */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
`;

export const CountryItem = styled.div`
    padding: 5px 15px;
    //cursor: pointer;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    //gap: 30px;

    .image {
        width: 10%;
    }

    .text {
        width: 90%;
    }

    &:hover {
        background-color: rgba(186, 189, 190, 0.63);
    }

    .span {
        padding-top: 0;
        color: #1C2127;
    }
`;


