import React from 'react'
import {Link} from 'react-router-dom'
import AuthHeader from "../components/Authentication/Header";
import Footer from "../components/Authentication/Footer";
import ResetPassword from "../components/Authentication/ResetPassword";

const ResetPasswords = () => {
    // const navigate = useNavigate();

    return (

        <div className="Tab_Auth">
            <AuthHeader/>
            <div className="auth-layout">
                <div>
                    <h2 className="auth-layout-text">Reset password</h2>
                    <div className="auth-container">
                        <ResetPassword/>
                    </div>
                </div>

            </div>
            <Link className="Link" to='/'>
                <span>sign in now </span>
            </Link>

            <Footer/>
        </div>

    )
}

export default ResetPasswords