import React from "react"
import {MainContainer} from "../TABS/Tabs";
import {Wrapper} from "./Account";
import {DepositBanksCard, UnavailableCard} from "../TABS/Deposit&WithdrawCard";
import {GridContainer} from "./InvestmentWallet";
import withLoader from "../../../Loader/WithLoader";
import 'react-toastify/dist/ReactToastify.css';
//import {ToastContainer} from 'react-toastify';
import {recommendedWallets, unavailableWallets} from "../../../Utilities/wallet";

const Deposits = () => {


    return (<main className='main-container'>
        {/*<ToastContainer/>*/}
        <Wrapper>

            <MainContainer>
                <div className='title'>
                    <div>
                        <h4>Deposit</h4>
                        <p>Funding your wallet is made significantly easier with a wide range of payment alternatives available</p>
                    </div>
                </div>
                <GridContainer>
                    {recommendedWallets?.slice(0, 1).map((bank, index) => (
                        <DepositBanksCard bank={bank} key={index}/>))}
                    {unavailableWallets?.map((bank, index) => (
                        <UnavailableCard bank={bank} key={index}/>))}
                </GridContainer>
            </MainContainer>
        </Wrapper>

    </main>)
}

// export default Deposits
export default withLoader(Deposits);
