export const Countries = [{name: "Afghanistan", value: "Afghanistan"}, {
    name: "Albania",
    value: "Albania"
}, {name: "Algeria", value: "Algeria"}, {name: "Andorra", value: "Andorra"}, {
    name: "Angola",
    value: "Angola"
}, {name: "Antigua and Barbuda", value: "Antigua and Barbuda"}, {
    name: "Argentina",
    value: "Argentina"
}, {name: "Armenia", value: "Armenia"}, {name: "Australia", value: "Australia"}, {
    name: "Austria",
    value: "Austria"
}, {name: "Azerbaijan", value: "Azerbaijan"}, {name: "Bahamas", value: "Bahamas"}, {
    name: "Bahrain",
    value: "Bahrain"
}, {name: "Bangladesh", value: "Bangladesh"}, {name: "Barbados", value: "Barbados"}, {
    name: "Belarus",
    value: "Belarus"
}, {name: "Belgium", value: "Belgium"}, {name: "Belize", value: "Belize"}, {
    name: "Benin",
    value: "Benin"
}, {name: "Bhutan", value: "Bhutan"}, {name: "Bolivia", value: "Bolivia"}, {
    name: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina"
}, {name: "Botswana", value: "Botswana"}, {name: "Brazil", value: "Brazil"}, {
    name: "Brunei",
    value: "Brunei"
}, {name: "Bulgaria", value: "Bulgaria"}, {name: "Burkina Faso", value: "Burkina Faso"}, {
    name: "Burundi",
    value: "Burundi"
}, {name: "Cabo Verde", value: "Cabo Verde"}, {name: "Cambodia", value: "Cambodia"}, {
    name: "Cameroon",
    value: "Cameroon"
}, {name: "Canada", value: "Canada"}, {
    name: "Central African Republic",
    value: "Central African Republic"
}, {name: "Chad", value: "Chad"}, {name: "Chile", value: "Chile"}, {name: "China", value: "China"}, {
    name: "Colombia",
    value: "Colombia"
}, {name: "Comoros", value: "Comoros"}, {name: "Congo", value: "Congo"}, {
    name: "Costa Rica",
    value: "Costa Rica"
}, {name: "Croatia", value: "Croatia"}, {name: "Cuba", value: "Cuba"}, {
    name: "Cyprus",
    value: "Cyprus"
}, {name: "Czech Republic", value: "Czech Republic"}, {
    name: "Democratic Republic of the Congo",
    value: "Democratic Republic of the Congo"
}, {name: "Denmark", value: "Denmark"}, {name: "Djibouti", value: "Djibouti"}, {
    name: "Dominica",
    value: "Dominica"
}, {name: "Dominican Republic", value: "Dominican Republic"}, {
    name: "East Timor",
    value: "East Timor"
}, {name: "Ecuador", value: "Ecuador"}, {name: "Egypt", value: "Egypt"}, {
    name: "El Salvador",
    value: "El Salvador"
}, {name: "Equatorial Guinea", value: "Equatorial Guinea"}, {name: "Eritrea", value: "Eritrea"}, {
    name: "Estonia",
    value: "Estonia"
}, {name: "Eswatini", value: "Eswatini"}, {name: "Ethiopia", value: "Ethiopia"}, {
    name: "Fiji",
    value: "Fiji"
}, {name: "Finland", value: "Finland"}, {name: "France", value: "France"}, {
    name: "Gabon",
    value: "Gabon"
}, {name: "Gambia", value: "Gambia"}, {name: "Georgia", value: "Georgia"}, {
    name: "Germany",
    value: "Germany"
}, {name: "Ghana", value: "Ghana"}, {name: "Greece", value: "Greece"}, {
    name: "Grenada",
    value: "Grenada"
}, {name: "Guatemala", value: "Guatemala"}, {name: "Guinea", value: "Guinea"}, {
    name: "Guinea-Bissau",
    value: "Guinea-Bissau"
}, {name: "Guyana", value: "Guyana"}, {name: "Haiti", value: "Haiti"}, {
    name: "Honduras",
    value: "Honduras"
}, {name: "Hungary", value: "Hungary"}, {name: "Iceland", value: "Iceland"}, {
    name: "India",
    value: "India"
}, {name: "Indonesia", value: "Indonesia"}, {name: "Iran", value: "Iran"}, {
    name: "Iraq",
    value: "Iraq"
}, {name: "Ireland", value: "Ireland"}, {name: "Israel", value: "Israel"}, {
    name: "Italy",
    value: "Italy"
}, {name: "Ivory Coast", value: "Ivory Coast"}, {name: "Jamaica", value: "Jamaica"}, {
    name: "Japan",
    value: "Japan"
}, {name: "Jordan", value: "Jordan"}, {name: "Kazakhstan", value: "Kazakhstan"}, {
    name: "Kenya",
    value: "Kenya"
}, {name: "Kiribati", value: "Kiribati"}, {name: "Kosovo", value: "Kosovo"}, {
    name: "Kuwait",
    value: "Kuwait"
}, {name: "Kyrgyzstan", value: "Kyrgyzstan"}, {name: "Laos", value: "Laos"}, {
    name: "Latvia",
    value: "Latvia"
}, {name: "Lebanon", value: "Lebanon"}, {name: "Lesotho", value: "Lesotho"}, {
    name: "Liberia",
    value: "Liberia"
}, {name: "Libya", value: "Libya"}, {name: "Liechtenstein", value: "Liechtenstein"}, {
    name: "Lithuania",
    value: "Lithuania"
}, {name: "Luxembourg", value: "Luxembourg"}, {name: "Madagascar", value: "Madagascar"}, {
    name: "Malawi",
    value: "Malawi"
}, {name: "Malaysia", value: "Malaysia"}, {name: "Maldives", value: "Maldives"}, {
    name: "Mali",
    value: "Mali"
}, {name: "Malta", value: "Malta"}, {name: "Marshall Islands", value: "Marshall Islands"}, {
    name: "Mauritania",
    value: "Mauritania"
}, {name: "Mauritius", value: "Mauritius"}, {name: "Mexico", value: "Mexico"}, {
    name: "Micronesia",
    value: "Micronesia"
}, {name: "Moldova", value: "Moldova"}, {name: "Monaco", value: "Monaco"}, {
    name: "Mongolia",
    value: "Mongolia"
}, {name: "Montenegro", value: "Montenegro"}, {name: "Morocco", value: "Morocco"}, {
    name: "Mozambique",
    value: "Mozambique"
}, {name: "Myanmar", value: "Myanmar"}, {name: "Namibia", value: "Namibia"}, {
    name: "Nauru",
    value: "Nauru"
}, {name: "Nepal", value: "Nepal"}, {name: "Netherlands", value: "Netherlands"}, {
    name: "New Zealand",
    value: "New Zealand"
}, {name: "Nicaragua", value: "Nicaragua"}, {name: "Niger", value: "Niger"}, {
    name: "Nigeria",
    value: "Nigeria"
}, {name: "North Korea", value: "North Korea"}, {name: "North Macedonia", value: "North Macedonia"}, {
    name: "Norway",
    value: "Norway"
}, {name: "Oman", value: "Oman"}, {name: "Pakistan", value: "Pakistan"}, {
    name: "Palau",
    value: "Palau"
}, {name: "Palestine", value: "Palestine"}, {name: "Panama", value: "Panama"}, {
    name: "Papua New Guinea",
    value: "Papua New Guinea"
}, {name: "Paraguay", value: "Paraguay"}, {name: "Peru", value: "Peru"}, {
    name: "Philippines",
    value: "Philippines"
}, {name: "Poland", value: "Poland"}, {name: "Portugal", value: "Portugal"}, {
    name: "Qatar",
    value: "Qatar"
}, {name: "Romania", value: "Romania"}, {name: "Russia", value: "Russia"}, {
    name: "Rwanda",
    value: "Rwanda"
}, {name: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis"}, {
    name: "Saint Lucia",
    value: "Saint Lucia"
}, {name: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines"}, {
    name: "Samoa",
    value: "Samoa"
}, {name: "San Marino", value: "San Marino"}, {
    name: "Sao Tome and Principe",
    value: "Sao Tome and Principe"
}, {name: "Saudi Arabia", value: "Saudi Arabia"}, {name: "Senegal", value: "Senegal"}, {
    name: "Serbia",
    value: "Serbia"
}, {name: "Seychelles", value: "Seychelles"}, {name: "Sierra Leone", value: "Sierra Leone"}, {
    name: "Singapore",
    value: "Singapore"
}, {name: "Slovakia", value: "Slovakia"}, {name: "Slovenia", value: "Slovenia"}, {
    name: "Solomon Islands",
    value: "Solomon Islands"
}, {name: "Somalia", value: "Somalia"}, {name: "South Africa", value: "South Africa"}, {
    name: "South Korea",
    value: "South Korea"
}, {name: "South Sudan", value: "South Sudan"}, {name: "Spain", value: "Spain"}, {
    name: "Sri Lanka",
    value: "Sri Lanka"
}, {name: "Sudan", value: "Sudan"}, {name: "Suriname", value: "Suriname"}, {
    name: "Sweden",
    value: "Sweden"
}, {name: "Switzerland", value: "Switzerland"}, {name: "Syria", value: "Syria"}, {
    name: "Taiwan",
    value: "Taiwan"
}, {name: "Tajikistan", value: "Tajikistan"}, {name: "Tanzania", value: "Tanzania"}, {
    name: "Thailand",
    value: "Thailand"
}, {name: "Togo", value: "Togo"}, {name: "Tonga", value: "Tonga"}, {
    name: "Trinidad and Tobago",
    value: "Trinidad and Tobago"
}, {name: "Tunisia", value: "Tunisia"}, {name: "Turkey", value: "Turkey"}, {
    name: "Turkmenistan",
    value: "Turkmenistan"
}, {name: "Tuvalu", value: "Tuvalu"}, {name: "Uganda", value: "Uganda"}, {
    name: "Ukraine",
    value: "Ukraine"
}, {name: "United Arab Emirates", value: "United Arab Emirates"}, {
    name: "United Kingdom",
    value: "United Kingdom"
}, {name: "United States", value: "United States"}, {name: "Uruguay", value: "Uruguay"}, {
    name: "Uzbekistan",
    value: "Uzbekistan"
}, {name: "Vanuatu", value: "Vanuatu"}, {name: "Vatican City", value: "Vatican City"}, {
    name: "Venezuela",
    value: "Venezuela"
}, {name: "Vietnam", value: "Vietnam"}, {name: "Yemen", value: "Yemen"}, {
    name: "Zambia",
    value: "Zambia"
}, {name: "Zimbabwe", value: "Zimbabwe"}];
