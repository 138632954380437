import React, {useEffect, useState} from 'react'
// import logo from "../../assets/images/Fictional company logo.svg";
// import logo from "../../assets/logo.svg";
import logo from "../../assets/unity.png";
import styled from "styled-components";
import {AiOutlineGlobal} from "react-icons/ai";
import {IoLanguage} from "react-icons/io5";
import {loadLan, showLanguageSelect} from "../ProfileDashboard/TABS/formatCurrency";
import getCompanyInfo from "../../Utilities/companyInfo";
import {Link} from "react-router-dom";

const AuthHeader = ({OpenSidebar}) => {

    const [isGoogleTranslate, setIsGoogleTranslate] = useState(false)
    const [googleTranslateInitialized, setGoogleTranslateInitialized] = useState(false);


    const company = getCompanyInfo()


    const translate = () => {
        !isGoogleTranslate ? setIsGoogleTranslate(true) : setIsGoogleTranslate(false)
        showLanguageSelect(googleTranslateInitialized)
    }

    useEffect(()=>{
        loadLan(setGoogleTranslateInitialized, googleTranslateInitialized)
    },[googleTranslateInitialized])

    return (<TopHeader className='header'>
            {/*<header className='header'>*/}
            {/*<div className='menu-icon'>*/}
            {/*    <GiTrade className='open-icon' onClick={OpenSidebar}/>*/}
            {/*</div>*/}
            <div className='header-left'>
                {/*<BsSearch className='icon'/>*/}
                <Link target='_blank' to={company.publicWebsite}>
                    <img
                        src={logo}
                        alt="fictional logo"
                        className='icon'
                        style={{width: "30%",}}
                    />
                </Link>
            </div>
            <div className='header-right'>
               <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>

                   <Link target='_blank' to={company.publicWebsite}>
                       <AiOutlineGlobal className='Nicon' style={{fontSize: "25px"}}/>
                   </Link>

                   <IoLanguage style={{fontSize:25}} onClick={()=>translate()} className='Nicon '/>
               </div>

                <div className="item-div" style={{display:isGoogleTranslate ? "block":"none",position:"absolute",zIndex:999}}>
                    <div className='Nicon' id="google_translate_element" style={{display:'flex',justifyContent: 'flex-end', background:' #141D22' }}></div>
                </div>

            </div>
            {/*</header>*/}
        </TopHeader>


    )

}

export default AuthHeader

const TopHeader = styled.div`
    padding-left: 7rem;
    padding-right: 7rem;
    @media (max-width: 768px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
`
