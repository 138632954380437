import styled from "styled-components";
import {useState} from "react";
import Pagination from "../../../../Loader/Pagination";
import { FaArrowDown, FaArrowUp} from "react-icons/fa";
import {truncateText} from "../formatCurrency";


// DashboardTransactionHistory



export const DashboardTransactionHistory = ({transactionHistory, user}) => {

    console.log(transactionHistory, "transactionHistory")

    const [currentPage, setCurrentPage] = useState(1);

    const PAGE_SIZE = 6;
    const totalPages = Math.ceil(transactionHistory.length / PAGE_SIZE);

    // Calculate the index range for the current page
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, transactionHistory.length);

    // Slice the transaction history to display transactions for the current page
    //const transactions = Array?.isArray(transactionHistory) ? transactionHistory : [];
    // const currentTransactions = transactions?.slice(startIndex, endIndex)?.reverse();


    const reversedTransactionHistory = transactionHistory.slice();
    const currentTransactions = reversedTransactionHistory.slice(startIndex, endIndex);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    // console.log(user, "trans")
    return (<div>
        <TransactionContainer>
            {/*{currentTransactions?.map((transaction, index) => (<Transaction key={index}>*/}
            {currentTransactions?.map((transaction, index) => (
                <Transaction key={index}>
                    {
                        transaction?.sender === user?.email && transaction?.paymentOption === "P2P_Withdrawal" ? (
                            <TransactionImage>
                                <FaArrowUp style={{fontSize: "16px", color: "red"}}/>
                            </TransactionImage>
                        ) : (
                            transaction?.sender !== user?.email &&  transaction?.paymentOption === "P2P_Withdrawal" ? (
                                <TransactionImage>
                                    <FaArrowDown style={{fontSize: "16px", color: "#00C076"}}/>
                                </TransactionImage>
                            ) : (
                                transaction?.paymentOption === "Deposit" || transaction?.paymentOption === "redeemInvestment" || transaction?.paymentOption === "Referral Reward" ? (
                                    <TransactionImage>
                                        <FaArrowDown style={{fontSize: "16px", color: "#00C076"}}/>
                                    </TransactionImage>
                                ) : (
                                    <TransactionImage>
                                        <FaArrowUp style={{fontSize: "16px", color: "red"}}/>
                                    </TransactionImage>
                                )
                            )
                        )
                    }



                    <TransactionDetails>
                        {transaction?.paymentOption === "redeemInvestment" ? (
                                <TransactionTitle>Redeem Investment</TransactionTitle>) :

                            transaction?.sender === user?.email && transaction?.paymentOption === "P2P_Withdrawal" ? (
                                <TransactionTitle>
                                    P2P Transfer
                                </TransactionTitle>
                            ) : (
                                transaction?.sender !== user?.email &&  transaction?.paymentOption === "P2P_Withdrawal" ? (
                                        <TransactionTitle>
                                            P2P Received
                                        </TransactionTitle>
                                    ):
                                    (<TransactionTitle>{transaction?.paymentOption}</TransactionTitle>))}

                        {transaction?.fundAdmin ? (
                            <WalletAddress> To. {truncateText(transaction?.fundAdmin)}  </WalletAddress>) : transaction?.toWalletAddress ? (
                            <WalletAddress> To.{truncateText(transaction?.toWalletAddress)} </WalletAddress>) : (
                            <WalletAddress> To. {transaction?.toAccount} </WalletAddress>)}

                        <WalletAddress> Date: {transaction.date}</WalletAddress>
                        <WalletAddress> Receipt No. {transaction?.receiptNo} </WalletAddress>
                    </TransactionDetails>
                    <div>

                        <TransactionAmount>
                            {transaction?.paymentMethod}
                        </TransactionAmount>

                        {transaction?.paymentOption === "Deposit" || transaction?.paymentOption === "redeemInvestment" || transaction?.paymentOption === "Referral Reward" ?
                            (<TransactionAmount style={{color: "#00C076"}}>+${transaction.amount}</TransactionAmount>)
                            :  transaction?.paymentOption === "P2P_Withdrawal" && transaction?.sender !== user?.email  ? (
                                    <TransactionAmount style={{color: "#00C076"}}>+${transaction.amount}</TransactionAmount>
                                ) :
                                (<TransactionAmount style={{color: "red"}}>-${transaction.amount}</TransactionAmount>)}

                        <TransactionAmount>
                            {transaction?.paymentOption === "Deposit" ?
                                transaction?.depositStatus :
                                transaction?.paymentOption === "redeemInvestment" || transaction?.paymentOption === "Referral Reward" ?
                                    transaction?.transactionStatus
                                    :
                                    transaction?.paymentOption === "Withdrawal" ?
                                        transaction?.withdrawalStatus
                                        :
                                        transaction?.paymentOption === "P2P_Withdrawal" ?
                                            transaction?.transferStatus
                                            :
                                            transaction?.transactionStatus

                            }
                        </TransactionAmount>

                    </div>
                </Transaction>))}
        </TransactionContainer>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange}/>
    </div>)
}


const TransactionContainer = styled.div`
    width: 100%;
    background-color: #121521;
`;

const Transaction = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;

    @media (max-width: 768px) {
        //flex-direction: column;
        align-items: flex-start;
        color: #ffffff;
    }
`;

const TransactionImage = styled.div`
  border-radius: 50%;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TransactionDetails = styled.div`
    flex-grow: 1;
    margin-right: 20px;
`;

const TransactionTitle = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
    color: #00C076;
    @media (max-width: 768px) {
        color: #ffffff;
        margin-top: 6px;
    }
`;

const WalletAddress = styled.div`
    color: #ffffff;
    font-size: 12px;
    margin-top: 8px;
    @media (max-width: 768px) {
        color: #ffffff;
        margin-top: 6px;
    }
`;

const TransactionAmount = styled.div`
    color: #ffffff;
    font-size: 13px;
    margin-top: 6px;
    justify-items: flex-end;
    text-align: end;
    @media (max-width: 768px) {
        color: #ffffff;
        margin-top: 6px;
    }
`;
