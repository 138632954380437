import React from 'react'
import AuthHeader from "../components/Authentication/Header";
import Footer from "../components/Authentication/Footer";
import ResetPasswordOTP from "../components/Authentication/ResetPasswordOTP";

const ResetPasswordOTPs = () => {
    // const navigate = useNavigate();

    return (

        <div className="Tab_Auth">
            <AuthHeader/>
            <div className="auth-layout">
                <div>
                    <h2 className="auth-layout-text">Reset password OTP</h2>
                    <div className="auth-container">
                        <ResetPasswordOTP/>
                    </div>
                </div>

            </div>


            <Footer/>
        </div>

    )
}

export default ResetPasswordOTPs