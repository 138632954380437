import React from "react"
import Layout from "../../components/ProfileDashboard/SideBarLayout";
import Withdrawal from "../../components/ProfileDashboard/SideBarPages/Withdrawal";


const WithdrawFunds = () => {

    return (<Layout>
        <Withdrawal/>
    </Layout>)

}

export default WithdrawFunds


