import React, {useState} from 'react';
import styled from 'styled-components';
import {MdOutlineKeyboardArrowDown, MdVerifiedUser} from "react-icons/md";
import Loading from "../../../../Loader/Loading";
import {useAuth} from "../../../../contexts/AppContext";
import {ResetUserOTPSchema} from "../../../../validation/validation";
import * as Yup from "yup";
import {ProfileUpdate} from "../../../Authentication/UpdateProfile";
import 'react-toastify/dist/ReactToastify.css';
//import { toast } from 'react-toastify';


const ProfileUpdateAccordion = () => {
    const [openStep, setOpenStep] = useState(null);

    const toggleStep = (step) => {
        setOpenStep(step === openStep ? null : step);
    };


    const [verificationSent, ] = useState(false);
    const {requestUserOTP, verifyUserOTP, user} = useAuth();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [show, ] = useState(false);


    const [formData, setFormData] = useState({
        otp: '',
    });


    const sendVerificationOTP = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await requestUserOTP()
                .then((data) => {
                   // console.log(data.status, "status")
                    if (data.status) {
                        alert('Verification code has been sent successfully');
                    } else {
                       // console.log(data.message, "error message")
                        alert(data.message);
                    }
                })
                .catch((error) => {
                    //console.log("Oops Something Went Wrong Please Try Again")
                    alert("Oops Something Went Wrong Please Try Again");
                })
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                    alert(error.message)
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await ResetUserOTPSchema.validate(formData, {abortEarly: false});
            await verifyUserOTP(formData.otp)
                .then((data) => {
                    if (data.status) {
                        alert("OTP verified successfully");
                        setFormData({
                            otp: ""
                        })
                    } else {
                        //console.log(data.message, "error message")
                        alert(data.message);
                    }
                })
                .catch((error) => {
                    //console.log("Oops Something Went Wrong Please Try Again")
                    alert("Oops Something Went Wrong Please Try Again");
                })
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                    alert(error.message)
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e, countryName) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };


    return (

        <AccordionContainer>
        <Steps>
            <StepHeader onClick={() => toggleStep(1)}>
                <div className="step-header">
                    <span className="step-number">1</span>
                    <span>Verify Email</span>
                </div>
                <MdOutlineKeyboardArrowDown
                    className={`step-icon ${openStep === 1 ? 'open' : ''}`}/>
            </StepHeader>
            <StepContent isOpen={openStep === 1}>
                <Container>
                    <div>
                        <TextWrapper>

                            {user?.verification?.email === true ? (
                                <div className="verification_form">
                                    <button type="button" disabled style={{background: "#00C076"}}>
                                        {loading ? <Loading/> : <span style={{
                                            textTransform: "capitalize",
                                            fontWeight: "bold",
                                            letterSpacing: "5px",
                                            fontSize: "15px",
                                            color: "#ffff"
                                        }}>EMAIL VERIFIED  <MdVerifiedUser/> </span>}
                                    </button>
                                    <p style={{
                                        fontSize: "12px",
                                        color: "#00C076",
                                        marginTop: "1rem",
                                        textTransform: "capitalize"
                                    }}>Email Verification completed Please contact
                                        customer care If You need Help.</p>

                                </div>) : (<div className="section">
                                <h5>Verify Email</h5>
                                <h6>Verify your email to enhance account security.</h6>
                                <span>Email: <span
                                    style={{fontSize: "13px",textTransform:'lowercase'}}>{user?.email}</span></span>
                                <div className="verification_form">
                                    <div>
                                        <input type="text"
                                               placeholder="Verification Code"
                                               name="otp"
                                               value={formData.otp}
                                               onChange={handleChange}
                                               required/>
                                        {errors.otp && !formData.otp &&
                                            <div className="error">{errors.otp}</div>}
                                        {!verificationSent ? (
                                            <p className="verification_text"
                                               onClick={sendVerificationOTP} style={{
                                                cursor: 'pointer',
                                                fontWeight: "600",
                                                fontSize: "12px",
                                                color: "#B9A62CFF"
                                            }}>
                                                <div style={{fontSize:15,marginTop:10}}>Send Code?</div>
                                            </p>) : (
                                            <p className="verification_text">Verification code has
                                                been sent to your
                                                email.</p>)}
                                    </div>


                                    <button type="button" onClick={handleVerify}>
                                        {loading ? <Loading/> :
                                            <span style={{textTransform: "capitalize"}}>verify email </span>}
                                    </button>
                                    {show && (<p className="verification_text">email Verification
                                        successfully.</p>)}
                                </div>


                            </div>)}


                        </TextWrapper>
                    </div>
                </Container>
            </StepContent>
        </Steps>


        <Steps>
            <StepHeader onClick={() => toggleStep(2)}>
                <div className="step-header">
                    <span className="step-number">2</span>
                    <span>Update Personal Profile</span>
                </div>
                <MdOutlineKeyboardArrowDown
                    className={`step-icon ${openStep === 2 ? 'open' : ''}`}/>
            </StepHeader>
            <StepContent isOpen={openStep === 2}>
                <Container>
                    <TextWrapper>
                        {user?.verification?.profile === true ? (
                            <div className="verification_form">
                                <button type="button" disabled style={{background: "#00C076"}}>
                                    {loading ? <Loading/> : <span style={{
                                        textTransform: "capitalize",
                                        fontWeight: "bold",
                                        letterSpacing: "5px",
                                        fontSize: "15px",
                                        color: "#ffff"
                                    }}>PROFILE UPDATED  <MdVerifiedUser/> </span>}
                                </button>
                                <p style={{fontSize:"12px", color: "#00C076", marginTop: "1rem", textTransform:"capitalize"}}>Profile update completed Please contact
                                    customer care If You need Help.</p>
                            </div>
                        ) : (<ProfileUpdate/>)}
                    </TextWrapper>

                </Container>
            </StepContent>

        </Steps>


        </AccordionContainer>



    );
};

export default ProfileUpdateAccordion;


const AccordionContainer = styled.div`
    width: 100%;
    margin: 20px auto;
    border-radius: 2px;
    border: none;

    @media screen and (max-width: 576px) {
        p, span {
            color: #1C2127;
        }
    }
`;

const Steps = styled.div`
    background-color: #ffffff;
    border-top: 0;
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    border-left: 1px solid #E0E0E0;
    border-radius: 5px;
    margin-bottom: ${({isOpen}) => (!isOpen ? '7px' : '20px')};
`;

const StepHeader = styled.div`
    width: 100%;
    background-color: #ffffff;
    padding: 15px;
    cursor: pointer;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({isOpen}) => (isOpen ? '2rem' : '0')};

    .step-header {

        span {
            margin: 0.4rem;
            font-size: 12px;
        }

        .step-number {
            width: 50%;
            height: 50%;
            padding: 8px 12px;
            border-radius: 50%;
            font-size: 13px;
            color: #000000;
            font-weight: bold;
            background: ${(props) => (props.isOpen ? '#5b5f62' : '#E0E0E0')};
        }
    }

    .step-icon {
        font-size: 25px;
        color: #9e9ea4;
    }

    .step-icon.open {
        transform: rotate(180deg);
    }

`;

const StepContent = styled.div`
    //padding: 10px;
    display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
`;


const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2rem;

    .Document {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: start;
    }

    .hr {
        height: 1px;
        color: rgba(255, 255, 255, 0.31);
    }

    .Details {
        margin-top: 1rem;
    }

    .Details_Numbers {
        font-weight: bold;
        color: #00C076;
        font-size: 18px;
    }

    @media screen and (max-width: 576px) {
        gap: 1px;
        padding: 1.2rem;
    }

`;


const TextWrapper = styled.div`
    padding: 1rem;
    width: 100%;
    //height: 30vh;

    .verification_form {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .verification_form input {
        border: 1px solid #A7B1BC;
        background: transparent;
        outline: none;
        color: #1C2127;
        font-size: 12px;
        border-radius: 3px;
        padding: 13px;
        margin-top: 1rem;
        width: 100%;
    }

    .verification_form button {
        background: #FFCF01;
        color: #1C2127;
        outline: none;
        border: none;
        border-radius: 6px;
        padding: 10px;
        cursor: pointer;
        margin-top: 1rem;
    }

    span {
        font-size: 15px;
        margin-top: 5px;

    }

    h6 {
        font-size: 15px;
        margin-top: 5px;
    }

    .title_main {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
    }

    .title {
        display: flex;
        justify-content: end;
        font-size: 13px;
    }

    .edit {
        //margin-left: 5rem;
        font-size: 28px;
    }

    .verification_text {
        font-size: 11px;
        text-align: end;
        margin-top: 5px;
        color: green;
    }

`

