import React, {useState} from 'react';
import styled from 'styled-components';
import Loading from "../../../../Loader/Loading";
//import { ToastContainer} from "react-toastify";
import {FieldContainer, FieldLabel} from "../Deposit&WithdrawCard";


export const WithdrawalConfirmationPage = ({
                                               onClose,
                                               setSubmitted,
                                               amount,
                                               fromAccount,
                                               walletAddress,
                                               paymentOption,
                                               paymentMethod,
                                               handleWithdraw,
                                               loading,
                                               setIsModalOpens
                                           }) => {

    const handleSubmit = async (e) => {
        e?.preventDefault()
        try {
            await handleWithdraw()
            onClose();
            setSubmitted(false)
            // setIsModalOpens(true)
        } catch (error) {
            console.error('Error during transfer:', error);
        } finally {
        }
    };


    const cancelDeposit = () => {
        setSubmitted(false)
    }


    // Mock data for payment details
    const paymentDetails = {
        fromAccount: `${fromAccount}`,
        fundWalletAddress: `${walletAddress}`,
        amount: `${amount}`,
        orderId: 'ABC123',
        paymentOption: `${paymentOption}`,
        paymentMethod: `${paymentMethod}`,
        date: new Date().toLocaleString()
    };
    const title = "Are you sure you want to proceed ?"

    return (<Container>
        <Heading>{title}</Heading>
        <ConfirmationMessage>Please confirm before you proceed!</ConfirmationMessage>

        <DetailsContainer>

            <DetailItem>
                <strong>Amount:</strong>
                <span>${paymentDetails.amount}</span>
            </DetailItem>
            <DetailItem>
                <strong>From Account :</strong>
                <span>{paymentDetails.fromAccount}</span>
            </DetailItem>
            <DetailItem>
                <strong>Method:</strong>
                <span>{paymentDetails.paymentMethod}</span>
            </DetailItem>
            {/*<DetailItem>*/}
            {/*    <strong>Wallet Address:</strong>*/}
            {/*    <span style={{}}>{paymentDetails.fundWalletAddress}</span>*/}
            {/*</DetailItem>*/}
            <DetailItem>
                <strong>Option:</strong>
                <span>{paymentDetails.paymentOption}</span>
            </DetailItem>
            <DetailItem>
                <strong>Date:</strong>
                <span>{paymentDetails.date}</span>
            </DetailItem>

            <div style={{marginBottom:20,marginTop:40}}>
                <strong>Wallet:</strong><br/>
                <span style={{fontSize:14,color:'gray'}}>{paymentDetails.fundWalletAddress}</span>
            </div>

        </DetailsContainer>
        <div className="contact">
            <p>Need help? <span className="contactUs">Contact Us</span></p>
            <p className="cancel" onClick={cancelDeposit}>Cancel?</p>
        </div>
        <Button onClick={handleSubmit}>
            {loading ? <Loading/> : <span>Withdraw</span>}
        </Button>
    </Container>)
}


export const P2PConfirmationPage = ({
                                        onClose,
                                        setSubmitted,
                                        amount,
                                        FromAccount,
                                        ToAccount,
                                        paymentOption,
                                        paymentMethod,
                                        handleTransfer,
                                        recipientName,
                                        setIsModalOpens
                                    }) => {

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e?.preventDefault()
        setLoading(true);
        try {
            await handleTransfer();
            console.log("P2P Withdraw")
            onClose();
            setSubmitted(false)
            setIsModalOpens(true)
        } catch (error) {
            console.error('Error during transfer:', error);
        } finally {
            setLoading(false);
        }
    };


    const cancelPayment = () => {
        onClose();
        setSubmitted(false)
        setLoading(false)
    }
    // Mock data for payment details
    const paymentDetails = {
        paymentMethod: `${paymentMethod}`,
        FromAccount: `${FromAccount}`,
        ToAccount: `${ToAccount}`,
        recipientName: `${recipientName}`,
        amount: `${amount}`,
        orderId: 'ABC123',
        paymentOption: `${paymentOption}`,
        date: new Date().toLocaleString()
    };

    const title = "Are you sure you want to proceed ?"


    return (
        <Container>
            <Heading>{title}</Heading>
            <ConfirmationMessage>Please confirm details before sending, there is no going back after this action!</ConfirmationMessage>

            <DetailsContainer>
                <DetailItem>
                    <strong>status:</strong>
                    <span className="status">{paymentDetails.paymentMethod}</span>
                </DetailItem>
                <DetailItem>
                    <strong>Sender Account:</strong>
                    <span>{paymentDetails.FromAccount}</span>
                </DetailItem>
                <DetailItem>
                    <strong>Amount:</strong>
                    <span>${paymentDetails.amount}</span>
                </DetailItem>
                <DetailItem>
                    <strong>Recipient:</strong>
                    <span>{paymentDetails.recipientName}</span>
                </DetailItem>
                <DetailItem>
                    <strong>Recipient Account:</strong>
                    <span>{paymentDetails.ToAccount}</span>
                </DetailItem>

                <DetailItem>
                    <strong>payment Option:</strong>
                    <span>{paymentDetails.paymentOption}</span>
                </DetailItem>
                <DetailItem>
                    <strong>Date:</strong>
                    <span>{paymentDetails.date}</span>
                </DetailItem>
            </DetailsContainer>
            <div className="contact">
                <p>Need help? <span className="contactUs">Contact Us</span></p>
                <p className="cancel" onClick={cancelPayment}>Cancel?</p>
            </div>
            <Button onClick={handleSubmit}>
                {loading ? <Loading/> : <span>Transfer</span>}
            </Button>
        </Container>)
}


export const DepositConfirmationPage = ({
                                            formData,
                                            setFormData,
                                            onClose,
                                            setSubmitted,
                                            amount,
                                            accountToFund,
                                            fundWalletAddress,
                                            paymentOption,
                                            paymentMethod,
                                            handleDeposit,
                                            loading
                                        }) => {

    const [errors, setErrors] = useState({});

    const handleChange = async (e) => {
        e?.preventDefault()
        const {name, value} = e?.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };

    const handleSubmit = async (e) => {
        e?.preventDefault()
        try {
            if (!formData.transactionId) {
                //alert('payment transaction ID is required to proceed.');
               alert('payment transaction ID is required to proceed.');
            } else {
                setFormData(prevFormData => ({
                    ...prevFormData, transactionId: prevFormData.transactionId
                }));
                await handleDeposit();
                onClose();
                setSubmitted(false)
            }

        } catch (error) {
            alert('Oops, something went wrong, try again later');
        } finally {
        }
    };


    const cancelDeposit = () => {
        setSubmitted(false)
    }


    // Mock data for payment details
    const paymentDetails = {
        accountToFund: `${accountToFund}`,
        fundWalletAddress: `${fundWalletAddress}`,
        amount: `${amount}`,
        orderId: 'ABC123',
        paymentOption: `${paymentOption}`,
        paymentMethod: `${paymentMethod}`,
        date: new Date().toLocaleString()
    };

    const title = "Are you sure you want to proceed ?"

    return (<Container>

        <Heading>{title}</Heading>
        <ConfirmationMessage>Payment confirmation!</ConfirmationMessage>

        <DetailsContainer>
            {/*<DetailItem>*/}
            {/*    <strong>status:</strong>*/}
            {/*    <span className="status">pending</span>*/}
            {/*</DetailItem>*/}
            <DetailItem>
                <strong>Amount:</strong>
                <span>${paymentDetails.amount}</span>
            </DetailItem>
            <DetailItem>
                <strong>Fund To:</strong>
                <span>{paymentDetails.accountToFund}</span>
            </DetailItem>
            <DetailItem>
                <strong>Method:</strong>
                <span>{paymentDetails.paymentMethod}</span>
            </DetailItem>
            <DetailItem>
                <strong>Option:</strong>
                <span>{paymentDetails.paymentOption}</span>
            </DetailItem>
            <DetailItem>
                <strong>Date:</strong>
                <span>{paymentDetails.date}</span>
            </DetailItem>
        </DetailsContainer>
        <FieldContainer>
            <div style={{marginBottom:20,marginTop:20}}>
                <strong>Wallet:</strong><br/>
                <span style={{width:100}}>{paymentDetails.fundWalletAddress}</span>
            </div>

            <FieldLabel>Transaction ID</FieldLabel>
            <input
                type="text"
                value={formData?.transactionId || ""}
                name="transactionId"
                placeholder=""
                style={{fontSize: "12px"}}
                onChange={handleChange}
            />

        </FieldContainer>
        <div className="contact">
            <p>Need help? <span className="contactUs">Contact Us</span></p>
            <p className="cancel" onClick={cancelDeposit}>Cancel?</p>
        </div>
        <Button onClick={handleSubmit}>
            {loading ? <Loading/> : <span>Deposit</span>}
        </Button>
    </Container>)
}


export const InvestmentConfirmation = ({

                                           setSubmitted,
                                           loading,
                                           planName,
                                           planType,
                                           amount,
                                           spread,
                                           duration,
                                           handleInvest
                                       }) => {


    const handleSubmit = async (e) => {
        e?.preventDefault()
        try {
            await handleInvest();
            alert("Investment started successfully");
            setSubmitted(false)
        } catch (error) {
            alert('Oops something went wrong, please try again')
            //console.error('Error during transfer:', error);
        } finally {
        }
    };


    const cancelDeposit = () => {
        setSubmitted(false)
    }


    // Mock data for payment details
    const paymentDetails = {
        planName: `${planName}`,
        planType: `${planType}`,
        amount: `${amount}`,
        duration: `${duration}`,
        spread: `${spread}`,
        date: new Date().toLocaleString()
    };

    const title = "Are you sure you want to proceed?"
    return (<Container>

        <Heading>{title}</Heading>
        <ConfirmationMessage>Confirm Investment details!</ConfirmationMessage>
        <DetailsContainer>
            <DetailItem>
                <strong>Amount:</strong>
                <span style={{textAlign:'right'}}>${paymentDetails.amount}</span>
            </DetailItem>
            <DetailItem>
                <strong>Plan:</strong>
                <span style={{textAlign:'right'}}>{paymentDetails.planName}</span>
            </DetailItem>
            <DetailItem>
                <strong>Type:</strong>
                <span style={{textAlign:'right'}}>{paymentDetails.planType}</span>
            </DetailItem>
            <DetailItem>
                <strong>Duration:</strong>
                <span style={{textAlign:'right'}}>{paymentDetails.duration} Days</span>
            </DetailItem>
            <DetailItem>
                <strong>Profit:</strong>
                <span style={{textAlign:'right'}}>{paymentDetails.spread}%</span>
            </DetailItem>
            <DetailItem>
                <strong>Date:</strong>
                <span style={{textAlign:'right'}}>{paymentDetails.date}</span>
            </DetailItem>
        </DetailsContainer>
        <div className="contact">
            <p>Need help? <span className="contactUs">Contact Us</span></p>
            <p className="cancel" onClick={cancelDeposit}>Cancel?</p>
        </div>
        <Button type="button" onClick={handleSubmit}>
            {loading ? <Loading/> : <span>Start Investment </span>}
        </Button>
    </Container>)
}


// Styled components for styling
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    //align-items: center;
    width: 100%;
    border-radius: 5px;
    //border: 1px solid #34aadc;
    //margin: 6px;
    padding: 20px;
    z-index: 999;

    .contact {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
    }

    .contactUs {
        color: #FFCF01;
        font-weight: bold;
        cursor: pointer;
    }

    .cancel {
        color: #FFCF01;
        font-weight: bold;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        .contact {
            //flex-direction: row;
            //justify-content: space-between;
        }
    }
`

const Heading = styled.h3`
    font-size: 18px;
`

const ConfirmationMessage = styled.p`
    font-size: 15px;
    margin-bottom: 20px;

`

const DetailsContainer = styled.div`
    //border-bottom: 1px solid #B1B5C36B;
    //padding: 10px;
    //border-radius: 5px;
    margin-bottom: 20px;
`

const DetailItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
    gap: 15rem;
  //background-color: #00C076;

    .status {
        background: #8FF5A0BD;
        padding: 4px 12px;
        border-radius: 10px;
        color: #00C076;
        border: 1px solid #4cd964;
    }

    span {
        font-size: 13px;
        color: #1C2127;
    }

    strong {
        font-size: 13px;
        color: #9e9ea4;
        font-weight: 600;
    }

    @media screen and (max-width: 768px) {
        gap: 5rem;
    }
`

const Button = styled.button`
    padding: 12px 20px;
    font-size: 15px;
    background-color: #f1c40f;
    color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`

