
export const formatCurrency = (amount) => {
    const numberValue = Number(amount || "");
    return numberValue?.toLocaleString('en-US');
};


export const formatCurrency2 = (amount) => {
    const numberValue = Number(amount || "");
    return numberValue?.toLocaleString('en-US', {
        style: 'currency', currency: 'USD',
        // minimumFractionDigits: 2,
        // maximumFractionDigits: 2
    });
};


export const formatCurrencies = (amount, profit) => {
    const formattedAmount = amount.toLocaleString('en-US', {
        style: 'currency', currency: 'USD'
    });

    const formattedProfit = profit.toLocaleString('en-US', {
        style: 'currency', currency: 'USD'
    });

    return [formattedAmount, formattedProfit];
};


export function truncateText(text, maxLength = 20) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
}

const isScriptAppended = (src) => {
    const scriptElements = document.getElementsByTagName('script');
    for (let i = 0; i < scriptElements.length; i++) {
        if (scriptElements[i].src === src) {
            return true;
        }
    }
    return false;
};

export const loadLan = (setGoogleTranslateInitialized, googleTranslateInitialized) => {
    if (!isScriptAppended('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;
        script.onload = () => {
            setGoogleTranslateInitialized(true);
            //setScriptLoaded(true);
        };
        script.onerror = (error) => console.error('Failed to load Google Translate script:', error.message, error.stack);
        document.getElementsByTagName('head')[0].appendChild(script);

        return () => {
            // script.remove();
        };
    } else if (!googleTranslateInitialized) {
        setGoogleTranslateInitialized(true);
    }
};

export const showLanguageSelect = (googleTranslateInitialized)=>{
    // loadLan()
    if (googleTranslateInitialized) {
        if (window.google.translate.TranslateElement) {
            new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
        } else {
            console.error('Google Translate Element is not available');
        }
    } else {
        console.error('Google Translate is not initialized yet');
    }
};
