import React from "react";
import {Route, Routes} from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import Index from "./pages/profile";
import Setting from "./pages/profile/setting";
import Investment from "./pages/profile/investment";
import Auth from './pages/auth';
import Dashboard from './pages/dashboard';
import Deposit from "./pages/profile/deposit";
import Transactions from "./pages/profile/transactions";
import Portfolio from "./pages/profile/portfolio";
import WithdrawFunds from "./pages/profile/wtihdrawal";
import {useAuth} from "./contexts/AppContext";
import ResetPasswords from "./pages/resetpassword";
import ResetPasswordOTPs from "./pages/resetPasswordOtp";
import UpdatePassword from "./pages/updatePassword";
import SecurityLayout from "./pages/security";
import Congratulation from "./pages/congratulation";
import 'react-toastify/dist/ReactToastify.css';
import Success from "./pages/profile/success";
//import useInitializeAuthState from "./helper/useInitializeAuthState";
//import {ToastContainer} from "react-toastify";
import useInitializeAuthState from "./helper/useInitializeAuthState";


//create .htaccess in your root folder and add below code
// <IfModule mod_rewrite.c>
//     RewriteEngine On
//     RewriteBase /
//     RewriteRule ^index\.html$ - [L]
//     RewriteCond %{REQUEST_FILENAME} !-f
//     RewriteCond %{REQUEST_FILENAME} !-d
//     RewriteRule . /index.html [L]
// </IfModule>



function App() {
    const { isLoggedIn } = useAuth();
    useInitializeAuthState();
    return (<div>
   {/* <AuthProvider> */}
        <Routes>
            {
                isLoggedIn && (<React.Fragment>
                        <Route path='/congratulation' element={<Congratulation/>}/>
                            {/*<Route path='/profile' element={<Index/>}/>*/}
                            <Route path='/profile/*' element={<Index/>}/>
                            <Route path='/dashboard' element={<Dashboard/>}/>
                            <Route path='/profile/deposit' element={<Deposit/>}/>
                            <Route path='/profile/withdrawal' element={<WithdrawFunds/>}/>
                            <Route path='/profile/transactions' element={<Transactions/>}/>
                            <Route path='/profile/setting' element={<Setting/>}/>
                            <Route path='/profile/investment' element={<Investment/>}/>
                            <Route path='/profile/portfolio' element={<Portfolio/>}/>
                            <Route path='/profile/success' element={<Success/>}/>
                    </React.Fragment>)
            }

            <Route path={'/*'} element={<Auth/>}/>
            <Route path='/resetpassword' element={<ResetPasswords/>}/>
            <Route path='/resetpasswordOtp' element={<ResetPasswordOTPs/>}/>
            <Route path='/updatePassword' element={<UpdatePassword/>}/>
            <Route path='/security' element={<SecurityLayout/>}/>



        </Routes>
     {/* </AuthProvider> */}
     {/*   <ToastContainer/>*/}
    </div>)

}

export default App;
