import React, {useState} from 'react';
import styled from 'styled-components';
import {MdOutlineKeyboardArrowDown} from "react-icons/md";
import {HiOutlineMail, HiOutlinePhone} from "react-icons/hi";
import {AiOutlineBook} from "react-icons/ai";
import Modal from "./Modal";
import AccountID from "../../../assets/accountID.webp";
import FileUploader from "../../Authentication/UploadDocument";
import 'react-toastify/dist/ReactToastify.css';


const AccordionContainer = styled.div`
    width: 100%;
    margin: 20px auto;
    border-radius: 2px;
    border: none;

    @media screen and (max-width: 576px) {
        p, span {
            color: #1C2127;
        }
    }
`;

const Steps = styled.div`
    background-color: #ffffff;
    border-top: 0;
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    border-left: 1px solid #E0E0E0;
    border-radius: 5px;
    margin-bottom: ${({isOpen}) => (!isOpen ? '7px' : '20px')};
`;

const StepHeader = styled.div`
    background-color: #ffffff;
    padding: 15px;
    cursor: pointer;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({isOpen}) => (isOpen ? '2rem' : '0')};

    .step-header {

        span {
            margin: 0.4rem;
            font-size: 12px;
        }

        .step-number {
            width: 50%;
            height: 50%;
            padding: 8px 12px;
            border-radius: 50%;
            font-size: 13px;
            color: #000000;
            font-weight: bold;
            background: ${(props) => (props.isOpen ? '#5b5f62' : '#E0E0E0')};
        }
    }

    .step-icon {
        font-size: 25px;
        color: #9e9ea4;
    }

    .step-icon.open {
        transform: rotate(180deg);
    }

`;

const StepContent = styled.div`
    //padding: 10px;
    display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
`;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2rem;

    .hr {
        height: 1px;
        color: rgba(255, 255, 255, 0.31);
    }

    .Details {
        margin-top: 1rem;
    }

    .Details_Numbers {
        font-weight: bold;
        color: #00C076;
        font-size: 18px;
    }

    @media screen and (max-width: 576px) {
        gap: 1px;
        padding: 1.2rem;
    }

`;

const Label = styled.div`
    font-size: 13px;
    margin-bottom: 5px;
    color: #373b3f;
`;

const Details = styled.div`
    display: flex;
    @media screen and (max-width: 576px) {
        display: block;
        flex-direction: column;
    }
`;

const Value = styled.div`

    display: flex;
    margin-right: 30px;

    p {
        padding-top: 5px;
        font-size: 13px;
    }

    .text-icon {
        padding-top: 15px;
        font-size: 33px;
        color: #1C2127;
    }

    @media screen and (max-width: 576px) {
        p {
            padding-top: 1px;
            font-size: 12px;
        }

        .text-icon {
            padding-top: 11px;
            font-size: 28px;
            color: #1C2127;
        }
    }
`;

const Button = styled.button`
    padding: 13px 20px;
    background-color: #FFCF01;
    color: #141D22;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 20%;
    font-size: 15px;
    @media screen and (max-width: 576px) {
        width: 50%;
    }
`;

const Accordion = ({user}) => {
    const [openStep, setOpenStep] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const toggleStep = (step) => {
        setOpenStep(step === openStep ? null : step);
    };


    return (<AccordionContainer>
        <Steps>
            <StepHeader onClick={() => toggleStep(1)}>
                <div className="step-header">
                    <span className="step-number">1</span>
                    <span>User Details</span>
                </div>
                <MdOutlineKeyboardArrowDown
                    className={`step-icon ${openStep === 1 ? 'open' : ''}`}/>
            </StepHeader>
            <StepContent isOpen={openStep === 1}>
                <Container>
                    <Label>Your confirmed details</Label>
                    <Details>
                        <Value>
                            <HiOutlineMail className="text-icon"/>
                            <p>{user?.email}</p>
                        </Value>
                        <Value>
                            <HiOutlinePhone className="text-icon"/>
                            {!user?.phone ? (<p>Unknown ...</p>) : (<p>{user?.phone}</p>)}
                        </Value>
                        <Value>
                            <AiOutlineBook className="text-icon"/>
                            {!user?.firstName ? (<p>Unknown ...</p>) : (
                                <p style={{textTransform: "capitalize"}}>{user?.firstName} {user?.lastName}</p>)}
                        </Value>
                    </Details>
                    <hr className='hr'/>

                    <div className="Details">
                        <Label> Withdrawal Minimum limit</Label>
                        <p className="Details_Numbers">{user?.depositLimit} USD</p>
                        {/*<Label>This limit will apply for 14 days after the first deposit</Label>*/}
                    </div>
                </Container>
            </StepContent>
        </Steps>


        <Steps>
            <StepHeader onClick={() => toggleStep(2)}>
                <div className="step-header">
                    <span className="step-number">2</span>
                    <span>Verify your identity</span>
                </div>
                <MdOutlineKeyboardArrowDown
                    className={`step-icon ${openStep === 2 ? 'open' : ''}`}/>
            </StepHeader>
            <StepContent isOpen={openStep === 2}>
                <Container>
                    <Label>Provide a document confirming your name</Label>
                    <p>Upload Any Necessary Documents Or Photos To Verify Your Identity.
                        <br/><br/>This Step May Vary Depending On Your Account Type And The Level Of Verification Required.</p>
                    <Button onClick={openModal}>Get Started</Button>
                </Container>


                <Modal isOpen={isModalOpen} onClose={closeModal} variant={'small'}>
                    <Container>
                        <div className="Document" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                <img src={AccountID} alt="AccountID" style={{width: "20%"}}/>

                        </div>
                        <h2>Document Verification</h2>
                        <Label>Kindly ensure that the selected document meets verification requirements</Label>
                        <hr className='hr'/>

                        <div className="Details">
                            <Label className="Details_Numbers">Select Document below</Label>
                        </div>

                        <FileUploader setIsModalOpen={setIsModalOpen} user={user}/>
                    </Container>
                </Modal>
            </StepContent>

        </Steps>

        {/*<Steps>*/}
        {/*    <StepHeader onClick={() => toggleStep(3)}>*/}
        {/*        <div className="step-header">*/}
        {/*            <span className="step-number">3</span>*/}
        {/*            <span>Verify residential address</span>*/}
        {/*        </div>*/}
        {/*        <MdOutlineKeyboardArrowDown*/}
        {/*            className={`step-icon ${openStep === 3 ? 'open' : ''}`}/>*/}
        {/*    </StepHeader>*/}
        {/*    <StepContent isOpen={openStep === 3}>*/}
        {/*        <Container>*/}
        {/*            <Label>You will need to provide proof of your place of residence</Label>*/}
        {/*            <Details>*/}
        {/*                <Value>*/}
        {/*                    <HiOutlineMail className="text-icon"/>*/}
        {/*                    <p>{user?.country}</p>*/}
        {/*                </Value>*/}

        {/*            </Details>*/}
        {/*            <hr className='hr'/>*/}

        {/*            <div className="Details">*/}
        {/*                <Label>Deposit limit</Label>*/}
        {/*                <p className="Details_Numbers">Unlimited</p>*/}
        {/*                <Label>Depending on selected payment method</Label>*/}
        {/*            </div>*/}
        {/*        </Container>*/}
        {/*        /!*<StepHeader>*!/*/}
        {/*        /!*<Button>Complete now</Button>*!/*/}
        {/*        /!*</StepHeader>*!/*/}
        {/*    </StepContent>*/}
        {/*</Steps>*/}
    </AccordionContainer>);
};

export default Accordion;


