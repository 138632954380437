import React from 'react';
import styled from 'styled-components';
import pcs from "../../../../assets/pcs.png"
import pc from "../../../../assets/pc.png"
import getCompanyInfo from "../../../../Utilities/companyInfo";


// Product Specifications
export const PortFolio = () => {

    const company = getCompanyInfo()
    return (

        <Container>
            <h2 style={{color:'#000'}}>{company.name} Allocation Process</h2>
            <hr className='hr'/>
            <InnerContainer>
                <StyledDiv>
                    <StyledParagraph>At {company.name}, we believe in optimizing your investments through a strategic allocation process. Here's how it works:</StyledParagraph>
                    <StyledList>
                        <StyledListItem>
                            <strong>Risk Assessment:</strong> We assess your risk tolerance and investment goals to determine the optimal asset allocation for your portfolio.
                        </StyledListItem>
                        <StyledListItem>
                            <strong>Asset Classes:</strong> We divide your investments into different asset classes, such as:
                            <ul style={{ marginLeft: '2rem' }}>
                                <li>Stocks (equities)</li>
                                <li>Bonds (fixed income)</li>
                                <li>Real Estate</li>
                                <li>Commodities</li>
                                <li>Currencies</li>
                            </ul>
                        </StyledListItem>
                        {/* Other list items */}
                        <StyledListItem>
                            <strong>Target Allocation:</strong> We set a target allocation for each asset class based on your risk profile and investment objectives.
                        </StyledListItem>

                        <StyledListItem>
                            <strong>Portfolio Construction:</strong> We construct your portfolio by allocating your investments across the selected asset classes.
                        </StyledListItem>

                        <StyledListItem>
                            <strong>Regular Rebalancing:</strong> We regularly review and adjust your portfolio to ensure it remains aligned with your target allocation.
                        </StyledListItem>
                    </StyledList>
                    {/* Other sections */}

                    <br/>
                    <div style={{color:'#000',fontWeight:'bold'}}>{company.name} Allocation Strategies</div>
                    <div style={{color:'#000',fontWeight:'normal'}}>{company.name} We offer three allocation strategies to suit your needs:</div>
                    <br/>

                    <StyledList>
                        <StyledListItem>
                            <strong>Conservative:</strong> Focuses on capital preservation and income generation.
                        </StyledListItem>
                        {/* Other list items */}
                        <StyledListItem>
                            <strong>Balanced:</strong> Seeks to balance growth and income.
                        </StyledListItem>

                        <StyledListItem>
                            <strong>Growth:</strong> Aims to maximize returns through equity investments.
                        </StyledListItem>

                    </StyledList>



                    <br/>
                    <div style={{color:'#000',fontWeight:'bold'}}>Benefits of {company.name} Allocation
                    </div>
                    <br/>

                    <StyledList>
                        <StyledListItem>
                            <strong>Diversification:</strong> Spreads risk across asset classes to minimize losses.

                        </StyledListItem>
                        {/* Other list items */}
                        <StyledListItem>
                            <strong>Optimized Returns:</strong> Maximizes potential returns based on your risk tolerance.
                        </StyledListItem>

                        <StyledListItem>
                            <strong>Regular Rebalancing:</strong> Ensures your portfolio remains aligned with your investment objectives.
                        </StyledListItem>

                    </StyledList>

                        <br/>

                    <p style={{color:'#000',fontSize:14}}>By following this allocation process, {company.name} helps you create a diversified and optimized investment portfolio tailored to your needs.</p>

                </StyledDiv>

            </InnerContainer>


            <h2 style={{color:'#000'}}>Referral Program: Earn Rewards</h2>
            <hr className='hr'/>
            <InnerContainer>

                <AccountContainer>

                    <StepContainer>
                        <div style={{color:'#000',fontWeight:'bold'}}>Step 1: Create an Account</div>
                        <StepTitle>1. Sign up for an account on our investment platform.</StepTitle>
                        <StepTitle>2. Verify your account through email or phone number, if required.</StepTitle>
                    </StepContainer>


                    <StepContainer>
                        <div style={{color:'#000',fontWeight:'bold'}}>Step 2: Get Your Referral Code</div>
                        <StepTitle>1. Log in to your account.</StepTitle>
                        <StepTitle>2. Navigate to the referral section or dashboard.</StepTitle>
                        <StepTitle>3. Find your unique referral code.</StepTitle>
                    </StepContainer>



                    <StepContainer>
                        <div style={{color:'#000',fontWeight:'bold'}}>Step 3: Share Your Referral Code</div>
                        <StepTitle>1. Copy your referral code.</StepTitle>
                        <StepTitle>2. Share it with friends, family, or social networks.</StepTitle>
                        <StepTitle>3. Encourage them to sign up using your code.</StepTitle>
                    </StepContainer>



                    <StepContainer>
                        <div style={{color:'#000',fontWeight:'bold'}}>Step 4: Earn Rewards</div>
                        <StepTitle>1. For each friend who signs up using your code, you'll get 10% of their invested capital on each investment they make.</StepTitle>
                        <StepTitle>2. Your friends will also earn rewards for their investments.</StepTitle>
                        <StepTitle>3. Track your referrals' progress and earnings on your dashboard.</StepTitle>
                    </StepContainer>


                    <BenefitsContainer>
                        <BenefitTitle>Benefits for You (the Upliner):</BenefitTitle>
                        <BenefitList>
                            <BenefitListItem>10% of the referral's invested capital on each investment made by the referral</BenefitListItem>
                            <BenefitListItem>Opportunities to earn more by referring multiple friends</BenefitListItem>
                            <BenefitListItem>Access to a user-friendly investment platform</BenefitListItem>
                        </BenefitList>
                    </BenefitsContainer>

                    <BenefitsContainer>
                        <BenefitTitle>Benefits for Your Friends (the Referrals):</BenefitTitle>
                        <BenefitList>
                            <BenefitListItem>A user-friendly investment platform</BenefitListItem>
                            <BenefitListItem>Opportunities to earn rewards on their investments</BenefitListItem>
                            <BenefitListItem>Support and guidance from you, their trusted referrer</BenefitListItem>
                        </BenefitList>
                    </BenefitsContainer>
                    <p>By following these steps and sharing your referral code, you can earn rewards and help your friends invest wisely. Start referring today!</p>
                </AccountContainer>

            </InnerContainer>


            <h2 style={{color:'#000'}}>Maximum investments permitted</h2>
            <hr className='hr'/>
            <InnerContainer>
                <FlexibilityContainer>
                    <SectionTitle>Investment Flexibility</SectionTitle>
                    <p>At {company.name}, we offer flexible investment options to suit your financial goals. You can send any amount to your account, and invest any amount into any plan, depending on the investment plan's minimum and maximum limits.</p>
                    <NoLimitSection>
                        <SectionTitle>No Maximum Deposit Limit</SectionTitle>
                        <p>There is no maximum limit on the amount you can deposit into your {company.name} account. You can send any amount to your account, and we will ensure that it is reflected in your account balance.</p>
                    </NoLimitSection>
                    <SectionTitle>Investment Plan Limits</SectionTitle>
                    <p>While there is no maximum deposit limit, each investment plan has its own minimum and maximum investment limits. These limits vary depending on the plan's risk level, tenure, and returns. Please refer to the specific investment plan details for more information on the minimum and maximum investment amounts.</p>
                    <ExampleContainer>
                        <ExampleTitle>Example</ExampleTitle>
                        <p>• Minimum investment in Plan A is $100, maximum is $900</p>
                        <p>• Minimum investment in Plan B is $1,000, maximum is $5,000</p>
                        <p>You can invest $100 in Plan A and $1,000 in Plan B, or invest $1,100 in Plan B alone</p>
                    </ExampleContainer>
                    <p>Get Started: Open an account with {company.name} today and start investing in your financial future. Our user-friendly platform and dedicated customer support team are here to help you every step of the way.</p>
                </FlexibilityContainer>
            </InnerContainer>


            <h2 style={{color:'#000'}}>Investment Management Rules:</h2>
            <hr className='hr'/>
            <InnerContainer>
               <RuleContainer>

                   <RuleList>
                       <RuleListItem>Diversification: Spread investments across asset classes, sectors, and geographies to minimize risk.</RuleListItem>
                       <RuleListItem>Risk Management: Set risk tolerance and adjust investments accordingly.</RuleListItem>
                       <RuleListItem>Long-term Focus: Invest for the long haul, rather than short-term gains.</RuleListItem>
                       <RuleListItem>Regular Portfolio Rebalancing: Periodically review and adjust the portfolio to maintain target allocations.</RuleListItem>
                       <RuleListItem>Dollar-Cost Averaging: Invest fixed amounts regularly, regardless of market conditions.</RuleListItem>
                       <RuleListItem>Tax Efficiency: Consider tax implications when investing and aim to minimize tax liabilities.</RuleListItem>
                       <RuleListItem>Low-Cost Investing: Opt for low-fee investments, such as index funds or ETFs.</RuleListItem>
                       <RuleListItem>Professional Advice: Consult with a financial advisor or investment manager if needed.</RuleListItem>
                       <RuleListItem>Regular Monitoring: Stay informed about market trends and economic conditions.</RuleListItem>
                       <RuleListItem>Disciplined Approach: Stick to the investment plan and avoid impulsive decisions based on market volatility.</RuleListItem>
                       <RuleListItem>Clear Investment Objectives: Define and prioritize investment goals, such as retirement or wealth accumulation.</RuleListItem>
                       <RuleListItem>Transparency and Accountability: Ensure clear reporting and accountability for investment decisions and performance.</RuleListItem>
                   </RuleList>
                   <p>These rules aim to promote a disciplined, informed, and strategic approach to investment management.</p>
               </RuleContainer>
            </InnerContainer>

        </Container>);
};




const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    //height: 100vh;

    .hr {
        height: 1px;
        width: 100%;
        color: rgba(255, 255, 255, 0.31);
    }

    @media (max-width: 768px) {
        h2 {
            color: #ffffff;
        }

        .header-text {
            display: none;
        }
    }
`;

const InnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    margin-top: 1.5rem;
`;

const StyledDiv = styled.div`
    font-family: Arial, sans-serif;
    line-height: 1.6;
  color: #1c2127;
`;

const StyledParagraph = styled.p`
    font-size: 14px;
  color: #1c2127;
`;

const StyledList = styled.ol`
    margin-left: 2rem;
`;

const StyledListItem = styled.li`
    margin-bottom: 0.5rem;
  color: #1c2127;
`;


const AccountContainer = styled.div`
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333; /* Main text color */
`;


const StepContainer = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 20px;
`;

const StepTitle = styled.h3`
  font-size: 13px;
  margin-top: 10px;
  color: #021310; /* Step title color */
  font-weight: normal;
`;

const BenefitsContainer = styled.div`
  margin-top: 1.5rem;
`;

const BenefitTitle = styled.h3`
  font-size: 1rem;
  margin-top: 1rem;
  color: #021310; /* Benefit title color */
`;

const BenefitList = styled.ul`
  margin-left: 2rem;
`;

const BenefitListItem = styled.li`
  margin-bottom: 0.5rem;
`;


///////////
const RuleContainer = styled.div`
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333; /* Main text color */
`;


const RuleList = styled.ol`
    margin-left: 2rem;
`;

const RuleListItem = styled.li`
    margin-bottom: 0.5rem;
`;


/////////
const FlexibilityContainer = styled.div`
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333; /* Main text color */
`;

const FlexibilityTitle = styled.h2`
    font-size: 1.5rem;
    margin-top: 1.5rem;
    color: #000; /* Black color for title */
    font-weight: bold; /* Bold font weight */
`;

const NoLimitSection = styled.div`
    margin-top: 1.5rem;
`;

const SectionTitle = styled.h3`
    font-size: 1.2rem;
    margin-top: 1rem;
    color: #000; /* Black color for section title */
    font-weight: bold; /* Bold font weight */
`;

const ExampleContainer = styled.div`
    margin-top: 1.5rem;
`;

const ExampleTitle = styled.h3`
    font-size: 1.2rem;
    margin-top: 1rem;
    color: #000; /* Black color for example title */
    font-weight: bold; /* Bold font weight */
`;

// Product Overview

export const PortFolioCopy = () => {
    return (<CardContainer>
        <ResponsiveCard>
            <Image src={pc} alt="Placeholder"/>
            <Text>
                For big number of small investors
            </Text>
            <BulletList>
                <BulletListItem>
                    Deposit your own money into a strategy account and trade using that capital
                </BulletListItem>
                <BulletListItem>
                    Orders are copied to each investor's invested capital using a copy
                    coefficient
                </BulletListItem>
                <BulletListItem>
                    The total equity is limited to 200,000 USD
                </BulletListItem>
            </BulletList>
            <Button>Select Portfolio Copying (Coming Soon!)</Button>
        </ResponsiveCard>


        <ResponsiveCard>
            <Image src={pcs} alt="Placeholder"/>
            <Text>
                For pooled funds of several large investors
            </Text>
            <Text>
                Be responsible for investment performance
            </Text>
            <BulletList>
                <BulletListItem>
                    Trade on a fund account using the pooled capital of all your investors
                </BulletListItem>
                <BulletListItem>
                    Orders are allocated to each investment according to its equity share
                </BulletListItem>
                <BulletListItem>
                    The total equity is unlimited
                </BulletListItem>
            </BulletList>
            <Button>Select Portfolio Management (Coming Soon!)</Button>
        </ResponsiveCard>
    </CardContainer>);
};


const CardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const ResponsiveCard = styled.div`
    width: calc(50% - 20px); /* 50% width with margin */
    margin-bottom: 20px;

    @media (max-width: 768px) {
        width: 100%; /* Full width on small screens */
    }
`;

const Image = styled.img`
    width: 100%;
    height: 40%;
    border-radius: 8px;
`;

const Text = styled.p`
    font-size: 13px;
`;

const BulletList = styled.ul`
    padding-left: 20px;
`;

const BulletListItem = styled.li`
    margin-bottom: 8px;
    font-size: 13px;
  color: #1c2127;
`;

const Button = styled.button`
    background-color: #F3F5F7;
    color: #373b3f;
    border: none;
    border-radius: 4px;
    padding: 15px 20px;
    font-size: 13px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    margin-top: 2rem;

    &:hover {
        border: 1px solid #D6DCEB;
    }
`;
