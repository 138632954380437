import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from "../../contexts/AppContext";
import * as Yup from "yup";
import Loading from "../../Loader/Loading";
//import {toast} from "react-toastify";
import {ResetOTPSchema} from "../../validation/validation";
//import {IoArrowBackCircleOutline} from "react-icons/io5";
import styled from "styled-components";

const ResetPasswordOTP = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const {resetPasswordOTP} = useAuth();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");

   const [message, setMessage] = useState(0)

    useEffect(() => {
       setTimeout(()=>{
           setMessage(1)
       }, 3000)
    }, [email]);


    const [formData, setFormData] = useState({
        passwordResetOtp: '', email: email
    });

    const handleVerify = async () => {
        // e.preventDefault();
        if(!formData.passwordResetOtp){

            alert('Please enter your OTP')
        }else {

            setLoading(true);
            try {
                await ResetOTPSchema.validate(formData, {abortEarly: false});
                await resetPasswordOTP(formData.email, formData.passwordResetOtp)
                    .then((data) => {
                        if (data.status) {
                            alert(data.message);
                            return navigate(`/updatePassword?email=${formData.email}`);
                        } else {
                            //console.log(data.error, "error message")
                            alert(data.error);
                            //return navigate(`/resetPasswordOtp`);
                        }
                    })
                    .catch((error) => {
                        //console.log("Oops Something Went Wrong Please Try Again")
                        alert(error.message);
                    })
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    const validationErrors = {};
                    error.inner.forEach(error => {
                        validationErrors[error.path] = error.message;
                    });
                }
            } finally {
                setLoading(false);
            }

        }

    };


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };

    const Navigate = () => {
        navigate("/");
    }


    return (<div>
        <div className="auth-container__form">
            {
                message ===0 ?
                    <SuccessDiv>
                        OTP has been sent to your email successfully!
                    </SuccessDiv>
                    :
                    <InfoDiv>
                        Please enter the otp to continue password reset
                    </InfoDiv>
            }
            <div>

                <label>Enter 4 digit OTP</label>
                <input type="text"
                       placeholder="4 digit OTP"
                       name="passwordResetOtp"
                       maxLength={4}
                       value={formData.passwordResetOtp}
                       onChange={handleChange}
                       required/>
                {errors.passwordResetOtp && !formData.passwordResetOtp &&
                    <div className="error">{errors.passwordResetOtp}</div>}
            </div>

            <button type="submit" onClick={handleVerify}> {loading ? <Loading/> :
                <span>Continue</span>}</button>

            <div>
                <p onClick={Navigate} style={{textAlign:"center",color:'#000',fontSize:13}}>Cancel Action</p>
            </div>
        </div>
    </div>)
}

export default ResetPasswordOTP


export const SuccessDiv = styled.div`
    background: linear-gradient(45deg, #28a745, #20c997);
    padding: 10px;
    border-radius: 6px;
    color: #fff;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
`;

export const InfoDiv = styled.div`
  background: linear-gradient(45deg, #fd7f02, #ecbd7e);
  padding: 10px;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`;
