import styled from "styled-components";
import { useState} from "react";

const Tabs = ({tabs}) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };



    return (<div>
        <TabContainer>
            {tabs.map((tab, index) => (<div className="flex" key={index} onClick={() => handleTabClick(index)}>

                <TabText active={(index === activeTab).toString()}>{tab.title}</TabText>
                <TabLine active={(index === activeTab).toString()}/>
                {/*{index === activeTab && <TabLine active={(index === activeTab).toString()}/>}*/}

                {/*<TabLine active={(index === activeTab).toString()}/>*/}
                {/*<TabLine active={(index === activeTab).toString()}/> /!* Pass active prop here *!/*/}


            </div>))}
        </TabContainer>

        <TabContent>
            {tabs[activeTab].content}
        </TabContent>
    </div>);
};


export default Tabs


const TabContainer = styled.div`
    display: flex;
    //align-items: center;
    border-bottom: 1px solid #BDBFC1;
    overflow-x: auto;
    overflow-y: hidden;
    transition: all 0.5s;
    text-align: start;
    padding-top: 8px;
  width: 100%;


    .flex {
        width: 100%;
        text-align: center;
        overflow: hidden;
        color: #141D22;
    }

    @media screen and (max-width: 576px) {
        color: #141D22;
        text-align: center;
        font-weight: bold;
        overflow: hidden;

        .flex {
            width: 100%;
            text-align: center;
            overflow: hidden;
            color: #141D22;
        }
    }
`;


const TabText = styled.span`
    margin-right: 5rem;
    //width: 200%;
    cursor: pointer;
    font-size: 15px;
    //text-align: center;
    color: ${({active}) => (active ? '#141D22' : 'inherit')};
    //font-size: 15px;
    font-weight: ${({active}) => (active ? 'bold' : '400')};

    @media screen and (max-width: 576px) {
        color: #141D22;
        text-align: start;
        font-weight: bold;
        font-size: 12px;
    }
`;

const TabLine = styled.div`
    height: 3px;
    margin-top: 12px;
    text-align: center;
    width: ${({active}) => (active === 'true' ? '90%' : '0')};
    background-color: ${({active}) => (active === 'true' ? '#141D22' : 'transparent')};
    transition: width 0.3s ease;

    @media screen and (max-width: 576px) {
        width: ${({active}) => (active === 'true' ? '100%' : '0')};
        background-color: ${({active}) => (active === 'true' ? '#141D22' : 'transparent')};
        overflow: hidden;
    }
`;


const TabContent = styled.div`
    margin-top: 6px;
`;


export const MainContainer = styled.div`
    color: #141D22;

    h2 {
        margin-top: 2rem;
        margin-bottom: 1px;
        color: #141D22;
        //font-size: 20px;
    }


    p {
        margin-top: 15px;
        font-size: 13px;
    }

    @media screen and (max-width: 576px) {
        color: #FFFFFF;
        .header-text {
            color: #FFFFFF;
        }
    }
`
