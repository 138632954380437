import React, {useState} from 'react';
import styled from "styled-components";
import {AiOutlineDollar} from "react-icons/ai";
import {FaRegCircleUser, FaToggleOff, FaToggleOn} from "react-icons/fa6";
import {useAuth} from "../../../contexts/AppContext";
import {PasswordValidationSchema} from "../../../validation/validation";
import * as Yup from "yup";
import Loading from "../../../Loader/Loading";
import Modal from "./Modal";
import ProfileUpdateAccordion from "./SettingProfile/ProfileUpdateAccordion";
import 'react-toastify/dist/ReactToastify.css';
////import { toast } from 'react-toastify';
import {useNavigate} from "react-router-dom";


export const AccountCard = () => {
    const {user} = useAuth();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    const data1 = "Verification status"
    const data2 = "Not Verified"
    const data22 = "Account Verified"
    // const data3 = `${user?.email}`
    const data4 = "Complete Profile"



    return (<CardContainer>
        <Icon>
            <FaRegCircleUser className="icon-text"/>
        </Icon>
        <TextContainer>
            <Description>{data1}</Description>
            {user?.accountStatus === true ? (<Title className="green">
                {data22}
            </Title>) : (<Title className="color">
                {data2}
            </Title>)}
            <Description>{user?.email || ""}</Description>
        </TextContainer>
        <Button onClick={openModal}>{data4}</Button>


        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div style={{padding: "1rem"}}>
                <h2 style={{margin: "1rem"}}>Account Verification</h2>
                <p style={{fontSize: "13px"}}>Your security is our top priority, and completing</p>
                <p style={{fontSize: "13px"}}> this process ensures the safety and integrity of your
                    information.</p>
                <ProfileUpdateAccordion/>
            </div>

        </Modal>

    </CardContainer>);
};


export const AccountCard1 = () => {
    const {user} = useAuth();

    const data1 = "Minimum Deposit "
    // const data2 = `${user?.depositLimit} USD`
    const data3 = "Verify your account to enjoy more features"


    return (<CardContainer>
        <Icon>
            <AiOutlineDollar className="icon-text"/>
        </Icon>
        <TextContainer>
            <Description>{data1}</Description>
            <Title>{user?.depositLimit || ""} USD</Title>
            <Description>{data3}</Description>
        </TextContainer>
    </CardContainer>);
};


export const AuthorizationCard = () => {
    const {user} = useAuth();

    const [showChangePassword, setShowChangePassword] = useState(false);

    const handleChangePasswordClick = () => {
        setShowChangePassword(true);
    };

    const handleSubmit = () => {
        setShowChangePassword(false);
    };


    return (

        <div>
            {showChangePassword ? (<ChangePasswordForm user={user} onSubmit={handleSubmit} onCancel={() => setShowChangePassword(false)}/>) : (
                <AuthContainer>
                    <FieldContainer>
                        <FieldLabel>Email</FieldLabel>
                        <FieldValue>{user?.email || ""}</FieldValue>
                    </FieldContainer>
                    <FieldContainer>
                        <FieldLabel>Password</FieldLabel>
                        <FieldValue><span>************</span>
                            <AuthButton onClick={handleChangePasswordClick}>Change</AuthButton>
                        </FieldValue>
                    </FieldContainer>
                </AuthContainer>)}

        </div>


    )

}


export const ChangePasswordForm = ({onSubmit, onCancel}) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const {user, updateUserPassword} = useAuth();


    const [formData, setFormData] = useState({
        currentPassword: '', newPassword: '', confirmPassword: ''
    });


    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await PasswordValidationSchema.validate(formData, {abortEarly: false});
            await updateUserPassword(formData.currentPassword, formData.newPassword, formData.confirmPassword)
                .then((data) => {
                    if(data.message === 'Access Denied'){
                        navigate('/')
                    }else {
                        //console.log(data, "user data ")
                        alert(data?.message || "Password changed successfully");
                        onSubmit();
                        return data
                    }
                })
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                    //console.log(error, "error")
                    alert(error.message)
                });
            } else {
                alert(error.message);
                //console.error('Error during login:', error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e, countryName) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (

        <div>
            <AuthContainer onSubmit={handleSubmit}>
                <FieldContainer>
                    <FieldLabel>Email</FieldLabel>
                    <FieldValue>{user?.email || ""}</FieldValue>
                </FieldContainer>
                <FieldContainer className="div-form">
                    {/*<FieldLabel> Password</FieldLabel>*/}

                    <FieldInput>
                        <p className="change-password">Change Password</p>

                        <div className="password-form">
                            <p className="password">Current Password</p>

                            <div>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Current Password"
                                    name="currentPassword"
                                    value={formData?.currentPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            {errors.currentPassword && <div>{errors.currentPassword}</div>}
                        </div>
                        <div className="password-form">
                            <p className="password">New Password</p>
                            <div>
                                <input type={showPassword ? 'text' : 'password'}
                                       placeholder="New Password"
                                       name="newPassword"
                                       value={formData.newPassword}
                                       onChange={handleChange}
                                       required/>

                            </div>
                            {errors.newPassword && <div>{errors.newPassword}</div>}
                        </div>
                        <div className="password-form">
                            <p className="password">Confirm New Password</p>
                            <div>
                                <input type={showPassword ? 'text' : 'password'}
                                       placeholder="Confirm Password"
                                       name="confirmPassword"
                                       value={formData?.confirmPassword}
                                       onChange={handleChange}
                                       required/>
                            </div>

                            {errors.confirmPassword && <div>{errors.confirmPassword}</div>}
                        </div>

                        <div style={{marginTop: "10px"}}>

                            <input type="checkbox" placeholder="Password" id="password"
                                   className="checkbox_input" style={{cursor: "pointer"}}
                                   onClick={togglePasswordVisibility}
                                   required/>
                            <label style={{fontSize: "12px"}}>Show Password</label>
                        </div>
                        <CButton type="button" onClick={handlePasswordUpdate}
                                 disabled={loading}>  {loading ?
                            <Loading/> : <span>Updated Password</span>}</CButton>

                        <CButton type="submit" onClick={onCancel}>Cancel</CButton>
                    </FieldInput>

                </FieldContainer>

            </AuthContainer>

        </div>

    );

}


export const Auth2Card = () => {
    //const navigate = useNavigate();
    const {SecurityEnable2Fa, user} = useAuth();
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        isEnabled: user?.twoFactor, email:user?.email
    });


    const updatedValue = !formData?.isEnabled;

    const ContinueEnableOption = async () => {
        setLoading(true);
        try {
            console.log(updatedValue, "updated value")
            setFormData(prevState => ({...prevState, isEnabled: updatedValue}));
            await SecurityEnable2Fa(updatedValue, formData.email)
                .then((data) => {
                    if (data.status) {
                        alert(data.message);
                        // toast.success(`2FA ${updatedValue ? 'Enabled' : 'Disabled'}`);
                    } else {
                       // console.log(data.message, "error message")
                    }
                })
        } catch (error) {
            alert(error.message);
            //console.error('Error during 2FA update:', error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = () => {
        const result = window.confirm(
            'Are you sure you want to enable 2-Step verification?'
        );

        if (result) {
            // User clicked "OK"
            ContinueEnableOption().then()
        } else {

        }
    };


    return (


        <AuthContainer>
            {/*<ToastContainer/>*/}
            <FieldContainer>
                <FieldLabel>2FA</FieldLabel>
                <FieldValue>
                    <p style={{
                        fontWeight: "bold",
                        fontSize: "15px"
                    }}>{formData.isEnabled ? 'Enabled' : 'Disabled'}</p>
                    <SwitchContainer onClick={handleChange} style={{fontSize: "30px"}}>
                        {loading ? (
                            <span style={{fontSize: "10px"}}>Loading...</span>
                        ) : (
                            formData.isEnabled ? <FaToggleOn/> :
                                <FaToggleOff/>
                        )}
                    </SwitchContainer>
                </FieldValue>
            </FieldContainer>

        </AuthContainer>


    )

}


const SwitchContainer = styled.div`
    display: inline-block;
    cursor: pointer;
    //font-size: 20px;

    .icon {
        width: 50px;
        height: 2rem;
    }
`;

const CardContainer = styled.div`
    background-color: #F8F9FA;
    border-radius: 10px;
    padding: 30px;
    //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 5px 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    @media (max-width: 768px) {
        width: 100%;
        //width: 350px;
        //margin: 10px 0;
        padding: 30px;
        display: block;
        justify-content: center;
        color: #141D22;
    }
`;

const Icon = styled.div`
    width: 50px;
    height: 50px;
    //background-color: #007bff;
    border-radius: 50%;
    border: 3px solid #E8E9EA;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3e4350;
    font-size: 40px;

    //.icon-text {
    //    font-size: 20px;
    //}
`;

const TextContainer = styled.div`
    text-align: start;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;

    .color {
        color: red;
    }

    .green {
        color: #00C076;
    }
`;

const Title = styled.h6`
    font-size: 18px;
    padding-top: 8px;

`;

const Description = styled.div`
    font-size: 13px;
    padding-top: 8px;
    @media (max-width: 576px) {
        font-size: 14px;
        color: #141D22;
        margin-bottom: 10px;
    }

`;


const Button = styled.button`
    padding: 10px 20px;
    background-color: #FFCF01;
    color: #141D22;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 70%;
`;


const AuthContainer = styled.div`
    background-color: #ffffff;
    border-radius: 2px;
    width: 100%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(157, 157, 157, 0.34);
    //justify-content: center;

    .icon {
        font-size: 30px;
        margin-top: 5px;
    }

    @media (max-width: 768px) {
        width: 100%;
        color: #141D22;
    }
`;

const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 20px 40px;
    width: 100%;
    border-top: 2px solid #F3F5F7;

    @media screen and (max-width: 576px) {
        display: flex;
        flex-direction: row;
        padding: 20px 10px;
    }

    .div-form {
        &:first-child {
            border-top: none;
        }
    }

    &:first-child {
        border-top: none;
    }


    input {
        width: 60%;
        padding: 10px 20px;
        border: 1px solid rgba(198, 200, 201, 0.75);
        //border-radius: 2px;
    }


`;

const FieldLabel = styled.label`
    margin-top: 5px;
    font-size: 12px;
    //width: 15%;
    //@media screen and (max-width: 576px) {
    //    width: 100%;
    //}

`;
const FieldInput = styled.div`
    width: 100%;
    margin-left: 8rem;

    input {
        padding: 13px;
    }

    .checkbox_input {
        padding: 20px;
        width: 30px;
    }

    @media screen and (max-width: 576px) {
        margin-left: 1rem;
        p {
            color: #1C2127;
        }

        input {
            width: 100%;
        }
    }

    .password-form {
        margin-top: 2rem;
    }

    .password-form:nth-last-child(3) {
        margin-bottom: 2rem;
    }

    .change-password {
        font-weight: 600;
        margin-bottom: 2rem;
    }

    .password {
        margin-bottom: 5px;
        margin-top: 10px;
    }



`

const FieldValue = styled.div`
    width: 100%;
    font-size: 12px;
    margin-left: 10rem;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 576px) {
        margin-left: 5rem;
        margin-top: 5px;
        span {
            color: #373b3f;
        }
    }
`;


const AuthButton = styled.button`
    padding: 15px 20px;
    width: 20%;
    font-size: 12px;
    background-color: #F3F5F7;
    color: #141D22;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    //margin-top: 3rem;

    &:hover {
        background-color: #FFCF01;
    }

    @media screen and (max-width: 576px) {
        margin-top: 3rem;
        width: 100%;
        padding: 15px 26px;
    }
`;


const CButton = styled.button`
    padding: 15px 20px;
    width: 100%;
    margin-top: 1rem;
    font-size: 12px;
    background-color: #F3F5F7;
    color: #141D22;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    //margin-top: 3rem;

    &:hover {
        background-color: #FFCF01;
    }
`;
