import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const DW_Success = () => {
    const navigate = useNavigate();
    //const location = useLocation();
    const urlParams = new URLSearchParams(window.location.search);

    const amount = urlParams.get('amount');
    const message = urlParams.get('message');
    const transactionRef = urlParams.get('transactionRef');
    const transactionStatus = urlParams.get('transactionStatus');
    const date = urlParams.get('date');

    useEffect(() => {
        // console.log(`Amount: ${amount}`);
        // console.log(`message : ${message}`);
        // console.log(`Transaction Reference: ${transactionRef}`);
        // console.log(`Transaction Status: ${transactionStatus}`);
    }, [amount, message, transactionRef, transactionStatus, date]);

    const Navigate = () => {
        navigate("/profile");
    }

    return (
        <Container>
            <ImageContainer>
                <IoMdCheckmarkCircleOutline className="icon" />
            </ImageContainer>

            <Heading>Success</Heading>
            <SubHeading className="message">{message}</SubHeading>

            <DetailsContainer>
                <DetailItem>
                    <strong>TransactionId:</strong>
                    <span>{transactionRef}</span>
                </DetailItem>
                <DetailItem>
                    <strong>Amount:</strong>
                    <span>${amount}</span>
                </DetailItem>
                <DetailItem>
                    <strong>Status:</strong>
                    <span>{transactionStatus}</span>
                </DetailItem>
                <DetailItem>
                    <strong>Date:</strong>
                    <span>{date}</span>
                </DetailItem>
            </DetailsContainer>

            <div className="contact">
                <p className="cancel" onClick={Navigate}>Back</p>
            </div>

        </Container>)
};

export default DW_Success;

// Styled components for styling
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  border-radius: 5px;
  margin: auto; /* horizontally center the container */
  z-index: 999;
  padding: 0;
  //box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  min-height: 100vh; /* to ensure centering vertically */

  .message {
    margin-bottom: 5px;
  }

  .icon {
    font-size: 70px;
    color: #28a745;
  }

  .contact {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .contactUs {
    color: #FFCF01;
    font-weight: bold;
    cursor: pointer;
  }

  .cancel {
    color: #00C076;
    font-weight: bold;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    padding: 20px;
  }

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Heading = styled.h3`
  font-size: 25px;
  margin-top: 2rem;
`;

const SubHeading = styled.h3`
  font-size: 15px;
  margin-top: 1rem;
`;

const DetailsContainer = styled.div`
  border-bottom: 1px solid #B1B5C36B;
  margin-top: 25px;
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
  gap: 14rem;

  .status {
    background: #8FF5A0BD;
    padding: 4px 12px;
    border-radius: 10px;
    color: #00C076;
    border: 1px solid #4cd964;
  }

  span {
    font-size: 13px;
    color: #1C2127;
  }

  strong {
    font-size: 13px;
    color: #9e9ea4;
    font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    gap: 5rem;
  }
`;
