import React from "react"
import { useLocation } from "react-router-dom";
import Layout from "../../components/ProfileDashboard/SideBarLayout";
import InvestmentWallet from "../../components/ProfileDashboard/SideBarPages/InvestmentWallet";


const Investment = () => {

    const location = useLocation();
    const queryString = location.search;
    const searchParams = new URLSearchParams(queryString);

    // Example: Get the value of the first query parameter dynamically
    const queryParameterName = searchParams.keys().next().value;
   // const queryParameterValue = searchParams.get(queryParameterName);

    return (<Layout>
        <InvestmentWallet parameter={queryParameterName}/>
    </Layout>)

}

export default Investment
