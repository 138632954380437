import React from "react";
import "../../App.css"
import {Route, Routes} from "react-router-dom";
import Setting from "./setting";
import Layout from "../../components/ProfileDashboard/SideBarLayout";
import Account from "../../components/ProfileDashboard/SideBarPages/Account";
import InvestmentWallet from "../../components/ProfileDashboard/SideBarPages/InvestmentWallet";
import Transactions from "./transactions";
import PortfolioManagement from "../../components/ProfileDashboard/SideBarPages/PortFolioManagement";
import Withdrawal from "../../components/ProfileDashboard/SideBarPages/Withdrawal";
import Deposits from "../../components/ProfileDashboard/SideBarPages/Deposit";


const Index = () => {


    return (<Layout>
            <Routes>
                <Route path='/' element={<Account/>}/>
                <Route path='/deposit' element={<Deposits/>}/>
                <Route path='/deposit' element={<Withdrawal/>}/>
                <Route path='/transactions' element={<Transactions/>}/>
                {/*<Route path='/' element={<Home/>}/>*/}
                <Route path='/setting' element={<Setting/>}/>
                <Route path='/investment' element={<InvestmentWallet/>}/>
                <Route path='/portfolio' element={<PortfolioManagement/>}/>
            </Routes>

        </Layout>

    )
}

export default Index

