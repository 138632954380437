import React, {createContext, useContext, useState} from "react";
//import useInitializeAuthState from "../helper/useInitializeAuthState";
import useLocalStorage from "../helper/useLocalStorage";


const AuthContext = createContext();


export const useAuth = () => {
   return useContext(AuthContext);
};

const baseURL = "https://backend-unityexness.onrender.com";
// const baseURL = "https://backend.unityexness.com";
const ApiKey = 'unity-exness-public-2000'


export const AuthProvider = ({children}) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [updateSymbol, setUpdateSymbol] = useState("BTCUSDT")

    const [isLoggedIn, setIsLoggedIn] = useLocalStorage('isLoggedIn', false);
    const [token, setToken] = useLocalStorage('token', null);
    const [user, setUser] = useLocalStorage('user', null);
    const [InvestmentPlan, setInvestmentPlan] = useLocalStorage('InvestmentPlan', null);
    const [transactionHistory, setTransactionHistory] = useLocalStorage('transactionHistory', null);
    const [investmentHistory, setInvestmentHistory] = useLocalStorage('investmentHistory', null);

    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    // const [token, setToken] = useState(() => localStorage.getItem('token'));
    // const [InvestmentPlan, setInvestmentPlan] = useState(() => safeJSONParse(localStorage.getItem('InvestmentPlan')));
    // const [user, setUser] = useState(() => safeJSONParse(localStorage.getItem('user')));
    // const [transactionHistory, setTransactionHistory] = useState(() => safeJSONParse(localStorage.getItem('transactionHistory')));
    // const [investmentHistory, setInvestmentHistory] = useState(() => safeJSONParse(localStorage.getItem('investmentHistory')));




    // const [user, setUser] = useState(() => {
    //     const storedUser = localStorage.getItem('user');
    //     return storedUser ? storedUser : null;
    // });

    // const [transactionHistory, transactionHistory] = useState(null);
    // const [investmentHistory, setInvestmentHistory] = useState(null);

    // const [token, setToken] = useState(() => {
    //     const storedToken = localStorage.getItem('token');
    //     return storedToken ? JSON?.parse(storedToken) : null;
    // });
    //
    // const [InvestmentPlan, setInvestmentPlan] = useState(() => {
    //     const storedInvestmentPlan = localStorage.getItem('InvestmentPlan');
    //     return storedInvestmentPlan ? storedInvestmentPlan : null;
    // });
    //

    //
    // const [transactionHistory, setTransactionHistory] = useState(() => {
    //     const storedWalletNumber = localStorage.getItem('transactionHistory');
    //     return storedWalletNumber ? storedWalletNumber : null;
    // })
    //

    // const [investmentHistory, setInvestmentHistory] = useState(() => {
    //     const storedHistory = localStorage.getItem('investmentHistory');
    //     return storedHistory ? storedHistory : null;
    // })

    // Update local storage whenever state changes




    const returnSearchQuery = (data) => {
        setSearchQuery(data)
    };


    const handleNetworkError = (error) => {
        console.error(error, "network error");
        // return {status: false, message: error.message || 'network error occurred'};
    };


    const Enable2Fa = async (url, headers, body) => {
        try {
            const requestOptions = {
                method: "POST", headers: headers, body: JSON.stringify(body),
                // redirect: "follow"
            };
            const response = await fetch(url, requestOptions);
            if (response.status === 200 || response.status === 201) {
                const data = await response.json();
                return {status: true, message: data?.message, details: data};
            }
            else {
                const errorResponse = await response.json();
                return {status: false, message: errorResponse.message};
            }
        } catch (error) {
            return handleNetworkError(error);
        }
    };

    const VerifyWallet = async (url, headers, body) => {
        try {
            const requestOptions = {
                method: "POST", headers: headers, body: JSON.stringify(body),
                // redirect: "follow"
            };
            const response = await fetch(url, requestOptions);
            if (response.status === 200 || response.status === 201) {
                const data = await response.json();
                return {status: true, message: data?.message, account: data};
            }
            else {
                const errorResponse = await response.json();
                return {status: false, message: errorResponse.message};
            }
        } catch (error) {
            return handleNetworkError(error);
        }
    };

    const PostRequest = async (url, headers, body) => {
        try {
            const requestOptions = {
                method: "POST", headers: headers, body: JSON.stringify(body),
                credentials:"include"
                // redirect: "follow"
            };
            const response = await fetch(url, requestOptions);
            if (response.status === 200 || response.status === 201) {
                const data = await response.json();
                const token = data?.token
                setToken(token)
                await fetchData(token);
                await fetchTransactionHistory(token);
                await fetchInvestmentHistory(token);
                // localStorage.setItem('token', data?.token);
                return {status: true, message: data?.message, details: data};
            }
            else if (response.status === 498) {
                return {status: false, message: 'Access Denied'};
            }
            else {
                const errorResponse = await response.json();
                return {status: false, error: errorResponse.message};
            }
        } catch (error) {
            return handleNetworkError(error);
        }
    };


    const GetRequest = async (url, headers) => {
        try {
            const response = await fetch(url, headers);
            if (response.status === 200) {
                const data = await response.json();
                // console.log(data, "user")
                    setToken(data?.token);
                    setUser(data?.userDetails);
                   localStorage.setItem('user', data?.userDetails);
                return {status: true, message: 'successful', data};
            } else {
                const errorResponse = await response.json();
                return {status: false, message: errorResponse.message};
            }
        } catch (error) {
            return handleNetworkError(error);

        }
    };


    const GetTransactionRequest = async (url, headers) => {
        try {
            const response = await fetch(url, headers);
            if (response.status === 200) {
                const data = await response.json();
                    setToken(data?.token);
                    setTransactionHistory(data?.transactionHistory);
                  localStorage.setItem('transactionHistory', data?.transactionHistory);
                return {status: true, message: 'successful', data};
            } else {
                const errorResponse = await response.json();
                return {status: false, message: errorResponse.message};
            }
        } catch (error) {
            return handleNetworkError(error);
        }
    };

    const GetInvestmentTransaction = async (url, headers) => {
        try {
            const response = await fetch(url, headers);
            if (response.status === 200) {
                const data = await response.json();
                    setToken(data.token);
                    setInvestmentHistory(data?.investments);
                   localStorage.setItem('investmentHistory', data?.investments);
                return {status: true, message: 'successful', data};
            } else {
                const errorResponse = await response.json();
                return {status: false, message: errorResponse.message};
            }
        } catch (error) {
            return handleNetworkError(error);
        }
    };


    const register = async (country, email, password, partnerCode) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization",  `${ApiKey}`);
        const requestBody = JSON.stringify({
            country: country, email: email, password: password, partnerCode: partnerCode
        })
        const requestOptions = {
            method: "POST", headers: myHeaders, body: requestBody,
            credentials:"include"
            // redirect: "follow"
        };

        try {
            const response = await fetch(`${baseURL}/register`, requestOptions);
            if (response.status === 200 || response.status === 201) {
                const data = await response.json();
                    setIsLoggedIn(true);
                    const token = data?.token
                    setToken(token);
                    await fetchData(data?.token);
                     await  fetchInvestment()
                    await fetchTransactionHistory(data?.token);
                    await fetchInvestmentHistory(data?.token);
                     localStorage.setItem('token', data?.token);
               return {status: true, message: data.message, details: data};
            } else {
                const errorResponse = await response.json();
                return {status: false, message: errorResponse.message};
            }
        } catch (error) {
            console.log(error, "network error")
            // return {status: false, message: error.message || 'An error occurred'};
        }


    };

    const login = async (email, password) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization",  `${ApiKey}`);
        const requestBody = JSON.stringify({
            email: email, password: password
        })
        const requestOptions = {
            method: "POST", headers: myHeaders, body: requestBody,
            credentials:"include"
            // redirect: "follow"
        };
        try {
            const response = await fetch(`${baseURL}/login`, requestOptions);
            if (response.status === 200 || response.status === 201) {
                const data = await response.json();
                    setIsLoggedIn(true);
                    const token = data?.token
                    setToken(token);
                    await fetchData(data?.token);
                    await fetchTransactionHistory(data?.token);
                    await fetchInvestmentHistory(data?.token);
                    await  fetchInvestment()
                    localStorage.setItem('token', data?.token);
                return {status: true, message: data.message, details: data};
            } else {
                const errorResponse = await response.json();
                return {status: false, message: errorResponse.message};
            }
        } catch (error) {
            console.log(error, "network error")
            // return {status: false, message: error.message || 'An error occurred'};
        }


    };

    const Security2Fa = async (email, twoFactorCode) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization",  `${ApiKey}`);
        const requestBody = JSON.stringify({
            email: email, twoFactorCode: twoFactorCode
        })
        const requestOptions = {
            method: "POST", headers: myHeaders, body: requestBody,
            credentials:"include"
            // redirect: "follow"
        };
        try {
            const response = await fetch(`${baseURL}/verifyTwoStepVerification`, requestOptions);
            if (response.status === 200 || response.status === 201) {
                const data = await response.json();
                    setIsLoggedIn(true);
                    const token = data?.token
                    setToken(token);
                     localStorage.setItem('token', token);
                        await fetchData(data?.token);
                        await fetchTransactionHistory(data?.token);
                        await fetchInvestmentHistory(data?.token);
                        await  fetchInvestment()
                return {status: true, message: data.message, details: data};
            } else {
                const errorResponse = await response.json();
                return {status: false, message: errorResponse.message};
            }
        } catch (error) {
            console.log(error, "network error")
            // return {status: false, message: error.message || 'An error occurred'};
        }


    };

    const resetPassword = async (email) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization",  `${ApiKey}`);
        const requestBody = {
            email: email,
        };
        return await PostRequest(`${baseURL}/resetPassword`, myHeaders, requestBody);
    };

    const resetPasswordOTP = async (email, passwordResetOtp) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization",  `${ApiKey}`);
        const requestBody = {
            email: email, passwordResetOtp: passwordResetOtp,
        };
        return await PostRequest(`${baseURL}/verifyPasswordResetOtp`, myHeaders, requestBody);
    };


    const changePassword = async (newPassword, email) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization",  `${ApiKey}`);
        const requestBody = {
            newPassword: newPassword, email: email
        };
        return await PostRequest(`${baseURL}/updatePassword`, myHeaders, requestBody);
    };


    const fetchData = async (token) => {
        if (token) {
            const myHeaders = {
                headers: {
                    'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
                }
            };
            return await GetRequest(`${baseURL}/fetchUserDetails`, myHeaders);

        }
    };

    const fetchTransactionHistory = async (token) => {
        if (token) {
            const myHeaders = {
                headers: {
                    'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
                }
            };
            // setTransactionHistory(transactionData);
            return await GetTransactionRequest(`${baseURL}/transactionHistory`, myHeaders);
        }
    };

    const fetchInvestmentHistory = async (token) => {
        if (token) {
            const myHeaders = {
                headers: {
                    'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
                }
            };
            // setInvestmentPlan(investmentTransactionData);
            return await GetInvestmentTransaction(`${baseURL}/myInvestments`, myHeaders);
        }
    };

    const fetchInvestment = async () => {
        try {
            const investmentPlanResponse = await fetch(`${baseURL}/investmentPlans`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${ApiKey}`
                }
            });
            const investmentPlanData = await investmentPlanResponse.json();
            setInvestmentPlan(investmentPlanData);
            localStorage.setItem('InvestmentPlan', JSON.stringify(investmentPlanData));
        } catch (error) {
            handleNetworkError(error);
        }
    };



    const updateUserProfile = async (firstName, lastName, phone) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestBody = {
            firstName: firstName, lastName: lastName, phone: phone,
        };
        if (token) {
            return await PostRequest(`${baseURL}/updateUserInfo`, myHeaders, requestBody);
        }
    };



    const updateUserPassword = async (currentPassword, newPassword, confirmPassword) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestBody = {
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
        };
        if (token) {
            return await PostRequest(`${baseURL}/changePassword`, myHeaders, requestBody);
        }
    };

    const requestUserOTP = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestOptions = {
            method: "GET", headers: myHeaders, redirect: "follow"
        };
        try {
            if (token) {
                const response = await fetch(`${baseURL}/requestOtp`, requestOptions)
                if (response.status === 200 || response.status === 201) {
                    const data = await response.json();

                    if (data.token) {
                        setToken(data?.token);
                        // localStorage.setItem('token', data.token);
                    }
                    return {status: true, message: 'successful', data, result: data};
                } else {
                    const errorResponse = await response.json();
                    return {status: false, message: errorResponse.message};
                }
            }
        } catch (error) {
            return {error, message: error.message};
        }
    };

    const verifyUserOTP = async (otp) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestBody = {
            otp: otp
        };
        if (token) {
            return await PostRequest(`${baseURL}/verifyOtp`, myHeaders, requestBody);
        }
    };

    const verifyWalletNUmber = async (walletNumber) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestBody = {
            walletNumber: walletNumber
        };
        if (token) {
            return await VerifyWallet(`${baseURL}/verifyWalletNumber`, myHeaders, requestBody);
        }

    };

    const P2PTransfer = async (fromAccount, toAccount, amount, paymentOption, paymentMethod) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestBody = {
            fromAccount: fromAccount,
            toAccount: toAccount,
            amount: amount,
            paymentOption: paymentOption,
            paymentMethod: paymentMethod
        };
        if (token) {
            return await PostRequest(`${baseURL}/doTransfer`, myHeaders, requestBody);
        }

    };

    const Withdrawal = async (paymentMethod, amount, fromAccount, toWalletAddress, paymentOption) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestBody = {
            paymentMethod: paymentMethod,
            amount: amount,
            fromAccount: fromAccount,
            toWalletAddress: toWalletAddress,
            paymentOption: paymentOption,
        };
        if (token) {
            return await PostRequest(`${baseURL}/withdrawal`, myHeaders, requestBody);
        }
    }


    const Deposit = async (paymentMethod, amount, toAccount, fundAdmin, paymentOption, transactionId) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestBody = {
            paymentMethod: paymentMethod,
            amount: amount,
            toAccount: toAccount,
            fundAdmin: fundAdmin,
            paymentOption: paymentOption,
            transactionId: transactionId
        };

        if (token) {
            return await PostRequest(`${baseURL}/deposit`, myHeaders, requestBody);
        }

    };


    const StartInvestment = async (amount, spread, duration, investmentPlan) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestBody = {
            amount: amount, spread: spread, duration: duration, investmentPlan: investmentPlan
        };

        if (token) {
            return await PostRequest(`${baseURL}/startInvestment`, myHeaders, requestBody);
        }

    };


    const RedeemInvestment = async (amount, toAccount,) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestBody = {
            amount: amount, toAccount: toAccount,
        };

        if (token) {
            return await PostRequest(`${baseURL}/redeemInvestment`, myHeaders, requestBody);
        }

    };


    const SecurityEnable2Fa = async (isEnabled, email) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestBody = {
            isEnabled: isEnabled,
            email:email
        };
        if (token) {
            return await Enable2Fa(`${baseURL}/enableTwoFactor`, myHeaders, requestBody);
        }
    };


    const UpLoadDocument = async (file)=> {
        try {
            let myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            let formdata = new FormData();
            formdata.append("image", file, file.name);

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            if (token) {
                // return await PostRequest(`${baseURL}/verify`, requestOptions);
                let response = await fetch(`${baseURL}/verify`, requestOptions)

                if (response.status === 200 || response.status === 201) {
                    const data = await response.json();
                    const token = data?.token
                    setToken(token)
                    await fetchData(token);
                    await fetchTransactionHistory(token);
                    await fetchInvestmentHistory(token);
                    // localStorage.setItem('token', data?.token);
                    return {status: true, message: data?.message, details: data};
                } else if (response.status === 498) {
                    return {status: false, message: 'Access Denied'};
                } else {
                    const errorResponse = await response.json();
                    return {status: false, error: errorResponse.message};
                }


            }
        } catch (e) {
            return handleNetworkError(e);
        }
    }

    const Logout = () => {
        setUser(null);
        setInvestmentPlan(null);
        setTransactionHistory(null);
        setInvestmentHistory(null);
        setIsLoggedIn(false);
        setToken(null);

        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('transactionHistory');
        localStorage.removeItem('investmentHistory');
    };



    // const {token, loggedIn, user, setIsLoggedIn, setToken,setUser, setInvestmentPlan, setTransactionHistory, setInvestmentHistory} = useAuth();


    return(<AuthContext.Provider value={{
        setIsLoggedIn, setToken, setUser, setInvestmentPlan, setTransactionHistory, setInvestmentHistory,
        returnSearchQuery,
        searchQuery,
        updateSymbol,
        setUpdateSymbol,
        user,
        InvestmentPlan,
        transactionHistory,
        investmentHistory,
        token,
        register,
        login,
        Logout,
        isLoggedIn,
        resetPassword,
        resetPasswordOTP,
        changePassword,
        updateUserProfile,
        updateUserPassword,
        requestUserOTP,
        verifyUserOTP,


        verifyWalletNUmber,
        P2PTransfer,
        Deposit,
        Withdrawal,
        StartInvestment,
        RedeemInvestment,

        Security2Fa,
            SecurityEnable2Fa,
        UpLoadDocument
    }}>
            {children}
    </AuthContext.Provider>);
};







// smartsuppclient@gmail.com
