import React from "react"
import {Wrapper} from "./Account";
import styled from "styled-components";
import {FaRegCalendarAlt} from "react-icons/fa";
import {MainContainer} from "../TABS/Tabs";
import EmptyImage from "../TABS/EmptyImage";
import {GeneralTransactionHistory} from "../TABS/TransactionHistory/GeneralTransactionHistory";
import withLoader from "../../../Loader/WithLoader";
import 'react-toastify/dist/ReactToastify.css';
//import { ToastContainer } from 'react-toastify';
import {useAuth} from "../../../contexts/AppContext";

const TransactionHistorys = ( ) => {

    const {transactionHistory, user} = useAuth();


    return (

        <main className='main-container'>
            {/*<ToastContainer/>*/}
            <Wrapper>
                <div className='title'>
                    <h4>Transactions History</h4>
                </div>
                <ContainerChildren>
                    <Child first> <FaRegCalendarAlt/> Last 30 days</Child>
                    <Child>All transaction types</Child>
                    <Child>All statuses</Child>
                    <Child>All accounts</Child>
                </ContainerChildren>

                <MainContainer>

                    {transactionHistory && transactionHistory.length > 0 ? (
                        <GeneralTransactionHistory user={user} transactionHistory={transactionHistory}/>) : (<EmptyImage/>)}


                </MainContainer>
            </Wrapper>

        </main>)
}


const ContainerChildren = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-top: 5px;
`;

const Child = styled.div`
    //background-color: #F3F5F7;

    background-color: ${props => props.first ? '#6C8595' : '#F3F5F7'};
    color: ${props => props.first ? '#ffffff' : '#1C2127'};
    padding: 10px;
    margin: 5px;
    border-radius: 20px;
    text-align: start;
    font-size: 12px;
    //color: #1C2127;
    width: 45%;
    max-width: 150px;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
// export default TransactionHistorys

export default withLoader(TransactionHistorys);
