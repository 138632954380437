import React, {useState} from "react";
import Sidebar from "./SideBar";
import AutoSlider from "./TABS/Slider";
import Header from "./Header";
import SubHeader from "./SubHeader";
import ChatSupport from "../../Utilities/ChatSupport";
import {useAuth} from "../../contexts/AppContext";
import 'react-toastify/dist/ReactToastify.css';
//import { ToastContainer } from 'react-toastify';
//import {GoogleTranslate} from "../../Utilities/LanguageTranslator";



const Layout = ({children}) => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const {user} = useAuth();

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    // const showSidebar = location.pathname !== '/';
    return (<div>
        <Header OpenSidebar={OpenSidebar}/>
        {!(user?.verification?.email && user?.verification?.profile) && (
            <SubHeader/>
        )}

        <div className="grid-container">
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
            <div>
                <AutoSlider/>
            </div>
            {children}
            <div>
                <ChatSupport/>
            </div>
        </div>
        {/*Footer removed*/}
        {/*<Footer/>*/}
    </div>)
};



export default Layout;
