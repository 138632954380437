import * as Yup from 'yup';


export const UpdatePasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    newPassword: Yup.string().required('new password is required').min(6, 'Password must be at least 6 characters long'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'passwords must match')
        .required('Confirm password is required'),
});


export const PasswordValidationSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .required('Current password is required'),

    newPassword: Yup.string()
        .required('New password is required'),

    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required')
});


export const ResetOTPSchema = Yup.object().shape({
    passwordResetOtp: Yup.string().required('otp is required'),
});


export const ResetSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
});


export const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required')
});


export const SignUPSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
    partnerCode: Yup.string().optional(),
    termsAndConditions: Yup.boolean().oneOf([true], 'Terms and conditions must be accepted')
});

//INSIDE


export const ResetUserOTPSchema = Yup.object().shape({
    otp: Yup.string().required('otp is required'),
});


export const Reset2FaOTPSchema = Yup.object().shape({
    twoFactorCode: Yup.string().required('otp is required'),
});

export const UpdateUSerSchema = Yup.object().shape({
    firstName: Yup.string().required('firstName is required'),
    lastName: Yup.string().required('Password is required'),
    phone: Yup.string()
        .required('Phone is required')
        .matches(/^\d{11}$/, 'Phone number must be 11 digits')
        .matches(/^[0-9]*$/, 'Phone number must contain only digits')
});


export const WalletNumberSchema = Yup.object().shape({
    walletNumber: Yup.string()
        .required('Account Number is required')
        // .matches(/^\d{10}$/, 'Account number must be 10 digits')
        .matches(/^[0-9]*$/, 'Account number must contain only digits')
});


export const FormValidationSchema = Yup.object().shape({
    file: Yup.mixed()
        .required('File is required')
        .test('fileSize', 'File size is too large', (value) => {
            return value && value.size <= 1024 * 1024 * 10; // 10MB
        })
        .test('fileType', 'Invalid file type', (value) => {
            return value && ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
        }),
});
