import {useEffect} from 'react';


const ChatSupport = () => {

    // const loadTawkToScript = () => {
    //     if (!window.Tawk_API) {
    //         let s1 = document.createElement("script");
    //         let s0 = document.getElementsByTagName("script")[0];
    //         s1.async = true;
    //         s1.src = 'https://embed.tawk.to/660eab921ec1082f04dee3bf/1hqkksdr9';
    //         s1.charset = 'UTF-8';
    //         s1.setAttribute('crossorigin', '*');
    //         s0.parentNode.insertBefore(s1, s0);
    //     }
    // };

    const loadJivoChatScript = () => {
        if (!window.jivo_api) {
            let s1 = document.createElement("script");
            let s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = '//code.jivosite.com/widget/Bll7kqqkBy';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        }
    };

    useEffect(() => {
        loadJivoChatScript();
    }, []);


    return null
};

export default ChatSupport;
