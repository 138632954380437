import styled from "styled-components";
import {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
//import { ToastContainer } from 'react-toastify';


const Tab = ({tabs, parameter}) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(()=>{
        switch (parameter) {
            case "redeem":
                setActiveTab(1)
                break;

            case "plan":
                setActiveTab(0)
                break;

            case "my_investment":
                setActiveTab(2)
                break;

            default:
                setActiveTab(0)
        }

    },[parameter])


    return (<div>
        {/*<ToastContainer/>*/}
        <TabContainer>
            {tabs.map((tab, index) => (<div key={index} onClick={() => handleTabClick(index)}>
                <TabText active={index === activeTab}>{tab.title}</TabText>
                <TabLine active={index === activeTab}/>
            </div>))}
        </TabContainer>
        <TabContent>
            {tabs[activeTab].content}
        </TabContent>
    </div>);
};


export default Tab


const TabContainer = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #BDBFC1;
    overflow-x: auto;
    overflow-y: hidden;
    transition: all 0.5s;
    text-align: start;
    padding-top: 8px;
    cursor: pointer;

  @media only screen and (max-width: 600px) {
    overflow-x: hidden !important;
    overflow-y: hidden;
  }
`;

// #74797C
const TabText = styled.span`
    margin-right: 3rem;
    //padding: 20px;
    cursor: pointer;
    //color: #141D22;
    color: ${({active}) => (active ? '#141D22' : '#74797C')};
    font-size: 12px;
    font-weight: 400;
    @media screen and (max-width: 576px) {
        color: #000000;
        text-align: start;
    }
`;

const TabLine = styled.div`
    height: 3px;
    margin-top: 12px;
    text-align: start;
    width: ${({active}) => (active ? '85%' : '0')};
    background-color: ${({active}) => (active ? '#141D22' : 'transparent')};
    transition: width 0.3s ease;

    @media screen and (max-width: 576px) {
        width: ${({active}) => (active ? '60%' : '0')};
        background-color: ${({active}) => (active ? '#141D22' : 'transparent')};
    }
`;


const TabContent = styled.div`
    margin-top: 6px;
`;


export const MainContainer = styled.div`
    color: #141D22;
    width: 100%;

    .header-text {
        margin-bottom: 0;
    }

    h2 {
        margin-top: 2rem;
        margin-bottom: 1rem;
        color: #141D22;
        font-size: 18px;
    }


    p {
        margin-top: 10px;
        font-size: 12px;
    }

    @media screen and (max-width: 576px) {
        color: #FFFFFF;
        .header-text {
            color: #000;
        }
    }
`
