import styled from "styled-components";
import {ClipLoader} from "react-spinners";

const LoadingSpan = styled.span`
    display: flex;
    align-items: center;
`;

const Loader = styled(ClipLoader)`
    margin-right: 5px; /* Adjust margin as needed */
`;

const LoadingText = styled.span`
    margin-left: 5px; /* Adjust margin as needed */
`;

const Loading = () => {
    return (<LoadingSpan>
        <Loader color="black" size={15}/>
        {/*<Loader color="#85d37e" size={10}/>*/}
        <LoadingText>Please wait...
        </LoadingText>
    </LoadingSpan>);
};

export default Loading;


