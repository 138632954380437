import React from 'react'
import {Link} from 'react-router-dom'
import Login from "../components/Authentication/Login";
import Register from "../components/Authentication/Register";
import AuthHeader from "../components/Authentication/Header";
import Footer from "../components/Authentication/Footer";
import Tabs from "../components/Authentication/Tabs";
import getCompanyInfo from "../Utilities/companyInfo";

const Auth = () => {
    // const navigate = useNavigate();

    const company = getCompanyInfo()

    const tabs = [{
        title: "Login", content: <Login/>
    }, {
        title: "Signup", content: <Register/>
    }]

    return (

        <div className="Tab_Auth">
            <AuthHeader/>
            <div className="auth-layout">
                <div>
                    <h2 className="auth-layout-text">Welcome to {company.name}</h2>
                    <div className="auth-container">
                        <Tabs tabs={tabs}/>
                        {/*<Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick}/>*/}
                    </div>
                </div>

            </div>

            <Link className="Link" to='/resetpassword'>
                <span>I Forgot My Password </span>
            </Link>

            <Footer/>
        </div>

    )
}

export default Auth
