import React, {useEffect, useState} from "react";
import LoadingSpinner from "./LoadingSpinner";

const withLoader = (WrappedComponent) => {
    return (props) => {
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            setLoading(true); // Set loading to true when component is mounted

            // Simulating data loading with a setTimeout
            const timer = setTimeout(() => {
                setLoading(false); // Set loading to false after a delay
            }, 3000); // Adjust the delay as needed

            return () => clearTimeout(timer); // Clean up on unmount
        }, []);

        return loading ? <LoadingSpinner/> : <WrappedComponent {...props} />;
    };
};

export default withLoader;
