import React, {useState} from "react";
import {useNavigate} from 'react-router-dom'
import {useAuth} from "../../contexts/AppContext";
import {ResetSchema} from "../../validation/validation";
import * as Yup from "yup";
import Loading from "../../Loader/Loading";
import {toast} from "react-toastify";
//import {IoArrowBackCircleOutline} from "react-icons/io5";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const {resetPassword} = useAuth();

    const [formData, setFormData] = useState({
        email: '',
    });

    const handleVerify = async () => {
        // e.preventDefault();
        setLoading(true);
        try {
            await ResetSchema.validate(formData, {abortEarly: false});
            await resetPassword(formData.email)
                .then((data) => {
                    if (data.status) {
                        toast.success(data.message);
                        return navigate(`/resetPasswordOtp?email=${formData.email}`);
                    } else {
                        console.log(data.message, "error message")
                        toast.error(data.message);
                        return navigate("/resetPassword");
                    }
                }).catch((error) => {
                    console.log("Oops Something Went Wrong Please Try Again")
                    toast.error("Oops Something Went Wrong Please Try Again");
                })
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
            }
        } finally {
            setLoading(false);
        }
    };


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };


    // const Navigate = () => {
    //     navigate("/");
    // }


    return (<div>
        {/*<ToastContainer/>*/}
        {/*<IoArrowBackCircleOutline style={{fontSize: "25px", cursor: "pointer"}} onClick={Navigate}/>*/}
        <div className="auth-container__form">


            <p style={{color: "#21262C", fontSize: "12px", fontWeight: "600"}}>Enter your email
                address</p>
            <p>If you have lost your password and could not have access any more, you can just reset your password here</p>
            <div>

                <label>your email address</label>
                <input type="email"
                       placeholder="Email"
                       name="email"
                       value={formData.email}
                       onChange={handleChange}
                       required/>
                {errors.email && !formData.email && <div className="error">{errors.email}</div>}
            </div>

            <button type="submit" onClick={handleVerify}> {loading ? <Loading/> :
                <span>Continue</span>}</button>
        </div>
    </div>)
}

export default ResetPassword
