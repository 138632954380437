import React, { useState} from "react";
import styled from "styled-components";
import Modal from "./Modal";
import {BiArrowBack} from "react-icons/bi";
import {
    CountryItem,
    DropdownButton,
    DropdownContainer,
    DropdownContent
} from "./dropDown/CustomDropDown";
import {IoMdCopy} from "react-icons/io";
import QRCode from "react-qr-code";
import {
    DepositConfirmationPage,
    P2PConfirmationPage,
    WithdrawalConfirmationPage
} from "./TransactionHistory/confirmation";
import {useAuth} from "../../../contexts/AppContext";
import * as Yup from "yup";
import Loading from "../../../Loader/Loading";
//import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BeatLoader} from "react-spinners";
import {formatCurrency2} from "./formatCurrency";
import maestro from "../../../assets/banks/maestro-2.svg";
import TransferSuccessFul from "./TransactionHistory/successfulTransfer";
import {useNavigate} from "react-router-dom";
import CongratsModal from "./TransactionHistory/CongratsModal";
import {recommendedWallets} from "../../../Utilities/wallet";
import getCompanyInfo from "../../../Utilities/companyInfo";



// unavailable card
export const UnavailableCard = ({bank}) => {
    const {name, processingTime, fee, Limit, Image_Icon, session} = bank;

    return (
        <div>

            <CardContainer>
                <div className='invest-title'>
                    <div style={{display: "flex"}}>
                        {session === "Recommended" ? (
                            <Icon> <img src={maestro} alt="Bitcoin"/></Icon>) : (
                            <Icon>{Image_Icon}</Icon>)}
                        {session === "Recommended" ? (<Title>Crypto</Title>) : (
                            <Title>{name}</Title>)}
                    </div>
                    {/*<p className="Unavailable">{session}</p>*/}

                    <p className="Unavailable">Unavailable</p>
                </div>
                <TextContainer>
                    <Description>{processingTime}</Description>
                    <Description>{fee}</Description>
                    <Description>{Limit}</Description>
                </TextContainer>
            </CardContainer>

        </div>

    );
};


// Deposit Form & Cards
export const DepositBanksCard = ({bank}) => {
    const {processingTime, fee, Limit, Image_Icon, session} = bank; //name,
    const [isModalOpen, setIsModalOpen] = useState(false);

    // console.log(type, AccountNumber)

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    return (
        <div>

            <CardContainer onClick={openModal}>
                <div className='invest-title'>
                    <div style={{display: "flex"}}>
                        {session === "Recommended" ? (
                            <Icon> <img src={maestro} alt="Bitcoin"/></Icon>) : (
                            <Icon>{Image_Icon}</Icon>)}
                        {session === "Recommended" ? (<Title>Crypto</Title>) : (
                            <Title>Cryptocurrency</Title>
                            // <Title>{name}</Title>
                        )}

                    </div>
                    <p className="Recommended">Recommended</p>
                    {/*<p className="Recommended">{session}</p>*/}
                </div>
                <TextContainer>
                    <Description>{processingTime}</Description>
                    <Description>{fee}</Description>
                    <Description>{Limit}</Description>
                </TextContainer>
            </CardContainer>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <DepositForm bank={bank} onClose={closeModal}/>
            </Modal>

        </div>

    );
};


export const DepositForm = ({onClose, bank}) => {
    const {user, Deposit} = useAuth();
    const navigate = useNavigate();
    const [selectedBank, setSelectedBank] = useState("");
    const [showCopiedMessage,  ] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    // const [isModalOpens, setIsModalOpens] = useState(false);

    const company = getCompanyInfo();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const admin = selectedBank?.AccountNumber


    const [formData, setFormData] = useState({
        paymentMethod: selectedBank?.name || "",
        amount: "",
        toAccount: user?.walletNumber,
        fundAdmin: "",
        paymentOption: "Deposit",
        transactionId: ""
    });


    const handleDeposit = async () => {
        setLoading(true);
        try {
            await Deposit(formData.paymentMethod, formData.amount, formData.toAccount || selectedBank?.AccountNumber, formData.fundAdmin, formData.paymentOption, formData.transactionId)
                .then((data) => {
                    if (data.message === "Deposit request submitted successfully") {
                        setFormData({
                            paymentMethod: selectedBank?.name || "",
                            amount: "",
                            toAccount: "",
                            fundAdmin: "",
                            paymentOption: "Deposit"
                        });
                        setSelectedBank("")
                        alert(data?.message || "Deposit request submitted successfully");
                        return navigate(`/profile/success?amount=${data?.details?.amount}&message=${data?.details?.message}&transactionRef=${data?.details?.transactionRef}&transactionStatus=${data?.details?.transactionStatus}&date=${data?.details?.date}`);

                    }  else if (data?.message === 'Access Denied') {
                       alert("Login to Continue");
                        return navigate(`/`);
                    } else if(data.error) {
                        alert(data?.error);
                    }

                }).catch((error) => {
                    alert(error?.message);
                })
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                    alert(error?.message)
                });
            }
        } finally {
            setLoading(false);
        }


    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // const closeModal = () => {
    //     setIsModalOpens(false);
    // };

    const handleSelectBank = (bankName) => {
       // console.log(bankName, "bankName")
        setFormData({
            ...formData,
            paymentMethod: bankName?.name,
            fundAdmin: bankName?.AccountNumber
        });
        setSelectedBank(bankName);
        setIsOpen(false);
        //console.log(formData, "formdat....a")
    };

    const handleChange = async (e, countryName) => {
        const {name, value} = e?.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!formData.paymentMethod) {
            alert('Payment Method is required to proceed.');
            return
        }

        if(!formData.amount){
            alert('Please enter amount');
            return
        }

        if (formData?.amount < user?.depositLimit) {

            alert(`Minimum Deposit must be $100.`);
           // toast.info(`Minimum Deposit must be $100.`);
        } else {

            if(!formData?.amount.replace(/[^0-9]/g, '')){

                alert(`Please enter amount`);

            }else {
                setSubmitted(true);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    paymentOption: "Deposit",
                    paymentMethod: selectedBank?.name,
                    fundAdmin: selectedBank?.AccountNumber
                }));
            }
        }
    };

    if (submitted) {
        return (<Container>
            <DepositConfirmationPage onClose={onClose} setSubmitted={setSubmitted}
                                     handleDeposit={handleDeposit} formData={formData}
                                     setFormData={setFormData}
                                     amount={formData.amount.replace(/[^0-9]/g, '')}
                                     accountToFund={user.walletNumber}
                                     fundWalletAddress={admin}
                                     paymentOption={formData.paymentOption}
                                     paymentMethod={formData.paymentMethod} loading={loading}

            />
        </Container>)
    }


    const copyToClipboard = (admin) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(admin)
                .then(() => {
                    alert("Wallet Address Copied")
                })
                .catch((error) => {
                    alert("Failed to copy to clipboard");
                });
        } else {
            alert("Clipboard API not supported");
        }
    }


    const amount = user?.balance;
    const formattedAmount = formatCurrency2(amount);


    return (
        <Container>
            <FormContainer>
                <h3 className='text' style={{marginBottom:20}} onClick={onClose}>
                    <div style={{background:'#000',height:30,width:30,borderRadius:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <BiArrowBack className='icon' style={{color:'#fff'}}/>
                    </div>
                </h3>
                <FieldContainer>
                    <FieldLabel style={{marginBottom:10}}>Deposit Method:</FieldLabel>
                    <DropdownContainer>
                        <DropdownButton onClick={toggleDropdown}>

                            {selectedBank ? (<>
                                <div className="image">
                                    {selectedBank?.image}
                                </div>
                                <div className="text">
                                    {selectedBank?.name}
                                </div>
                            </>) : (<>
                                <div className="text">
                                    ---Select----
                                </div>
                            </>)}
                        </DropdownButton>
                        {isOpen && (<DropdownContent>
                            {recommendedWallets?.map((bank, index) => (
                                <CountryItem value={bank.name} key={index}
                                             onClick={() => handleSelectBank(bank)}
                                             style={{cursor: "pointer"}}>
                                    <div className="image">
                                        {bank?.image}
                                    </div>
                                    <div className="text">
                                        {bank?.name}
                                    </div>
                                </CountryItem>))}
                        </DropdownContent>)}
                    </DropdownContainer>

                </FieldContainer>
                <FieldContainer>
                    <FieldLabel>To account</FieldLabel>
                    <div className="Account">
                        <div>
                            <span className="step-number">ST</span>
                            <span>{user?.walletNumber}</span>
                        </div>
                        <div>{formattedAmount}</div>
                    </div>
                </FieldContainer>
                <FieldContainer>
                    <FieldLabel>Amount</FieldLabel>
                    <input
                        type="text"
                        value={formData.amount}
                        name="amount"
                        placeholder="0"
                        style={{fontSize: "15px", fontWeight: "500"}}
                        onChange={handleChange} required={true}
                    />
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "5px",
                        flexDirection: "row"
                    }}>
                            <span
                                style={{color: "#000000"}}>Minimum Deposit</span><span>({user?.depositLimit} USD)</span>

                    </div>
                </FieldContainer>
                <CardContainers>
                    <CardContent>
                        <Text>
                            Enter the amount you want to deposit. It should fall within the
                            suggested range.
                        </Text>
                    </CardContent>
                </CardContainers>
                <AmountContainer>
                    <p className="text">Deposit</p>
                    {formData.amount === "" ? "0 USD" : (parseFloat(formData.amount) || 0).toLocaleString('en-US', {
                        style: 'currency', currency: 'USD'
                    })}

                </AmountContainer>

                <AuthButton type="button" onClick={handleSubmit}>
                    Continue
                    {/*{loading ? (<Loading/>) : (<span> Continue</span>)}*/}
                </AuthButton>
            </FormContainer>

            <TextWrapper className='HT'>
                <p>
                    <strong>Terms</strong>
                </p>
                <p>Average payment time: 1 hour</p>
                <p>Fee: 0%</p>
                <p>
                    <strong>Need Help!</strong>
                </p>
                <p>Contact Support Team {company.supportEmail}</p>
                {bank.type === "bank" ? (<div>
                    <p>Bank Details</p>
                    <CardContainer>
                        <div className='invest-title'>
                            <Icon>
                                {bank.Image_Icon}
                            </Icon>
                            <Title>{bank.name}</Title>
                        </div>
                        <TextContainer>
                            <Description
                                style={{fontWeight: "600"}}>{selectedBank.AccountNumber}</Description>
                            <Description>{bank.processingTime}</Description>
                        </TextContainer>
                    </CardContainer>

                </div>) : (<div>


                    {selectedBank.name ? (

                        <div style={{marginBottom:30}}>
                            <br/>
                            <FieldContainer>
                                <FieldLabel style={{
                                    fontWeight: "bold",
                                    marginTop: "5px",
                                    fontSize:'13px'
                                }}>   {selectedBank.name} Wallet
                                    Address</FieldLabel>
                                <div className="" style={{width: "50%"}}>


                                    <div>
                                        <p style={{fontSize:13,textTransform:'lowercase'}}>{selectedBank.AccountNumber}</p>
                                        <IoMdCopy onClick={() => copyToClipboard(admin)}
                                                   style={{
                                                       fontSize: "20px",
                                                       cursor: "pointer"
                                                   }}/>
                                    </div>
                                </div>

                            </FieldContainer>
                            {showCopiedMessage &&
                                <span style={{
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                    color: "green"
                                }}>Wallet Address copied to clipboard!</span>}

                            <div style={{
                                height: "auto",
                                marginTop: "1rem",
                                maxWidth: '100%',
                                width: "50%"
                            }}>
                                <QRCode
                                    size={100}
                                    style={{height: "auto", maxWidth: "100%", width: "100%",background:'red'}}
                                    value={selectedBank.AccountNumber || ""}
                                    viewBox={`0 0 256 256`}
                                    fgColor={'#f1c40f'}
                                />

                                <p style={{fontSize: "12px", fontWeight: "bold"}}>Scan the QR code to get receiver address</p>
                            </div>
                        </div>

                    ) : (

                        <div style={{marginBottom:30}}>
                            <div style={{background:'rgba(239,151,151,0.17)',marginRight:30}}>
                                <p style={{padding:10}}>No Payment Method Selected</p>
                            </div>
                        </div>
                    )}

                </div>)}


            </TextWrapper>


            {/*DepositSuccessFul*/}
            {/*<Modal isOpen={isModalOpens}>*/}
            {/*    <DepositSuccessFul onClose={closeModal} amount={formData.amount}*/}
            {/*    />*/}
            {/*</Modal>*/}
        </Container>

    );

}


// withdrawal Form & Cards
export const WithdrawalBanksCard = ({bank}) => {
    const { processingTime, fee, Limit, Image_Icon, session} = bank;
    const [isModalOpen, setIsModalOpen] = useState(false);

    // console.log(type, AccountNumber)
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (

        <div>
            <CardContainer onClick={openModal}>
                <div className='invest-title'>

                    <div style={{display: "flex"}}>
                        {session === "Recommended" ? (
                            <Icon> <img src={maestro} alt="Bitcoin"/></Icon>) : (
                            <Icon>{Image_Icon}</Icon>)}
                        {session === "Recommended" ? (<Title>Crypto</Title>) : (
                            <Title>Cryptocurrency</Title>)}
                            {/*<Title>{name}</Title>)}*/}


                    </div>
                    <p className="Recommended">Recommended</p>

                </div>
                <TextContainer>
                <Description>{processingTime}</Description>
                    <Description>{fee}</Description>
                    <Description>{Limit}</Description>
                </TextContainer>
            </CardContainer>

            <Modal isOpen={isModalOpen} onClose={closeModal} variant={'small'}>
                <BankWithdrawalForm bank={bank}
                                    onClose={closeModal}
                />
            </Modal>
        </div>

    );
};

export const BankWithdrawalForm = ({onClose, bank}) => {
    const [isModalOpens, setIsModalOpens] = useState(false);
    const [updateDetails] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(false)
    const navigate = useNavigate();

    const [selectedBank, setSelectedBank] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const company = getCompanyInfo();

    const {user, Withdrawal} = useAuth();

    const [formData, setFormData] = useState({
        paymentMethod: selectedBank?.name || "",
        amount: "",
        fromAccount: user?.walletNumber,
        toWalletAddress: "",
        paymentOption: "Withdrawal"
    });

    // const OpenSuccess = ()=>{
    //     setIsModalOpens(true)
    // }
    const handleWithdraw = async (e) => {
        e?.preventDefault()
        setLoading(true);
        try {
            await Withdrawal(formData.paymentMethod, formData.amount, formData.fromAccount, formData.toWalletAddress, formData.paymentOption)
                .then((data) => {

                    if (data?.status) {
                        setFormData({
                            paymentMethod: selectedBank?.name || "",
                            amount: "",
                            fromAccount: user?.walletNumber,
                            toWalletAddress: "",
                            paymentOption: "Withdrawal"
                        });

                        //console.log(data, 'we now')

                        return navigate(`/profile/success?amount=${data?.details?.amount}&message=${data?.details?.message}&transactionRef=${data?.details?.transactionRef}&transactionStatus=${data?.details?.transactionStatus}&date=${data?.details?.date}`);
                        // toast.success(data?.message);
                    } else if (data?.message === 'Access Denied') {

                        alert("Login to Continue");
                        return navigate(`/`);

                    } else if(data.error) {

                        //console(data + 'error bbb');

                    }
                })
        } catch (err) {

            console.log(err)
            alert('Oops, something went wrong')

        } finally {
            setLoading(false);
            console.log('finally')
        }
    };


    const handleSelectBank = (bankName) => {
        setFormData({
            ...formData, paymentMethod: bankName?.name
        });
        setSelectedBank(bankName);
        setIsOpen(false);
    };


    const handleChange = async (e, countryName) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };


    const handleSubmit = (e) => {
        if(!selectedBank?.name){
            alert(`Please select a payment method`);
        }else {
            e.preventDefault()
            if (!formData.amount || !formData.toWalletAddress) {
                alert('All fields all required to proceed.');
            } else if (formData.amount && formData.amount < user?.depositLimit) {
                alert(`Minimum Withdrawal must be $100.`);
            } else {
                setSubmitted(true);
                setFormData(prevFormData => ({
                    ...prevFormData, paymentOption: "Withdrawal"
                }));
            }
        }
    };


    if (submitted) {
        return (<Container>
            <WithdrawalConfirmationPage setIsModalOpens={setIsModalOpens} onClose={onClose}
                                        setSubmitted={setSubmitted}
                                        amount={formData.amount}
                                        fromAccount={user?.walletNumber}
                                        paymentOption={formData.paymentOption}
                                        paymentMethod={selectedBank?.name}
                                        walletAddress={formData.toWalletAddress}
                                        handleWithdraw={handleWithdraw} loading={loading}
            />;
        </Container>)
    }

    const closeModals = () => {
        setIsModalOpens(false);
    };


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    const amount = user?.balance;
    const formattedAmount = formatCurrency2(amount);


    return (<Container>
            <FormContainer>
                <h3 className='text' style={{marginBottom:20}} onClick={onClose}>
                    <div style={{background:'#000',height:30,width:30,borderRadius:50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <BiArrowBack className='icon' style={{color:'#fff'}}/>
                    </div>
                </h3>

                <FieldContainer>
                    <FieldLabel>Payment Method:</FieldLabel>
                    <DropdownContainer>
                        <DropdownButton onClick={toggleDropdown}>
                            {selectedBank ? (<>
                                <div className="image">
                                    {selectedBank.image}
                                </div>
                                <div className="text">
                                    {selectedBank.name}
                                </div>
                            </>) : (<>
                                <div className="text">
                                    ----Select----
                                </div>
                            </>)}
                        </DropdownButton>
                        {isOpen && (<DropdownContent>
                            {recommendedWallets?.map((bank, index) => (
                                <CountryItem value={bank.name} key={index}
                                             onClick={() => handleSelectBank(bank)}
                                             style={{cursor: "pointer"}}>
                                    <div className="image">
                                        {bank.image}
                                    </div>
                                    <div className="text">
                                        {bank.name}
                                    </div>
                                </CountryItem>))}
                        </DropdownContent>)}
                    </DropdownContainer>

                </FieldContainer>

                <FieldContainer>
                    <FieldLabel>From account</FieldLabel>
                    <div className="Account">
                        <div>
                            <span className="step-number">ST</span>
                            <span>{user?.walletNumber}</span>
                        </div>
                        <div>{formattedAmount} </div>
                    </div>
                </FieldContainer>

                <FieldContainer>
                    <FieldLabel>{selectedBank.name} Wallet Address</FieldLabel>
                    <input
                        type="text"
                        value={formData.toWalletAddress}
                        name="toWalletAddress"
                        placeholder="21u8dhih21224892fhb93u92h9492489422g"
                        style={{fontSize: "13px",}}
                        onChange={handleChange}
                    />
                </FieldContainer>

                <FieldContainer>
                    <FieldLabel>Amount</FieldLabel>
                    <input
                        type="text"
                        value={formData.amount}
                        name="amount"
                        placeholder="0"
                        style={{fontSize: "15px", fontWeight: "500"}}
                        onChange={handleChange}
                    />
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "5px",
                        flexDirection: "row"
                    }}>
                            <span
                                style={{color: "#000000"}}>Minimum Withdrawal</span><span>({user?.depositLimit} USD)</span>

                    </div>
                </FieldContainer>


                <CardContainers>
                    <CardContent>
                        <Text>
                            Enter the amount you want to withdraw. It should fall within the
                            suggested range and cannot
                            be more than available on your trading account or wallet.
                        </Text>
                    </CardContent>
                </CardContainers>

                <AmountContainer>
                    <p className="text">To be Withdraw</p>
                    {formData.amount === "" ? "0 USD" : (parseFloat(formData.amount) || 0).toLocaleString('en-US', {
                        style: 'currency', currency: 'USD'
                    })}
                </AmountContainer>

                <AuthButton type="submit" onClick={handleSubmit}>
                    <span>Continue</span>
                </AuthButton>

                <TextWrapper>
                    <p>
                        <strong>Terms</strong>
                    </p>
                    <p>Average payment time: 1 hour</p>
                    <p>Fee: 0%</p>
                    <p>
                        <strong>Need Help!</strong>
                    </p>
                    <p>Contact Support Team {company.supportEmail}</p>
                </TextWrapper>

            </FormContainer>


            <CongratsModal isOpen={isModalOpens}>
                <TransferSuccessFul onClose={closeModals}
                                    updateDetails={updateDetails}
                />
            </CongratsModal>
        </Container>

    );

}

// P2P -- Withdrawal form
export const P2PWithdrawalForm = () => {
    const [updateDetails, setUpdateDetails] = useState(null);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpens, setIsModalOpens] = useState(false);
    const {user, verifyWalletNUmber, P2PTransfer} = useAuth();
    const [accountLoading, setAccountLoading] = useState(false)
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [recipientName, setRecipientName] = useState('');

    const company = getCompanyInfo()

    const [formData, setFormData] = useState({
        fromAccount: user?.walletNumber, toAccount: "",
        amount: '', paymentOption: "P2P-withdrawal", paymentMethod: "Transfer",
    });

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeModals = () => {
        setIsModalOpens(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.amount || !formData.toAccount) {
            alert('All fields all required to proceed.');
            return
        }
        if (formData.amount < user?.depositLimit) {
            alert(`Minimum Withdrawal amount must be $100.`);
        } else {
            setIsModalOpen(true);
        }

    };

    const handleTransfer = async () => {
        // e.preventDefault();
        setLoading(true);
        try {
            await P2PTransfer(formData.fromAccount, formData.toAccount, formData.amount, formData.paymentOption, formData.paymentMethod)
                .then((data) => {
                    if (data.message === "Your transfer is successful") {
                        setIsModalOpens(true)
                        setUpdateDetails({
                            amount: data?.details?.amount,
                            message: data?.message,
                            transactionStatus: data?.details?.transactionStatus,
                            transactionRef: data?.details?.transactionRef,
                            date: data?.details?.date,
                        });
                        // toast.success(data?.message);
                    }  else if (data?.message === 'Access Denied') {
                        alert("Login to Continue");
                        return navigate(`/`);
                    } else if(data.error) {
                        alert(data?.error);
                    }

                }).catch((error) => {
                    //console.log("Oops Something Went Wrong Please Try Again")
                    alert("Oops Something Went Wrong Please Try Again");
                })
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors = {};
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                    alert(error.message)
                });
            }
        } finally {
            setLoading(false);
        }
    };


    const handleChange = async (e, countryName) => {
        e.preventDefault();
        const {name, value} = e?.target;
        setFormData({
            ...formData, [name]: value
        });

        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }

        if (name === 'walletNumber' && value.length === 10) {
            setAccountLoading(true)
            try {
                await verifyWalletNUmber(value)
                    .then((data) => {
                        if (data?.status) {
                            const walletNumber = data?.account?.walletNumber;
                            setRecipientName(data?.account?.recipient)
                            setFormData(prevFormData => ({
                                ...prevFormData,
                                toAccount: walletNumber,
                                paymentOption: "P2P_Withdrawal",
                                paymentMethod: "Transfer"
                            }));
                            alert(data?.message);
                        }
                    }).catch((error) => {
                       // console.log("Oops Something Went Wrong Please Try Again")
                        alert("Oops Something Went Wrong Please Try Again");
                    })

            } catch (error) {
                alert(error.message)
                //console.error(error.message);
            } finally {
                setAccountLoading(false)
            }
        }

    };


    const amount = user?.balance;
    const formattedAmount = formatCurrency2(amount);

    return (<Container>
            <FormContainer>
                <FieldContainer>
                    <FieldLabel>From account</FieldLabel>
                    <div className="Account">
                        <div>
                            <span className="step-number">ST</span>
                            <span>{user?.walletNumber}</span>
                        </div>
                        <div>{formattedAmount}</div>
                    </div>
                </FieldContainer>

                <FieldContainer>
                    <FieldLabel>To account</FieldLabel>
                    <input
                        type="text"
                        value={formData.walletNumber}
                        name="walletNumber"
                        onChange={handleChange}
                        maxLength={10}
                        placeholder="0086754679"
                        style={{fontSize: "13px", fontWeight: "500"}}
                    />

                    {accountLoading ? <BeatLoader color="#9FEA99"/> : <span>{recipientName}</span>}
                </FieldContainer>

                <FieldContainer>
                    <FieldLabel>Amount</FieldLabel>
                    <input
                        type="text"
                        value={formData.amount}
                        name="amount"
                        placeholder="0"
                        style={{fontSize: "15px", fontWeight: "500"}}
                        onChange={handleChange}
                    />
                    <span>minimum Amount {user?.depositLimit} USD</span>
                </FieldContainer>


                <CardContainers>
                    <CardContent>
                        <Text>
                            Enter the amount you want to send. It should fall within the
                            suggested range and cannot
                            be more than available on your account wallet.
                        </Text>
                    </CardContent>
                </CardContainers>

                <AmountContainer>
                    <p className="text">To be Withdraw</p>
                    {formData.amount === "" ? "0 USD" : (parseFloat(formData.amount) || 0).toLocaleString('en-US', {
                        style: 'currency', currency: 'USD'
                    })}
                </AmountContainer>

                <AuthButton type="submit"
                    // onClick={handleTransfer}
                            onClick={handleSubmit}

                >
                    {loading ? <Loading/> : <span>Continue </span>}
                </AuthButton>


            </FormContainer>
            <TextWrapper>
                <p>
                    <strong>Terms</strong>
                </p>
                <p>Average payment time: 1 hour</p>
                <p>Fee: 0%</p>


                <p>
                    <strong>Need Help!</strong>
                </p>
                <p>Contact Support Team {company.supportEmail}</p>

            </TextWrapper>

            <Modal isOpen={isModalOpen}>

                <P2PConfirmationPage setIsModalOpens={setIsModalOpens} onClose={closeModal}
                                     setSubmitted={closeModal}
                                     ToAccount={formData.toAccount} amount={formData.amount}
                                     FromAccount={user?.walletNumber}
                                     handleTransfer={handleTransfer}
                                     paymentOption={formData.paymentOption}
                                     paymentMethod={formData.paymentMethod}
                                     recipientName={recipientName}
                />

            </Modal>

            <CongratsModal isOpen={isModalOpens}>
                <TransferSuccessFul onClose={closeModals}
                                    updateDetails={updateDetails}
                />
            </CongratsModal>

        </Container>

    );

}

export const CardContainer = styled.div`
    background-color: #FFFFFF;
    border: 1px solid #E8E9EA;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.0);
    width: 100%;
    margin: 5px;
    display: flex;
    flex-direction: column;
    color: #141D22;

    cursor: pointer;

    .invest-title {
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }

    .session {
        background-color: ${props => props.ClassName === "Recommended" ? '#F0FBF5' : '#FFFCF2'};
        color: ${props => props.session === "Recommended" ? '#7FC95E' : '#A59A90'};
        padding: 8px;
        border-radius: 20px;
        text-align: start;
        font-size: 12px;
    }

    .Recommended {
        background-color: #F0FBF5;
        color: #78d54e;
        padding: 7px;
        border-radius: 20px;
    }

    .Unavailable {
        background-color: #FFFCF2;
        color: #9f8974;
        padding: 7px;
        border-radius: 20px;
    }

    &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
        //width: 90%;
        margin: 10px 0;
        display: flex;
        //justify-content: center;
        color: #141D22;
    }
`;


export const Icon = styled.div`
    width: 40px;
    height: 40px;
    //background-color: #007bff;
    border-radius: 50%;
    border: 3px solid #E8E9EA;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3e4350;
    font-size: 40px;
    //margin-top: 10px;

    img {
        width: 100%;
    }
`;

export const TextContainer = styled.div`
    border-top: 2px solid #E8E9EA;
    width: 100%;
    text-align: start;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.h6`
    font-size: 16px;
    margin: 10px;

`;

export const Description = styled.div`
    font-size: 12px;
    padding: 6px;
`;


// investment Wallet
export const Container = styled.div`
    //display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        color: #141d22;
    }
`;

export const FormContainer = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    padding: 25px;
    width: 100%;


    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;

export const FormContainer2 = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    padding: 25px;
    width: 100%;


    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;

export const Select = styled.select`
    //appearance: none;
    padding: 12px 38px 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    background: #ffffff;
    //background-repeat: no-repeat;
    //background-position: right 12px center;
    background-size: 16px;
    font-size: 16px;

    @media (max-width: 768px) {
        font-size: 13px;
        color: #141d22;
    }

    &:hover {
        cursor: pointer;
        //background-color: #f3f5f7;
    }

    option {
        color: #00C076;
        cursor: pointer;
    }
`;


export const TextWrapper = styled.div`
    //width: 50%;
    color: #1C2127;
  left: 10px;
  //padding-left: 20px;
  //padding-right: 10px;

    p {
        margin-bottom: 10px;
        font-size: 14px;
        color: #1C2127;
    }

    @media (max-width: 768px) {
        p {
            color: #1C2127;
            font-size: 13px;
        }

        width: 100%;

    }
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    .Account {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        border: 1px solid #E2E4E4;
        padding: 13px 20px;
        font-size: 12px;
    }

    .step-number {
        width: 8%;
        height: 8%;
        padding: 5px 5px;
        border-radius: 50%;
        font-size: 12px;
        color: #3CAD7D;
        font-weight: bold;
        background: #E9F6F0;
        margin-right: 8px;
    }


    input {
        width: 100%;
        padding: 13px 20px;
        border: 1px solid #E2E4E4 !important;
        background-color: #FFFFFF !important;
    }

    span {
        font-size: 12px;
        color: #108bad;
        margin-top: 5px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        border: 1px solid #E2E4E4 !important;
        box-shadow: 0 0 0 30px white inset !important; /* Adjust to match your background color */
        -webkit-text-fill-color: inherit !important;
    }

`;

export const FieldLabel = styled.label`
    margin-bottom: 6px;
    font-size: 12px;
`;

export const AuthButton = styled.button`
    padding: 15px 20px;
    width: 100%;
    font-size: 13px;
    background-color: #f3f5f7;
    color: #141d22;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 3rem;

    &:hover {
        background-color: #ffcf01;
    }
`;

export const CardContainers = styled.div`
    background-color: #E8F3FE;
    border: 1px solid #7bbeff;
    border-radius: 8px;
    padding: 10px;

    @media (max-width: 768px) {
        min-width: 100%;
        color: #141D22;
        font-size: 13px;
    }
`;


export const CardContent = styled.div`
    padding: 6px;
    color: #141D22;
`;

export const Text = styled.div`
    font-size: 13px;
    line-height: 1.5;
    color: #141D22;
`;

export const AmountContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 13px 20px;
    background-color: #F8F9F9;
    border-radius: 5px;

    margin-top: 2rem;

    .amount {
        font-weight: 600;
        font-size: 15px;
    }

    .text {
        font-size: 13px;
    }
`
