import {Wrapper} from "./Account";
import Tab, {MainContainer} from "../TABS/Tabs";
import React from "react";
import {PortFolio, PortFolioCopy} from "../TABS/portFolio/portfolio";
import withLoader from "../../../Loader/WithLoader";
import 'react-toastify/dist/ReactToastify.css';


const PortfolioManagement = () => {

    const tabs = [{
        title: "Overview", content:

            <MainContainer>
                <h2>Choose a product</h2>
                <PortFolioCopy/>
            </MainContainer>

    }, {
        title: "Product specification", content: <MainContainer>
            <PortFolio/>
        </MainContainer>
    },]

    return (


        <main className='main-container'>
            <Wrapper>
                <div className='title'>
                    <div>
                        <h4 style={{color:'#000'}}>Welcome to Portfolio Management Solution</h4>
                        <p style={{color:'#000'}}>Access all the tools you need to manage investors and maximize your performance</p>

                    </div>
                </div>
                <Tab tabs={tabs}/>
            </Wrapper>
        </main>)
}


// export default PortfolioManagement
export default withLoader(PortfolioManagement);
