import React, {useState, useRef} from 'react';
import styled from "styled-components";
//import {toast} from "react-toastify";
import * as Yup from "yup";
import {useAuth} from "../../contexts/AppContext";
// import {FormValidationSchema} from "../../validation/validation";
import Loading from "../../Loader/Loading";
import {useNavigate} from "react-router-dom";
import {MdVerifiedUser} from "react-icons/md";
//import {ProfileUpdate} from "./UpdateProfile";
import {Container, TextWrapper} from "../ProfileDashboard/TABS/Deposit&WithdrawCard";
//import {Label} from "recharts";

const FileUploader = ({setIsModalOpen, user}) => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [holdImageUrl, setHoldImageUrl] = useState('')
    const fileInputRef = useRef(null)
    const handleImgClick = () => {
        fileInputRef.current.click(); // Trigger click on file input
    };

    const {UpLoadDocument} = useAuth();

    const handleFileInputChange = (event) => {
        //console.log(event,"event")
        const file = event.target.files?.[0];
        setFile(file);
        console.log(file, "file formData")
        if (file) {
            setFile(file);
            const imageUrl = URL.createObjectURL(file);
            setHoldImageUrl(imageUrl);
        }
    };


    const handleUpLoad = async (e) => {
        e.preventDefault();
        setLoading(true);


        try {
            // await FormValidationSchema.validate(fileData);
            await UpLoadDocument(file)
                .then((data) => {
                    //console.log(data,"data")
                    if (data.message === "Access Denied") {
                        return navigate(`/`);
                    } else if (data.status) {
                        //console.log(data, "upload successful")
                        alert(data?.message);
                        setIsModalOpen(false)
                        setHoldImageUrl('')
                    } else {
                        //console.log(data.message, "error message")
                        alert(data?.message);
                    }
                })
                .catch((error) => {
                    //console.log(error)
                    alert("Oops Something Went Wrong Please Try Again");
                })
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                alert(error.message);
            }
        } finally {
            setLoading(false);
            // setIsModalOpen(false)

        }
    };


    return (<div>
        {/*<ToastContainer/>*/}
        <ProfileUpdateContainer>
            <ProfileUpdateForm>


                <Container>
                    <TextWrapper>
                        {user?.verification?.meansOfIdentification ? (
                            <div className="verification_form">

                                <div style={{
                                    textTransform: "capitalize",
                                    fontWeight: "bold",
                                    letterSpacing: "5px",
                                    fontSize: "15px",
                                    color: "#ffff",
                                    background: user?.verification?.meansOfIdentificationAdmin && user?.verification?.meansOfIdentificationAdmin ? "#00C076" : "red",
                                    padding:10,
                                    width:'100%'
                                }}>Document Uploaded  <MdVerifiedUser/> </div>

                                {
                                    user?.verification?.meansOfIdentificationAdmin && user?.verification?.meansOfIdentificationAdmin ?
                                        <p style={{fontSize:"12px", color: "#00C076", marginTop: "1rem", textTransform:"capitalize"}}>
                                            Your Document is now approved</p>
                                        :
                                        <p style={{fontSize:"12px", color: "red", marginTop: "1rem", textTransform:"capitalize"}}>
                                            Your Document is under review now</p>
                                }

                            </div>
                        ) : (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {/* Main image */}

                                    <img
                                        src={holdImageUrl ? holdImageUrl : require('../../assets/images/no_image_resized_675-450.jpg')}
                                        alt="img"
                                        style={{ cursor: 'pointer', width: 200, height:200, position: 'relative' }}
                                        onClick={handleImgClick}
                                    />

                                    {/* Second image (hidden) */}
                                    <div style={{ position: 'absolute', top: '65%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
                                        <img
                                            src={require('../../assets/images/icons/file-upload-1.png')}
                                            alt="img"
                                            style={{ cursor: 'pointer', width: '70px' }}
                                            onClick={handleImgClick}
                                        />
                                    </div>

                                    {/* Hidden file input */}
                                    <InputField
                                        ref={fileInputRef}
                                        type="file"
                                        accept=".png, .jpeg, .jpg"
                                        onChange={handleFileInputChange}
                                        style={{ display: 'none' }} // Hide the input visually
                                        required
                                    />
                                </div><br/>

                                {/* Button Container */}
                                <ButtonContainer>
                                    <SubmitButton type="button" onClick={handleUpLoad}>
                                        {loading ? <Loading/> : <> Upload Document </>}
                                    </SubmitButton>
                                </ButtonContainer>
                            </>
                        )}
                    </TextWrapper>

                </Container>





            </ProfileUpdateForm>
        </ProfileUpdateContainer>
    </div>);
};


const ProfileUpdateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1rem;
    text-align: start;
    width: 100%;

    h2 {
        font-size: 20px;
        margin-bottom: 1rem;
    }

    p {
        font-size: 12px;
    }
`;

const ProfileUpdateForm = styled.div`
    width: 100%;
    //max-width: 400px; /* Set maximum width for larger screens */
`;

const InputField = styled.input`
    //border: 1px solid #558c35;
    background: transparent;
    outline: none;
    color: #1C2127;
    font-size: 12px;
    //border-radius: 5px;
    //padding: 20px 20px;
    width: 100%;
  display: none;
`;


const ButtonContainer = styled.div`
    //margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-size: 12px;
        color: #B9A62CFF;
        cursor: pointer;
    }
`


const SubmitButton = styled.button`
    padding: 10px 20px;
    background-color: #FFCF01;
    color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;


export default FileUploader;
