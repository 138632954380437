import React from "react"
import Layout from "../../components/ProfileDashboard/SideBarLayout";
import TransactionHistorys from "../../components/ProfileDashboard/SideBarPages/TransactionHistorys";
///import {useAuth} from "../../contexts/AppContext";


const Transactions = () => {

    return (<Layout>
        <TransactionHistorys />
    </Layout>)

}

export default Transactions
