import React, {useEffect} from "react";
// import styles from './organisms.module.css'
// import styles from '../../organisms/organisms.module.css'

const TradeChart = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.async = true;
        script.onload = () => {
            new window.TradingView.widget({
                "width": "100%",
                "height": 665,
                "symbol": "BTCUSD",
                "interval": "1",
                "timezone": "Etc/UTC",
                "theme": "Dark",
                "style": "1",
                "locale": "en",
                "toolbar_bg": "#FFCF01",
                "enable_publishing": false,
                "withdateranges": true,
                "hide_side_toolbar": false,
                "allow_symbol_change": true,
                "details": true,
                "calendar": true,
                "container_id": "tradingview_474a9",
            });
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <div className="tradingview-widget-container">
                <div id="tradingview_474a9"></div>
                <div className="tradingview-widget-copyright">
                    {/* TradingView script will be appended here */}
                </div>
            </div>
        </div>

    );
}

export default TradeChart;
