import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useNavigate, useLocation} from "react-router-dom";
import success from "../../assets/firework.png"
import getCompanyInfo from "../../Utilities/companyInfo";
//import {useAuth} from "../../contexts/AppContext";


const CongratulationsScreen = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5);
    //const {loggedIn} = useAuth();

    const company = getCompanyInfo();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");



    useEffect(() => {
        //console.log("Email passed to congratulation:", email);
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        if (countdown === 0) {
            clearInterval(timer);
            navigate('/profile');
        }

        return () => clearInterval(timer);
    }, [countdown, navigate, email]);


    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

    const handleSubmit = () => {
        alert(`Welcome to ${company.name}, the future Forex trading community! Step into a world of global markets and endless possibilities. Whether you're a seasoned trader or just starting out, we're thrilled to have you here. Let's navigate the exciting world of currency exchange together. Happy trading!`);
            return navigate('/profile')
    }

    return (
        <div className="auth-container__form">
            <Title>Congratulation!</Title>
            <span>{email}</span>
            <Container>
                <img src={success} alt="success" width={120}/>

            </Container>
            <Message>
                Congratulations! Your registration is successful. Welcome to our
                community!
            </Message>


            <button type="button" onClick={handleSubmit}>Go to Dashboard</button>
            <Timer>

                {`${minutes.toString().padStart(2, '0')}:${seconds
                    .toString()
                    .padStart(2, '0')}`}
            </Timer>
        </div>
    );
};

export default CongratulationsScreen;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
`;


const Title = styled.h1`
    font-size: 20px;
    color: #333333;
    //margin-bottom: 20px;
`;

const Message = styled.p`
    font-size: 16px;
    color: #555555;
    margin-bottom: 40px;
`;


const Timer = styled.p`
    font-size: 11px;
    color: #555555;
    margin-bottom: 20px;
    display: flex;
    justify-content: end;
`;
