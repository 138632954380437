import { useState } from 'react';

const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });


    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            // localStorage.setItem('key', JSON.stringify(valueToStore) );
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error(error);
        }
    };


    // const setValue = (value) => {
    //     try {
    //         const valueToStore = value instanceof Function ? value(storedValue) : value;
    //         setStoredValue(valueToStore);
    //         // window.localStorage.setItem(key, JSON.stringify(valueToStore));
    //     } catch (error) {
    //         if (error.name === 'QuotaExceededError') {
    //             console.error('Local storage quota exceeded:', error);
    //             // Handle quota exceeded error
    //             // clearLocalStorageAndLogout();
    //         } else {
    //             console.error('Error setting local storage:', error);
    //         }
    //     }
    // };
    //

    const clearLocalStorageAndLogout = () => {
        try {
            // Clear all items from local storage
            window.localStorage.clear();
            // Log out the user (you can define your logout logic here)
            console.log('User logged out due to local storage quota exceeded');
        } catch (error) {
            console.error('Error clearing local storage and logging out:', error);
        }
    };

    return [storedValue, setValue];
};

export default useLocalStorage;
