import React, {useEffect, useState} from 'react'
//import Tabs from '../components/molecules/tabs'
import Header from "../components/ProfileDashboard/Header";
import {useAuth} from "../contexts/AppContext";
import {useNavigate} from "react-router-dom";
//import {FetchOrderBook, FetchTicker} from "../api/fetchApi";
import TradeHistory from "../components/organisms/TradeHistory";
import TradeChart from "../components/ProfileDashboard/SideBarPages/TradeChart";
//import Sidebar from "../components/ProfileDashboard/SideBar";
import DashboardSidebar from "../components/ProfileDashboard/dashboardSideBar";


// const tabs = [{title: "Chart", active: true}, {
//     title: "Order book", active: false
// }, // { title: "Recent Trades", active: false }
// ]


function Dashboard() {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    //const [activeTab, setActiveTab] = useState(tabs)
    //const [tabContent, setTabContent] = useState(tabs[0].title)


    const {user, token} = useAuth();
    const navigate = useNavigate();

    const [symbol, ] = useState("BTCUSDT")
    //const [pairTicker, setPairTicker] = useState({})
    // const [orderBookData, setOrderBookData] = useState({
    //     lastUpdateId: 0, bids: [], asks: []
    // });


    const getSymbolTicker = async () => {
        try {
            //const data = await FetchTicker(symbol)
            //const newBook = await FetchOrderBook(symbol)
            // console.log(data)
            //setPairTicker(data || "")
            //setOrderBookData(newBook);
        } catch (error) {
            console.error(error)
        }
    }


    useEffect(() => {
        getSymbolTicker().then(data => {
            console.log(data)
        })

    }, [symbol])

    useEffect(() => {
        if (!token) {
            navigate('/')
        }
    }, [token, navigate]);


    // const handleClick = (index) => {
    //     const updatedList = tabs.map((item, i) => ({
    //         ...item, active: i === index //this sets the tab active to true and sets others to false
    //     }))
    //     setActiveTab(updatedList)
    //     setTabContent(updatedList[index].title)
    // }

    // const showContent = {
    //     display: 'flex'
    // }
    // const HideContent = {
    //     display: 'none'
    // }



    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    //    background-color: #121521;

    return (

        <div className="dashboard_body">
        {/*<Navbar/>*/}
            <Header user={user} OpenSidebar={OpenSidebar}/>
            <div className="grid-container" style={{background:'#000000'}}>
                <DashboardSidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
                <main className='dash-container'>
                    <TradeChart/>
                </main>
            </div>


            <section className="section">

                <TradeHistory/>

                {/*<div className="section__extended">*/}
                {/*    <div className="extended">*/}
                {/*        <div className="tradingview">*/}
                {/*            /!* <!-- chart session --> *!/*/}
                {/*            <div className='deskhide'>*/}
                {/*                <Tabs tabs={activeTab} handleClick={handleClick}/>*/}
                {/*            </div>*/}
                {/*            /!* <Orderbook style={tabContent === tabs[1].title ? showContent : null}*/}
                {/*                       orderBookData={orderBookData} pairTicker={pairTicker}/> *!/*/}

                {/*            /!* <Chart style={tabContent === tabs[1].title ? HideContent : null}/> *!/*/}
                {/*            /!* <TradeChart/> *!/*/}

                {/*            /!*<BuyAndSell/>*!/*/}
                {/*        </div>*/}
                {/*        <TradeHistory/>*/}
                {/*    </div>*/}
                {/*    /!*<BuyAndSell/>*!/*/}

                {/*</div>*/}
            </section>
        {/*<Footer/>*/}
    </div>


            )
}

export default Dashboard
