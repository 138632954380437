import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
//import {UpdateUSerSchema} from "../../validation/validation";
import 'react-toastify/dist/ReactToastify.css';
import {toast } from 'react-toastify';
//import * as Yup from "yup";
import {useAuth} from "../../contexts/AppContext";
import Loading from "../../Loader/Loading";
import {FaUserCircle} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import getCompanyInfo from "../../Utilities/companyInfo";

const ProfileUpdateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1rem;
    text-align: start;
    width: 100%;

    h2 {
        font-size: 20px;
        margin-bottom: 1rem;
    }

    p {
        font-size: 12px;
    }
`;

const ProfileUpdateForm = styled.div`
    width: 100%;
    //max-width: 400px; /* Set maximum width for larger screens */
`;

const InputField = styled.input`
    border: 1px solid #A7B1BC;
    background: transparent;
    outline: none;
    color: #1C2127;
    font-size: 12px;
    border-radius: 3px;
    padding: 13px;
    margin-top: 1rem;
    width: 100%;
`;

const ButtonContainer = styled.div`
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-size: 12px;
        color: #B9A62CFF;
        cursor: pointer;
    }
`


const SubmitButton = styled.button`
    padding: 10px 20px;
    background-color: #FFCF01;
    color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

export const ProfileUpdate = ({onCancel}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const {updateUserProfile, user} = useAuth();

    const [formData, setFormData] = useState({
        firstName: '', lastName: '', phone: ''
    });

    const company = getCompanyInfo()

    useEffect(() => {
        if (user && user?.firstName && user?.lastName && user?.phone) {
            const userData = {
                firstName: user?.firstName, lastName: user?.lastName, phone: user?.phone,
            };
            setFormData(prevFormData => ({
                ...prevFormData, ...userData
            }));
        }
    }, [user]);

    const handleUpdate = async () => {
        // e.preventDefault();
        setLoading(true);
       // try {
            //await UpdateUSerSchema.validate(formData, {abortEarly: false});

            if(!formData.firstName){
                toast.info("Please firstname is required");

            }else {

                if(!formData.lastName){

                    toast.info("Please lastname is required");

                }else {

                    if(!formData.phone){

                        toast.info("Please phone number is required");

                    }else {


                        await updateUserProfile(formData.firstName, formData.lastName, formData.phone)
                            .then((data) => {
                                if (data.status === false) {
                                    return navigate(`/`);
                                } else if (data.status === true) {
                                    toast.success(data.message);
                                } else {
                                    toast.error(data.message);
                                }
                            })
                            .catch((error) => {
                                console.log("Oops Something Went Wrong Please Try Again")
                                toast.error("Oops Something Went Wrong Please Try Again");
                            })

                    }


                }


            }

        // } catch (error) {
        //     toast.error("All input fields are required");
        //     // if (error instanceof Yup.ValidationError) {
        //     //     const validationErrors = {};
        //     //     error.inner.forEach(error => {
        //     //         validationErrors[error.path] = error.message;
        //     //         toast.error(error.message)
        //     //     });
        //     // }
        // } finally {
        //     setLoading(false);
        // }
    };


    const handleChange = (e, countryName) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };


    return (
<>
    {/*<ToastContainer/>*/}
    <ProfileUpdateContainer>
        <h2><FaUserCircle/> Profile Update </h2>
        <p>Provide Information: Enter the required details accurately to confirm your
            identity.</p>
        <p> This may include your name, email phone number, and any additional information as
            prompted.</p>

        <ProfileUpdateForm>

            <InputField type="text"
                        placeholder="First Name"
                        name="firstName"
                        value={formData?.firstName}
                        onChange={handleChange}

                        required/>
            {errors.firstName && !formData.firstName &&
                <div className="error">{errors.firstName}</div>}


            <InputField type="text"
                        placeholder="Last Name"
                        name="lastName"
                        value={formData?.lastName}
                // defaultValue={user.lastName}
                        onChange={handleChange}
                        required/>
            {errors.lastName && !formData.lastName &&
                <div className="error">{errors.lastName}</div>}


            <InputField type="text"
                        placeholder="Phone Number"
                        name="phone"
                        value={formData?.phone}
                        onChange={handleChange}
                        required/>
            {errors.phone && !formData.phone && <div className="error">{errors.phone}</div>}

            <p style={{color: "red", marginTop:"1rem", textTransform:"capitalize"}}>Profile updates is a one-time action and cannot be
                changed. Please contact customer care for any help {company.supportEmail}. </p>
            <ButtonContainer>

                <SubmitButton type="button" disabled={loading} onClick={handleUpdate}>
                    {loading ? <Loading/> : <> Update Profile</>}
                </SubmitButton>
            </ButtonContainer>
            {/*<SubmitButton type="submit" onClick={onCancel}>Update Profile</SubmitButton>*/}
        </ProfileUpdateForm>
    </ProfileUpdateContainer>
</>


    );
};



