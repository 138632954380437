import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FaRegUserCircle} from "react-icons/fa";
import Modal from "./TABS/Modal";
import ProfileUpdateAccordion from "./TABS/SettingProfile/ProfileUpdateAccordion";
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../contexts/AppContext";
//import { ToastContainer } from 'react-toastify';

const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 20px 20px;
    background-color: #FFF9EB;
    color: #1C2127;
    //z-index: 99;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem 5rem;
    margin-bottom: 0.3rem;
    position: relative;
    text-transform: capitalize;
    @media (max-width: 768px) {
        padding: 1rem 1rem;
        display: block;
        .btn {
            margin-top: 1rem;
            //display: none;
        }
    }

`;

const LeftContent = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
`;

const UserIcon = styled.div`
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #E8E9EA;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3e4350;
    font-size: 28px;
`;

const Button = styled.button`
    background-color: #F3EFE5;
    color: #1C2127;
    font-size: 13px;
    padding: 10px 35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #FFDE02;
    }

    &:not(:last-child) {
        margin-right: 10px;
    }
`;
const Button1 = styled.button`
    background-color: #FFDE02;
    color: #1C2127;
    font-size: 13px;
    padding: 10px 35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #F3EFE5;
    }

    &:not(:last-child) {
        margin-right: 15px;
    }
`;

const SubHeader = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    function extractUsername(email) {
        // Find the position of '@' symbol
        let atIndex = email.indexOf('@');
        if (atIndex !== -1) {
            // Extract the substring before '@'
            let username = email.substring(0, atIndex);
            return username;
        } else {
            // If no '@' symbol found, return the whole email
            return email;
        }
    }

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const {user} = useAuth();



    return (<>
            {/*<ToastContainer/>*/}
            <HeaderContainer>
                <LeftContent>
                    <UserIcon>
                        <FaRegUserCircle/>
                    </UserIcon>
                    <div>Hello <b>{extractUsername(user.email)}</b>, Fill in your account details to add more security to your account </div>
                </LeftContent>

                <div className="btn">
                    <Button className='btn1'>Learn more</Button>
                    <Button1 className='btn2' onClick={openModal}>Complete profile</Button1>
                </div>

                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <div style={{padding: "1rem"}}>
                        <h2 style={{margin: "1rem", fontSize: "20px"}}> Account Verification</h2>
                        <p style={{fontSize: "13px"}}>Your security is our top priority, and
                            completing</p>
                        <p style={{fontSize: "13px"}}> this process ensures the safety and integrity
                            of your
                            information.</p>
                        <ProfileUpdateAccordion/>
                    </div>

                </Modal>
            </HeaderContainer>
        </>


    );
};


export default SubHeader;
