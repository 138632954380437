import React from "react"
//import {useAuth} from "../../contexts/AppContext";
import DW_Success from "../../components/ProfileDashboard/TABS/TransactionHistory/D&W_SuccessPage";
import styled from "styled-components";
//import {useNavigate} from "react-router-dom";
//import {IoArrowBackCircleOutline} from "react-icons/io5";


const Success = () => {
    //const {user} = useAuth();
    //const navigate = useNavigate();

    // const Navigate = () => {
    //     navigate("/profile");
    // }

    return (

    <Container>
        {/*<IoArrowBackCircleOutline style={{fontSize: "25px", cursor: "pointer"}} onClick={Navigate}/>*/}
        <br/>
        <DW_Success/>
    </Container>)

}


const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem;


    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: start;
        //margin: 1rem;
    }



`

export default Success
