import styled from "styled-components";
import React, {useState} from "react";
import Pagination from "../../../../Loader/Pagination";
//import {FaArrowCircleDown, FaArrowCircleUp} from "react-icons/fa";
// import ProgressBar from "../../ProgressBar";
import { formatCurrency2} from "../formatCurrency";
import ProgressBar from "../TransactionHistory/ProgressBar";
import {HiMiniCog} from "react-icons/hi2";


export const DashboardInvestmentHistory = ({investmentHistory}) => {
    const [currentPage, setCurrentPage] = useState(1);

    const PAGE_SIZE = 6;
    const totalPages = Math.ceil(investmentHistory.length / PAGE_SIZE);


    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, investmentHistory.length);


    //const transactions = Array?.isArray(investmentHistory) ? investmentHistory : [];
    // const currentTransactions = transactions?.slice(startIndex, endIndex)?.reverse();

    const reversedTransactionHistory = investmentHistory.slice().reverse();
    const currentTransactions = reversedTransactionHistory.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    //const amount = currentTransactions[0]?.amount;
    //const formattedAmount = formatCurrency(amount);
    //const formattedProfit = formatCurrency(currentTransactions[0]?.returnOnInvestment);


    return (
        <div>
            <TransactionContainer>
            {currentTransactions?.map((transaction, index) => (
                    <Transaction key={index}>

                        {transaction?.withdrawalStatus === "Unpaid" ? (<TransactionImage>
                            <HiMiniCog style={{fontSize: "16px", color: "red"}}/>
                        </TransactionImage>) : (<TransactionImage>
                            <HiMiniCog style={{fontSize: "16px", color: "green"}}/>
                        </TransactionImage>)}
                        <TransactionDetails>

                            <TransactionTitle>{transaction?.investmentPlan}</TransactionTitle>
                            <WalletAddress> <ProgressBar startDate={transaction?.startDate}
                                                         endDate={transaction?.expirationDate} color={'#fff'}/>
                            </WalletAddress>
                            <WalletAddress>Profit: <span
                                className="profit">{formatCurrency2(transaction?.returnOnInvestment)}</span></WalletAddress>

                            <WalletAddress>Started: <span style={{color:'#fd7f02'}}>{transaction?.startDate} </span> | Ends: <span className="profit">{transaction?.expirationDate} </span></WalletAddress>

                            {transaction?.withdrawalStatus === "Unpaid" ? (<WalletAddress> Status: <span style={{textTransform:'capitalize',marginTop:10,color:'red'}}
                                className="statusRed">{transaction?.withdrawalStatus} </span></WalletAddress>) : (
                                <WalletAddress> Status: <span style={{textTransform:'capitalize',marginTop:10,color:'green'}}
                                    className="status">{transaction?.withdrawalStatus}</span></WalletAddress>)}
                        </TransactionDetails>
                        <div>

                            <TransactionAmount> {transaction?.duration} Days</TransactionAmount>
                            <TransactionAmount> <span
                                className="status"> -{formatCurrency2(transaction?.amount)}</span></TransactionAmount>

                        </div>
                    </Transaction>))}
            </TransactionContainer>

            <Pagination currentPage={currentPage} totalPages={totalPages}
                        onPageChange={handlePageChange}/>
        </div>)
}


const TransactionContainer = styled.div`
    width: 100%;
    background-color: #121521;

`;

const Transaction = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;

    @media (max-width: 768px) {
        //flex-direction: column;
        align-items: flex-start;
        color: #ffffff;
    }
`;

const TransactionImage = styled.div`
    border-radius: 50%;
    margin-right: 20px;
    @media (max-width: 768px) {
        margin-right: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
`;

const TransactionDetails = styled.div`
    flex-grow: 1;
    margin-right: 20px;
`;

const TransactionTitle = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
    color: #00C076;
    @media (max-width: 768px) {
        color: #ffffff;
        margin-top: 6px;
    }
`;

const WalletAddress = styled.div`
    color: #ffffff;
    font-size: 13px;
    margin-top: 8px;
  margin-bottom: 20px;

    .profit {
        text-transform: lowercase;
        color: #00C076;
        font-weight: bold;
    }

    .status {
        border: 1px solid #00C076;
        padding: 3px 10px;
        border-radius: 10px;
        text-transform: lowercase;
    }

    .statusRed {
        border: 1px solid #c20b1a;
        padding: 3px 10px;
        border-radius: 10px;
        text-transform: lowercase;
    }

    @media (max-width: 768px) {
        color: #ffffff;
        margin-top: 6px;
    }
`;

const TransactionAmount = styled.div`
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
    margin-top: 6px;
    justify-items: flex-end;
    text-align: end;


    .status {
        text-transform: lowercase;
        color: #ff2d55;
    }

    @media (max-width: 768px) {
        color: #ffffff;
        margin-top: 6px;
    }
`;
