import React from 'react';
import styled from 'styled-components';
import getCompanyInfo from "../../Utilities/companyInfo";

const FooterContainer = styled.footer`
    background-color: #F8F9F9;
    text-align: start;
    //position: relative;
    padding-left: 7rem;
    padding-right: 7rem;
    @media (max-width: 768px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
`;

const FooterText = styled.div`
    color: #777;
    font-size: 11px;
    margin-bottom: 10px;

    .FooterText-p {
        color: #1C2127;
        padding-top: 1.2rem;
    }

    .FooterText-pp {
        padding-top: 1.2rem;
    }

    @media (max-width: 768px) {
        font-size: 11px;
    }
`;

const FooterLink = styled.a`
    color: #007bff;
    text-decoration: none;
    margin: 0 5px;
`;

const Footer = () => {
    const company = getCompanyInfo();
    return (<FooterContainer>
        <FooterText>
            <p className='FooterText-p'>
                {company.name} offer services to residents of certain jurisdictions including the USA, Iran, North Korea,
                the European Union, the United Kingdom and others. The content of the website including translations
                should not be construed as means for solicitation. Investors make their own and independent decisions.
            </p>
            <p className='FooterText-p'>

                Trading in CFDs and generally leveraged products doesn't involve substantial risk of loss and you have
                0% chances of losing your invested capital.
            </p>
            <p className='FooterText-pp'>{company.name} (SC) Ltd is a Securities Dealer registered in Seychelles and
                authorised by the Financial Services Authority (FSA) The registered office of {company.name} (SC) Ltd is at
                9A CT House, 2nd floor, Providence, Mahe, Seychelles.</p>

            <p className='FooterText-pp'>{company.name} B.V. is a Securities Intermediary registered in Curaçao and
                authorised by the Central Bank of Curaçao and Sint Maarten (CBCS)</p>


            <p className='FooterText-pp'>{company.name} (VG) Ltd is authorised by the Financial Services Commission (FSC)
                in BVI
                The entities above are duly authorised to operate under the {company.name} brand and trademarks
            </p>
            <p className='FooterText-pp'>
                The information on this website may only be copied with the {company.name} written permission of
                {company.name}.</p>

            <p className='FooterText-pp'>{company.name} complies with the Payment Card Industry Data Security Standard (PCI
                DSS) to ensure your security and privacy. We conduct regular vulnerability scans and penetration tests
                in accordance with the PCI DSS requirements for our business model.</p>


            <p className='FooterText-pp'>The information on this website does not constitute investment advice or a
                recommendation or a solicitation to engage in any investment activity.</p>
            <p className='FooterText-pp'>The information on this website may only be copied with the express written
                permission of {company.name}.</p>

            <p className='FooterText-pp'></p>
        </FooterText>


        <FooterText>
            <FooterLink target={'_blank'} href={company.terms}>Terms & Conditions</FooterLink>
            <FooterLink target={'_blank'} href={company.privacy}>Privacy & Policy</FooterLink>
            {/*<FooterLink href="#">Risk disclosure</FooterLink>*/}
            {/*<FooterLink href="#">Preventing money laundering</FooterLink>*/}
            {/*<FooterLink href="#">Security instructions</FooterLink>*/}
            {/*<FooterLink href="#">Legal documents</FooterLink>*/}
        </FooterText>
        <FooterText>
            ©️ {company.year} . {company.name}
        </FooterText>
    </FooterContainer>);
};

export default Footer;
