import React from 'react'
import {Link} from "react-router-dom";
import {BsGrid1X2Fill} from "react-icons/bs";
import {TbCircleArrowDown, TbPresentationAnalytics, TbSettings2} from "react-icons/tb";
import {PiArrowCircleUpRightBold} from "react-icons/pi";
import {MdHistory} from "react-icons/md";
import {LuWallet} from "react-icons/lu";
import {GoBriefcase} from "react-icons/go";
import 'react-toastify/dist/ReactToastify.css';

const Sidebar = ({openSidebarToggle}) => {
    // const Sidebar = ({openSidebarToggle, OpenSidebar}) => {

    return (

        <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
            {/*<div className='sidebar-title'>*/}
            {/*    <IoMdCloseCircleOutline className='icon close_icon' onClick={OpenSidebar}/>*/}
            {/*</div>*/}

            <ul className='sidebar-list'>
                <Link to="/profile">
                    <li className='sidebar-list-item'>
                        <BsGrid1X2Fill className='icon'/>
                        My accounts

                    </li>
                </Link>

                <Link to="/profile/deposit">
                    <li className='sidebar-list-item'>
                        <TbCircleArrowDown className='icon'/>
                        Deposit
                    </li>
                </Link>

                <Link to="/profile/withdrawal">
                    <li className='sidebar-list-item'>
                        <PiArrowCircleUpRightBold className='icon'/>
                        Withdrawal

                    </li>
                </Link>

                <Link to="/profile/transactions">
                    <li className='sidebar-list-item'>

                        <MdHistory className='icon'/>
                        Transaction history
                    </li>
                </Link>

                <Link to="/profile/investment">
                    <li className='sidebar-list-item'>
                        <LuWallet className='icon'/>
                        Investment
                    </li>
                </Link>

                <Link to="/profile/setting">
                    <li className='sidebar-list-item'>
                        <TbSettings2 className='icon'/>
                        Settings
                    </li>
                </Link>


                <Link to="/dashboard">
                    <li className='sidebar-list-item'>
                        <TbPresentationAnalytics className='icon'/>
                        Trade Analytics
                    </li>
                </Link>
                <Link to="/profile/portfolio">
                    <li className='sidebar-list-item'>
                        <GoBriefcase className='icon'/>
                        Portfolio management

                    </li>
                </Link>
            </ul>

        </aside>)
}

export default Sidebar