import xrp from "../assets/banks/xrp-text-mark-black-1.png";
import tetherSvg from "../assets/banks/tether.svg";
import xlm from "../assets/banks/xlm.svg";
import BitcoinCash from "../assets/banks/bitcoin-sv-1.svg";
import bitcoin from "../assets/banks/bitcoin.svg";
import binance from "../assets/banks/binance.png";
import binance_wallet from "../assets/banks/binance_wallet.png";
import litecoin from "../assets/banks/litecoin.svg";
import dash from "../assets/banks/dash-5.svg";
import tron from "../assets/banks/tron.svg";
import Ethereum from "../assets/banks/ethereum.svg";
import shib from "../assets/banks/shibaInu.svg";
import apple from "../assets/banks/apple-card.svg";
import maestro from "../assets/banks/monero.png";
import dogecoin from "../assets/banks/dogecoin.svg";
import bankCard from "../assets/banks/bank-card.png";


const walletData = [
    {
        "crypto": {
            "wallets": [
                {
                    "name": "Bitcoin (BTC)",
                    "AccountNumber": "bc1qg8hlwh0qcz7njr9h6329q22knm83tka33g392x",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "btc",
                    "image": <img src={bitcoin} alt="Bitcoin (BTC)"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={bitcoin} alt="Bitcoin (BTC)"/>
                },
                {
                    "name": "Ethereum",
                    "AccountNumber": "0x2835c3A00Eebd6ee37B52854De829Af87eC6e5fe",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "eth",
                    "image": <img src={Ethereum} alt="Ethereum"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={Ethereum} alt="Ethereum"/>
                },
                {
                    "name": "Tron",
                    "AccountNumber": "TDXucuGuGDqXL4A982JZP88dAe9r8oEdsn",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "tron",
                    "image": <img src={tron} alt="Tron"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={tron} alt="Tron"/>
                },
                {
                    "name": "XLM",
                    "AccountNumber": "GBKZZYSKEQMKNJMXEMET2KVXID2XM2NHGFBW4H2A7H53XIP6RAPLAEOH",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "xlm",
                    "image": <img src={xlm} alt="xlm"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={xlm} alt="xlm"/>
                },
                {
                    "name": "XRP",
                    "AccountNumber": "rBGLYUyFX8cJoTxJ6FQr1KXxXTm35aY73Q",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "xrp",
                    "image": <img src={xrp} alt="XRP"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={xrp} alt="XRP"/>
                },
                {
                    "name": "Bitcoin Cash",
                    "AccountNumber": "qqxajnlwuxa3r90682kwljuvuchuuhmffczy92rurt",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "btccash",
                    "image": <img src={BitcoinCash} alt="Bitcoin Cash"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={BitcoinCash} alt="Bitcoin Cash"/>
                },
                {
                    "name": "BNB",
                    "AccountNumber": "0x2835c3A00Eebd6ee37B52854De829Af87eC6e5fe",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "bnb",
                    "image": <img src={binance_wallet} alt="BNB"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={binance_wallet} alt="BNB"/>
                },
                {
                    "name": "Litecoin",
                    "AccountNumber": "ltc1qmtns22rdhcjjfvdm5euxhzg066v842g73phe4x",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "litecoin",
                    "image": <img src={litecoin} alt="Litecoin"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={litecoin} alt="Litecoin"/>
                },
                {
                    "name": "Dash Coin",
                    "AccountNumber": "XweWDzyxL5EMU6wnsR1ubmc4Gw2HZRjHVC",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "dash",
                    "image": <img src={dash} alt="Dash Coin"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={dash} alt="Dash Coin"/>
                },
                {
                    "name": "USDT ERC20",
                    "AccountNumber": "0x2835c3A00Eebd6ee37B52854De829Af87eC6e5fe",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "usdterc",
                    "image": <img src={tetherSvg} alt="USDT ERC20"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={tetherSvg} alt="USDT ERC20"/>
                },
                {
                    "name": "USDT TRC20",
                    "AccountNumber": "TDXucuGuGDqXL4A982JZP88dAe9r8oEdsn",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "usdttrc",
                    "image": <img src={tetherSvg} alt="USDT TRC20"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={tetherSvg} alt="USDT TRC20"/>
                },
                {
                    "name": "Shiba INU",
                    "AccountNumber": "0x2835c3A00Eebd6ee37B52854De829Af87eC6e5fe",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "shiba",
                    "image": <img src={shib} alt="Shiba INU"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={shib} alt="Shiba INU"/>
                },
                {
                    "name": "Monero",
                    "AccountNumber": "0x6F6c63514EcDEe67f6fcA95Bd6c464d1471CFeB4",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "monero",
                    "image": <img src={maestro} alt="Monero"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={maestro} alt="Monero"/>
                },
                {
                    "name": "Doge Coin",
                    "AccountNumber": "DU6tYwHK91dc81KyfKZPcGrnMVhZdKh8yb",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "minimumDeposit": 100,
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "icon": "doge",
                    "image": <img src={dogecoin} alt="Doge Coin"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={dogecoin} alt="Doge Coin"/>
                }
            ],
            "status": "Recommended",
            "type": "coin"
        }
    },
    {
        "Binance Pay": {
            "wallets": [
                {
                    "name": "Binance pay",
                    "AccountNumber": "1234",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "image": <img src={binance} alt="Bitcoin"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={binance} alt="Bitcoin"/>
                }
            ],
            "status": "Unavailable",
            "type": "binance"
        }
    },
    {
        "Bank Card": {
            "wallets": [
                {
                    "name": "Bank Card",
                    "AccountNumber": "1234",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "image": <img src={bankCard} alt="Bank Card"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={bankCard} alt="Bank Card"/>
                }
            ],
            "status": "Unavailable",
            "type": "bank"
        }
    },
    {
        "Apple Pay": {
            "wallets": [
                {
                    "name": "Apple Pay",
                    "AccountNumber": "1234",
                    "Limit": "Limits 10 - 20,000,000 USD",
                    "fee": "Fee 0%",
                    "processingTime": "ProcessingTime Instant - 30 minutes",
                    "status": true,
                    "image": <img src={apple} alt="Bitcoin"
                                  style={{width: "20px", marginRight: "1rem"}}/>,
                    "Image_Icon": <img src={apple} alt="Bitcoin"/>
                }
            ],
            "status": "Unavailable",
            "type": "apple"
        }
    }
]


export const recommendedWallets = walletData.filter(wallet => wallet[Object.keys(wallet)].status === "Recommended")[0]?.[Object.keys(walletData.filter(wallet => wallet[Object.keys(wallet)].status === "Recommended")[0])]?.wallets;

export const unavailableWallets = walletData.filter(wallet => wallet[Object.keys(wallet)].status === "Unavailable").map(wallet => wallet[Object.keys(wallet)].wallets).flat();

