import styled from "styled-components";
//import {BsThreeDotsVertical} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import { formatCurrency2} from "./formatCurrency";
import 'react-toastify/dist/ReactToastify.css';
import React, {useState} from "react";
import getCompanyInfo from "../../../Utilities/companyInfo";
//import {toast} from "react-toastify";


export const AccountCard = ({user}) => {
    const navigate = useNavigate();

    const deposit = () => {
        navigate("/profile/deposit");
    }


    const withdrawal = () => {
        navigate("/profile/withdrawal");
    }


    const Invest = () => {
        navigate("/profile/investment");
    }

    const amount = user?.balance;
    const formattedAmount = formatCurrency2(amount);

    return (<CardContainer>
        <AccountDetails>
            <span className="Demo">Wallet ID</span>
            <span># {user?.walletNumber}</span>
        </AccountDetails>

        <ButtonContainer>
            <Balance>{formattedAmount}
                {/*<span>.00 USD</span>*/}
            </Balance>
            <div>
                <GreyButton onClick={deposit}>Deposit</GreyButton>
                <GreyButton onClick={withdrawal}>Transfer</GreyButton>
                <YellowButton onClick={Invest}>Trade</YellowButton>
                {/*<BsThreeDotsVertical className="dots"/>*/}
            </div>
        </ButtonContainer>
    </CardContainer>)
};


export const DemoCard = ({user}) => {

    const navigate = useNavigate();
    const deposit = () => {
        navigate("/profile/deposit");
    }


    const withdrawal = () => {
        navigate("/profile/investment?redeem");
    }


    const Invest = () => {
        navigate("/profile/investment?plan");
    }

    const amount = user?.investBalance;
    const formattedAmount = formatCurrency2(amount);

    return (<CardContainer>
        <AccountDetails>
            <span className="Demo">Wallet ID</span>
            <span># {user?.walletNumber}</span>
        </AccountDetails>

        <ButtonContainer>
            <div>
                <GreyButton>Investment Balance</GreyButton>
                <Balance>{formattedAmount}</Balance>
            </div>

            <div>
                <GreyButton onClick={deposit}>Deposit</GreyButton>
                <GreyButton onClick={withdrawal}>Redeem</GreyButton>
                <YellowButton onClick={Invest}>Trade</YellowButton>

            </div>

        </ButtonContainer>




    </CardContainer>);
};


export const ArchivedCard = ({user}) => {
    // const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const [showCopiedMessage, ] = useState(false);

    const company = getCompanyInfo()

    const code = user?.partnerCode
    const copyToClipboard = (code) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(code)
                .then(() => {
                    //alert()
                    alert(code+" Copied");
                    // setShowCopiedMessage(true);
                    // setTimeout(() => {
                    //     setShowCopiedMessage(false);
                    // }, 3000);
                })
                .catch((error) => {
                    alert("Failed to copy to clipboard");
                    //console.error("Failed to copy to clipboard:", error);
                });
        } else {
            alert("Clipboard API not supported");
            //console.error("Clipboard API not supported");
        }
    }


    return (
        <>
            {showCopiedMessage &&
                <span style={{
                    display:"flex",
                    justifyContent:"end",
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: "green"
                }}>Partner Code {code} copied to clipboard!</span>}
            <CardContainer>
                <AccountDetails>
                    <span style={{fontWeight:"bold",fontSize:15,background:'white'}}> {user?.totalPartner || 0} </span>
                    <span style={{background:'white'}}>Downline Partner(s)</span>
                </AccountDetails>

                <ButtonContainer>
                    <Balance>
                        <span style={{fontWeight:'400'}}>Your referral Partner Code</span> <br/> {code}
                    </Balance>
                    <div>
                        <GreyButton onClick={() => copyToClipboard(code)}>Copy</GreyButton>
                    </div>

                </ButtonContainer>

                <ReferralProgramContainer>
                    <Title>Earn 10% Continuously with Our Referral Program!</Title>
                    <Description>
                        Invite friends and family to invest in {company.name} and receive 10% of their investments as continuous income.
                        Your earnings are ongoing for every investment they make, ensuring a lucrative return for you and your referrals.
                    </Description>
                    <Note>
                        Note: This is a great opportunity to maximize your earnings and grow your wealth effortlessly.
                        Start referring today and unlock the power of passive income!
                    </Note>
                </ReferralProgramContainer>

            </CardContainer>
        </>

);
};


const ReferralProgramContainer = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const Title = styled.h2`
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  color: #555;
  font-size: 18px;
  margin-bottom: 15px;
`;

const Note = styled.p`
  color: #777;
  font-style: italic;
`;


const CardContainer = styled.div`
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 2.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 50px;
    
    @media (max-width: 768px) {
        width: 100%;
        color: #141D22;
    }
`;

const AccountDetails = styled.div`
    font-size: 12px;
    margin-bottom: 10px;

    span {
        margin: 6px;
        padding: 6px;
        background-color: #F3F5F7;
    }

    .Demo {
        background-color: #F0FBF5;
        color: #78d54e;
        //padding: 7px;
    }
`;


const Balance = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin: 1rem 1rem;

    span {
        font-weight: bold;
        font-size: 13px;
    }

    .text {
        font-weight: 500;
        font-size: 13px;
        margin-left: 1rem;
    }

    //margin-top: 4rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    //margin-top: 2rem;
    width: 100%;
    margin-top: 3rem;

    .dots {
        font-size: 16px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const Button = styled.button`
    //flex-grow: 1;
    padding: 10px 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    @media (max-width: 768px) {
        margin-right: 8px;
        margin-top: 5px;
    }
`;

export const GreyButton = styled(Button)`
    background-color: #F3F5F7;
    //color: #00C076;
`;


const YellowButton = styled(Button)`
    background-color: #FFDE02;
`;
