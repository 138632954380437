import React, {useState} from "react";
import { useNavigate} from 'react-router-dom'
import {useAuth} from "../../contexts/AppContext";
import {LoginSchema} from "../../validation/validation";
import * as Yup from "yup";
import Loading from "../../Loader/Loading";
import 'react-toastify/dist/ReactToastify.css';
import getCompanyInfo from "../../Utilities/companyInfo";


const Login = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const {login} = useAuth();

    const company = getCompanyInfo()

    const [formData, setFormData] = useState({
        email: '', password: '',
    });


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async () => {
        // e.preventDefault();
        setLoading(true);
        try {
            await LoginSchema.validate(formData);
            await login(formData.email, formData.password)
                .then((data) => {
                    // console.log(data, 'login data')
                    if (data.message === "Required 2-step verification") {
                        alert(data.message);
                        return navigate(`/security?email=${formData.email}`);
                    } else if (data.message === "Login successful") {
                       // if(isLoggedIn === true){
                        alert(`Welcome to ${company.name}, the future Forex trading community! Step into a world of global markets and endless possibilities. Whether you're a seasoned trader or just starting out, we're thrilled to have you here. Let's navigate the exciting world of currency exchange together. Happy trading!`);
                        return navigate('/profile')
                       // }
                    } else {
                        alert(data.message);
                        return navigate('/')
                    }
                }).catch((error) => {
                    alert("Oops Something Went Wrong Please Try Again");
                })
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                alert(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e, countryName) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, [name]: value
        });
        if (errors[name] && value) {
            setErrors({...errors, [name]: ''});
        }
    };


    return (<div>
        <div className="auth-container__form">

            <label style={{fontSize:13,fontWeight:'bold'}}>
                Please provide your account login credentials to continue
            </label>


            <div>
                <label>Your Email Address</label>
                <input type="email"
                       placeholder="Email"
                       name="email"
                       value={formData.email}
                       onChange={handleChange}
                       required/>
                {errors.email && !formData.email && <div className="error">{errors.email}</div>}
            </div>

            <div>
                <label>Password</label>
                <div>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                {errors.password && !formData.password &&
                    <div className="error">{errors.password}</div>}
            </div>
            <div className="checkboxToggle">
                <label>Show Password</label>
                <input type="checkbox" placeholder="password" id="password"
                       className="checkbox_input" style={{cursor: "pointer"}}
                       onClick={togglePasswordVisibility}
                       required/>
            </div>



            <button type="button" onClick={handleLogin} disabled={loading}>
                {loading ? <Loading/> : <span>Login</span>}
            </button>
        </div>
    </div>)
}

export default Login
