import React from 'react'
import styled from "styled-components";
import Tab, {MainContainer} from "../TABS/Tabs";
import {Wrapper} from "./Account";
import {InvestmentPlanCard, InvestmentWithdrawalForm} from "../TABS/InvestmentPlans";
import EmptyImage from "../TABS/EmptyImage";
import withLoader from "../../../Loader/WithLoader";
import {InvestmentHistory} from "../TABS/TransactionHistory/InvestmentHistory";

import { formatCurrency2} from "../TABS/formatCurrency";
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../../contexts/AppContext";
import {CardContainers, CardContent, Text} from "../TABS/Deposit&WithdrawCard";

const InvestmentWallet = ({parameter}) => {
    const {InvestmentPlan, user, investmentHistory, RedeemInvestment} = useAuth();


   // console.log(InvestmentPlan, "InvestmentPlan");


    const tabs = [{
        title: "Trading Plan", content: <MainContainer>
            {/*<ToastContainer/>*/}
            <h2 className='header-text'>Investment Plan</h2>
            <p className='header-text'>See all Investment Plan.</p>
            <PlansContainer>
                {InvestmentPlan?.investmentPlans?.map((plan, index) => (
                    <InvestmentPlanCard
                        key={plan._id}
                        _id={plan._id}
                        planName={plan.planName}
                        planType={plan.planType}
                        desc={plan.desc}
                        minimumInitialDeposit={plan.minimumInitialDeposit}
                        maximumInitialDeposit={plan.maximumInitialDeposit}
                        spread={plan.spread}
                        commission={plan.commission}
                        duration={plan.duration}
                        planStatus={plan.planStatus}
                    />
                ))}

            </PlansContainer>

        </MainContainer>
    }, {
        title: "Withdraw Profit ", content: <MainContainer>
            {/*<ToastContainer/>*/}
            <h2 className='header-text'>Withdraw Investment</h2>
            <p className='header-text'>You can withdraw your earned profit here by entering the amount and continue.</p>
            <InvestmentWithdrawalForm user={user} RedeemInvestment={RedeemInvestment}/>
        </MainContainer>
    }, {
        title: "My Tradings", content: <MainContainer>
            <h2 className='header-text'> My Investment History</h2>
            <p style={{marginBottom:20}} className='header-text'>See all your Investment history.</p>

            <CardContainers>
                <CardContent>
                    <Text>
                        All investments labeled as "Unpaid" will automatically transition to "Paid" status upon completion of (100% Progress Bar). At that point, we'll credit the corresponding amount to your investment profit balance, to enable you withdraw to your main balance
                    </Text>
                </CardContent>
            </CardContainers>

            {investmentHistory && investmentHistory.length > 0 ? (
                <InvestmentHistory investmentHistory={investmentHistory}/>) : (<EmptyImage title={'No Investment Yet'} subTitle={'There are currently no investment to display'} headColor={'#000'}/>)}

        </MainContainer>
    },


    ];

    const amount = user?.investBalance;
    const formattedAmount = formatCurrency2(amount);

    return (<main className='main-container'>
        {/*<ToastContainer/>*/}
        <Wrapper>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{marginTop: "1rem"}}>
                    <h4>Manage Investment</h4>
                    <p style={{fontSize:11}}>Invest with the funds in your wallet</p>

                </div>
                <div style={{marginTop: "1rem"}}>
                    <span style={{fontSize:13,fontWeight:'bold'}} className="balance-text">Earned Profit</span>
                    <h4 style={{color:'#000',fontSize:13}} className="balance">{formattedAmount}</h4>
                </div>
            </div>


            <Tab tabs={tabs} parameter={parameter}/>

        </Wrapper>
    </main>)
}


// export default InvestmentWallet
export default withLoader(InvestmentWallet);


export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 10px;
    //width: 100%;
  
`;


const PlansContainer = styled.div`
    display: flex;
    overflow-x: auto !important;
    padding: 20px 0;
  //background-color: #00C076;

  @media only screen and (max-width: 600px) {
    display: block !important;
    overflow-x: auto !important;
    padding: 20px 0 !important;
  }
  
  //.lpfVYg{
  //  width: 0 !important;
  //}
`;
