import React from "react"
import {Wrapper} from "./Account";
import {
    P2PWithdrawalForm,
    UnavailableCard,
    WithdrawalBanksCard
} from "../TABS/Deposit&WithdrawCard";
import {GridContainer} from "./InvestmentWallet";
import Tab, {MainContainer} from "../TABS/Tabs";
import withLoader from "../../../Loader/WithLoader";
// import 'react-toastify/dist/ReactToastify.css';
//import {ToastContainer} from 'react-toastify';
import {recommendedWallets, unavailableWallets} from "../../../Utilities/wallet";

const Withdrawal = () => {


    const tabs = [{
        title: "Wallet Withdrawal", content: <MainContainer>
            <GridContainer>
                {recommendedWallets?.slice(0, 1).map((bank, index) => (
                    <WithdrawalBanksCard bank={bank} key={index}/>))}
                {unavailableWallets?.map((bank, index) => (
                    <UnavailableCard bank={bank} key={index}/>))}
            </GridContainer>

        </MainContainer>
    }, {
        title: "Wallet P2P", content: <MainContainer>
            <P2PWithdrawalForm/>
        </MainContainer>
    },

    ]

    return (<main className='main-container'>
        <Wrapper>
            <div className='title'>
                <div>
                    <h4>Withdrawal</h4>
                    <p>All payment methods</p>
                </div>
            </div>

            <Tab tabs={tabs}/>

        </Wrapper>

    </main>)
}

// export default Withdrawal
export default withLoader(Withdrawal);
