const getCompanyInfo = () => {
    // You can replace this with your logic to fetch company data
    const name = 'UnityMarket';
    const started = 2008;
    const currentYear = new Date().getFullYear();
    const year = `${started}-${currentYear}`;
    const loadingText = 'UnityMarket';
    const supportEmail = "support@unitymarket.app";
    const publicWebsite = "https://unitymarket.app/"
    const supportUrl = publicWebsite+"contact";
    const terms = publicWebsite+"terms_conditions"
    const privacy = publicWebsite+"privacy_policy"


    return {name, year, loadingText, supportEmail, supportUrl, publicWebsite, terms, privacy};
};

export default getCompanyInfo;
