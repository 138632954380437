import React from "react"
import Layout from "../../components/ProfileDashboard/SideBarLayout";
import Deposits from "../../components/ProfileDashboard/SideBarPages/Deposit";
import {useAuth} from "../../contexts/AppContext";


const Deposit = () => {
    const {user} = useAuth();
    return (<Layout>
        <Deposits user={user}/>
    </Layout>)

}

export default Deposit


